<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <!--  v-loading="!textLoaded" -->
  <div v-loading="textIsloading" class="place-book-text">
    <!-- Your component's template goes here -->
    <div v-if="textLoaded" class="book-text">
      <hr />
      <!-- <b>Книги:</b> -->
      <div v-if="!textIsloading">
        <div v-for="book in allBooksTextByDate" :key="book.text_id">
          <p class="fulltext-book-title">
            <span v-if="book.book.book_author">{{ book.book.book_author }}</span>
            <span v-else>{{ book.book.book_short_title }}</span>
            <!-- If AJ book -->
            <span v-if="book.book.book_type === 'ay'">
              <span>, {{ book.text_paragraph }} ({{ humDateRange(book) }})</span>
            </span>
            <span v-else>
              <span v-if="book.text_date"> ({{ humDateRange(book) }})</span
              ><span v-else>. Глава «{{ book.chapter.chapter_title }}»</span>
            </span>
            :
            <a
              :href="`/book/view/${book.book.book_id}/${book.chapter_id}#pp${book.text_id}`"
              @click="onClickBookLink(book, $event)"
            >
              <el-icon style="top: 2px"><Memo /></el-icon>
            </a>
          </p>
          <TruncateReadMore
            :truncate-value="300"
            :only-if-more-than-value="315"
            readMoreButtonText="Развернуть"
            readLessButtonText="Свернуть"
            :content="book.full_text_content"
            :shortcontent="book.short_text_content"
          >
          </TruncateReadMore>
        </div>
      </div>
    </div>
    <div v-else class="booktext-loading-error">
      <p>
        Не удалось загрузить данные с сервера.
        <a href="#" @click.prevent="pageReload()">Перезагрузить страницу.</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TruncateReadMore from '@/components/TruncateReadMore.vue';
import myhelper from '@/plugins/myhelper';

export default {
  name: 'PlaceBookText',
  props: {
    placeId: { type: Number, required: true },
  },
  components: {
    TruncateReadMore,
  },
  data() {
    return {
      allBooksTextByDate: [],
      curPlace: null,
      textLoaded: true,
      textIsloading: true,
    };
  },
  watch: {
    // whenever question changes, this function will run
    placeId() {
      this.setBookTexts();
    },
  },
  mounted() {
    this.setBookTexts();
  },
  computed: {},

  methods: {
    ...mapGetters('places', ['mapMarkersList', 'isInitialized', 'placeByID']),
    ...mapGetters('books', ['chapterByChapterId']),
    ...mapActions('books', ['fetchAllBooksTextByDate']),
    ...mapActions('places', ['updatePlace']),
    humDateRange(book) {
      return myhelper.humanizeDateRange(book.text_date, book.text_enddate);
    },
    modifyFullText(textContent) {
      const doc = new DOMParser().parseFromString(textContent, 'text/html');
      const element = doc.body.getElementsByTagName('*');
      for (let i = 0; i < element.length; i += 1) {
        if (element[i].dataset.text4placeid === this.placeId.toString()) {
          element[i].classList.add('text-heighlight');
        }
      }
      return doc.documentElement.innerHTML;
    },
    extractPlaceText(textContent) {
      const parsed = new DOMParser().parseFromString(textContent, 'text/html');
      const selector = parsed.querySelectorAll(
        `[data-text4placeid='${this.placeId}'], [data-text4placeid2='${this.placeId}'], [data-text4placeid3='${this.placeId}'], [data-text4placeid4='${this.placeId}']`,
      );
      if (!selector || selector.length === 0) return '';
      const result = Array.from(selector)
        .map((el) => {
          if (el.local === 'p') {
            return el.outerHTML;
          }
          return `<p>${el.outerHTML}</p>`;
        })
        .join('\n');
      return result;
    },

    async setBookTexts() {
      this.textIsloading = true;
      this.textLoaded = true;
      if (!this.placeId) this.allBooksTextByDate = [];
      this.curPlace = this.placeByID()(this.placeId);
      let booktextids = '';
      if (this.curPlace.place_booktextids) {
        try {
          booktextids = JSON.parse(this.curPlace.place_booktextids);
        } catch (e) {
          console.error('Cannot parse place_booktextids', e);
          this.textLoaded = false;
          this.textIsloading = false;
          return;
        }
        // to not load books if date is not precise
      } else if (this.curPlace.date_format !== 'YYYY-MM-DD') {
        this.textLoaded = true;
        this.textIsloading = false;
        this.allBooksTextByDate = [];
        return;
      }
      let allBooksTextByDate;
      try {
        allBooksTextByDate = await this.fetchAllBooksTextByDate({
          textDate: this.curPlace.date,
          endDate: this.curPlace.enddate,
          textIds: booktextids,
          DateFormat: this.curPlace.date_format,
        });
      } catch (e) {
        console.error('Error in fetchAllBooksTextByDate', e);
        this.textLoaded = false;
        this.textIsloading = false;
        return;
      }
      this.textLoaded = false;
      this.allBooksTextByDate = allBooksTextByDate.map((book) => ({
        ...book,
        short_text_content: this.extractPlaceText(book.text_content),
        full_text_content: this.modifyFullText(book.text_content),
      }));
      this.textLoaded = true;
      this.textIsloading = false;
      // console.log('allBooksTextByDate', this.allBooksTextByDate);
    },
    onClickBookLink(book, event) {
      // if shiftKey pressed
      if (event.shiftKey) {
        event.preventDefault();
        this.$router.push({
          path: `/admin/book/edit/${book.book.book_id}/chapter/${book.chapter_id}/text/${book.text_id}`,
        });
      }
      // send user to `/book/view/${book.book.book_id}/${book.chapter_id}#pp${book.text_id}`"
      // this.$router.push({
      //   path: `/book/view/${book.book.book_id}/${book.chapter_id}`,
      //   hash: `pp${book.text_id}`,
      // });
    },
    pageReload() {
      window.location.reload();
      return false;
    },
  },
  // Your component's options go here
};
</script>

<style>
.truncate-read-more-collapsed .ellipsis-after:after {
  content: ' <...>';
}

.truncate-read-more-collapsed .ellipsis-before:before {
  content: '<...> ';
}

.truncate-read-more-collapsed .ellipsis-after .ellipsis-after:after {
  content: '';
}

.truncate-read-more-collapsed .ellipsis-after .ellipsis-before:before {
  content: '';
}

.truncate-read-more-expanded .text-heighlight {
  background-color: #e1effa;
}

.place-book-text .el-loading-spinner {
  top: calc(var(--el-loading-spinner-size) + 10px);
}
.el-loading-parent--relative .book-text {
  min-height: 100px;
}
.place-book-text.el-loading-parent--relative {
  min-height: 100px;
}
.place-book-text .el-loading-mask {
  background-color: transparent;
}
</style>

<style scoped>
/* Your component's styles go here */

.book-text {
  font-family: 'Fira Sans', sans-serif;
  font-size: 1.2em;
  text-align: justify;
}

.fulltext-book-title {
  font-size: 1.1em;
  font-weight: bold;
  /* text-align: center; */
  margin-top: 10px;
  margin-bottom: 10px;
  /* color: #5a1bc7; */
}
.fulltext-content {
  font-size: 1.1em;
  text-align: justify;
  /* padding: 5px 20px; */
  font-family: 'Arial', sans-serif;
  font-family: 'Literata', serif;
  line-height: 1.5;
}
</style>

<style scoped>
.booktext-loading-error {
  height: 100px;
  display: flex;
  align-items: center; /* Aligns vertically */
  justify-content: center; /* Aligns horizontally (optional) */
  border: 1px solid #ff1809; /* For visibility */
}
.booktext-loading-error p {
  color: black;
  text-align: center;
  padding: 17px;
  font-size: 18px;
  font-weight: bold;
}
</style>
