/* eslint-disable no-empty-pattern */
import axios from '@/plugins/axios';

import mutations from '@/store/mutations';
import myhelper from '@/plugins/myhelper';

const { SET_BOOKS, SET_CHAPTERS, SET_ALL_CHAPTERS, SET_TEXTS, SET_TEXT_IDS, SET_ALL_TEXT_IDS } =
  mutations;

const booksStore = {
  namespaced: true,

  state: {
    books: {},
    chapters: {},
    texts: {},
    textIds: {},
  },
  getters: {
    books: (state) => state.books,
    chapters: (state) => state.chapters,
    texts: (state) => state.texts,
    textIds: (state) => state.textIds,

    bookByID:
      ({ books }) =>
      (bookId) =>
        books[bookId],
    textIdsByBookID:
      ({ textIds }) =>
      (bookId) =>
        textIds[bookId],
    chaptersByBookID:
      ({ chapters }) =>
      (bookId) =>
        chapters[bookId] || [],
    chapterByChapterId:
      ({ chapters }) =>
      (chapterId) => {
        const ch = Object.values(chapters)
          .flat()
          .find((chapter) => chapter.chapter_id === chapterId);
        return ch;
      },
    textsByChapterID:
      ({ texts }) =>
      (chapterId) => {
        const t = texts[chapterId];
        const bookId = 2;
        const d = this.$store.dispatch('fetchTexts', { bookId, chapterId });
        console.log(this.$store.state);
        console.log('texts', t);
        console.log('dispatch', d);
      },
    isBooksInitialized: ({ books, chapters, textIds }) =>
      Object.keys(books).length > 0 &&
      Object.keys(chapters).length > 0 &&
      Object.keys(textIds).length >= Object.keys(books).length,

    bookIdByChapterId:
      ({ chapters }) =>
      (chapterId) => {
        const ch = Object.values(chapters)
          .flat()
          .find((chapter) => chapter.chapter_id === chapterId);
        console.log(chapters);
        console.log('Ch', ch, chapterId);
        return ch?.book_id;
      },

    getPrevChapter:
      ({}, getters) =>
      (bookId, chapterId) => {
        const ch = getters.chapterByChapterId(chapterId);
        return getters
          .chaptersByBookID(bookId)
          .filter((chapter) => chapter.chapter_seqid < ch.chapter_seqid)
          .reverse()[0];
      },

    getNextChapter:
      ({}, getters) =>
      (bookId, chapterId) => {
        const ch = getters.chapterByChapterId(chapterId);
        return getters
          .chaptersByBookID(bookId)
          .filter((chapter) => chapter.chapter_seqid > ch.chapter_seqid)[0];
      },

    getPrevText:
      ({}, getters) =>
      (bookId, seqId) =>
        getters
          .textIdsByBookID(bookId)
          .filter((text) => text.text_seqid < seqId)
          .reverse()[0], //

    getNextText:
      ({}, getters) =>
      (bookId, seqId) =>
        getters.textIdsByBookID(bookId).filter((text) => text.text_seqid > seqId)[0],

    getChapterByDate:
      ({}, getters) =>
      ({ bookId, textDate }) => {
        const ch = getters.chaptersByBookID(bookId);
        // console.log(ch, bookId, textDate);
        // find chapter by date
        ch.filter((chapter) => {
          if (!chapter.chapter_fromdate || !chapter.chapter_todate) return false;
          if (chapter.chapter_fromdate <= textDate && textDate <= chapter.chapter_todate) {
            // console.log('found chapter', chapter);
            return true;
          }
          return true;
        });
        return ch[0];
      },

    // eslint-disable-next-line no-unused-vars
    getTextIdByDate:
      ({}, getters) =>
      ({ bookId, textDate }) => {
        // console.log('textDate', textDate);
        // const chapter = getters.getChapterByDate({ bookId, textDate });
        if (!getters.textIds[bookId]) {
          return {};
        }
        const selectTexts = getters.textIds[bookId].filter((textInfo) => {
          // console.log(textInfo);
          if (!textInfo.text_date) {
            return false;
          }
          if (textInfo.text_date === textDate) {
            return true;
          }
          if (!textInfo.text_enddate) {
            return false;
          }
          if (textInfo.text_date <= textDate && textDate <= textInfo.text_enddate) {
            // console.log('found chapter', textInfo);
            return true;
          }
          return false;
        });
        // console.log('selectTexts', selectTexts);
        // if ajBookss - return all matches FIXME: remove hardcoded 3
        if (bookId > 3) {
          return selectTexts;
        }
        return [selectTexts[0]];

        // console.log('chapters', chapter);
        // get textId by date
      },
    getAllTextIdsByDate:
      ({}, getters) =>
      ({ textDate }) => {
        const { books } = getters;
        const textInfos = Object.values(books)
          .map((book) => {
            return getters.getTextIdByDate({ bookId: book.book_id, textDate });
          })
          .flat()
          .filter((text) => text);

        return textInfos;
      },
    getAllTextIdsByDateRange:
      ({}, getters) =>
      ({ textDate, endDate }) => {
        const dateArray = myhelper.dateRange2Array(textDate, endDate);
        const textInfos = dateArray
          .map((date) => {
            return getters.getAllTextIdsByDate({ textDate: date });
          })
          .flat();
        // return multiple texts for ajBooks but only 1 for diaries (N.K., George, ...)
        const textInfos2 = textInfos.reduce((acc, textInfo) => {
          // do not show ay books in prod
          if (textInfo.book_type === 'ay' && process.env.VUE_APP_MODIFIER === 'prod') {
            return acc;
          }
          if (textInfo.book_type === 'diary') {
            const found = acc.find((t) => t.book_id === textInfo.book_id);
            if (found) {
              return acc;
            }
          }
          acc.push(textInfo);
          return acc;
        }, []);

        return textInfos2;
      },
  },

  mutations: {
    [SET_BOOKS](state, books) {
      state.books = books;
    },
    [SET_CHAPTERS](state, { bookId, value }) {
      state.chapters[bookId] = value;
    },
    [SET_ALL_CHAPTERS](state, { value }) {
      state.chapters = value;
    },
    [SET_TEXTS](state, { chapterId, value }) {
      state.texts[chapterId] = value;
    },
    [SET_TEXT_IDS](state, { bookId, value }) {
      state.textIds[bookId] = value;
      // console.log(value);
    },
    [SET_ALL_TEXT_IDS](state, { value }) {
      // iterate through values and set textIds
      state.textIds = value;

      // console.log(value);
    },
  },
  actions: {
    initBooksStore: {
      // eslint-disable-next-line no-unused-vars
      handler({ dispatch, state }) {
        dispatch('fetchAllTextIds');
        dispatch('fetchAllChapters');
        dispatch('fetchBooks').then(() => {
          // console.log('Books store initialized');
          // foreach books fetch chapters
          // Object.values(state.books).forEach((book) => {
          //   dispatch('fetchChapters', book.book_id);
          //   // dispatch('fetchTextIds', book.book_id);
          // });
        });
      },
      root: true,
    },
    // =================================================
    // books
    fetchBooks({ commit }) {
      const api = '/api/books/';
      return axios
        .get('/api/books/', { timeout: 5000 })
        .then((response) => {
          const books = myhelper.serializeResponse(response.data, 'book_id');

          commit('SET_BOOKS', books);
          localStorage.setItem(api, JSON.stringify(books));
        })
        .catch((error) => {
          const cached = myhelper.safeJSONParse(localStorage.getItem(api), null);
          if (cached) {
            console.log(`Using cached books`);
            commit('SET_BOOKS', cached);
            this.$caemap.loadingcached = true;
          } else {
            console.log(error);
          }
        });
    },
    createBook({ dispatch }, book) {
      return axios.post('/api/books/', book).then(() => dispatch('fetchBooks'));
    },
    updateBook({ dispatch }, { bookId, book }) {
      return axios.put(`/api/books/${bookId}`, book).then(() => dispatch('fetchBooks'));
    },
    deleteBook({ dispatch }, bookId) {
      return axios.delete(`/api/books/${bookId}`).then(() => dispatch('fetchBooks'));
    },
    // =================================================
    // chapters
    // fetchAllChapters using /api/books/all/chapters/ request and SET_ALL_CHAPTERS mutation
    fetchAllChapters({ commit }) {
      return axios
        .get('/api/books/all/chapters/')
        .then((response) => {
          commit('SET_ALL_CHAPTERS', { value: response.data });
          localStorage.setItem('/api/books/all/chapters/', JSON.stringify(response.data));
        })
        .catch((error) => {
          const cached = myhelper.safeJSONParse(
            localStorage.getItem('/api/books/all/chapters/'),
            null,
          );
          if (cached) {
            console.log(`Using cached chapters for all books`);
            commit('SET_ALL_CHAPTERS', { value: cached });
            this.$caemap.loadingcached = true;
          } else {
            console.log(error);
          }
        });
    },

    fetchChapters({ commit }, bookId) {
      const api = `/api/books/${bookId}/chapters/`;
      return axios
        .get(api)
        .then((response) => {
          commit('SET_CHAPTERS', { bookId, value: response.data });
          localStorage.setItem(api, JSON.stringify(response.data));
        })
        .catch((error) => {
          const cached = myhelper.safeJSONParse(localStorage.getItem(api), null);
          if (cached) {
            console.log(`Using cached chapters for ${bookId}`);
            commit('SET_CHAPTERS', { bookId, value: cached });
            this.$caemap.loadingcached = true;
          } else {
            console.log(error);
          }
        });
    },
    createChapter({ dispatch }, { bookId, chapter }) {
      return axios
        .post(`/api/books/${bookId}/chapters/`, chapter)
        .then(() => dispatch('fetchChapters', bookId));
    },
    updateChapter({ dispatch }, { bookId, chapterId, chapter }) {
      return axios
        .put(`/api/books/${bookId}/chapters/${chapterId}`, chapter)
        .then(() => dispatch('fetchChapters', bookId));
    },
    deleteChapter({ dispatch }, { bookId, chapterId }) {
      return axios
        .delete(`/api/books/${bookId}/chapters/${chapterId}`)
        .then(() => dispatch('fetchChapters', bookId));
    },
    // =================================================
    // Texts
    fetchTexts({}, { bookId, chapterId }) {
      // return axios.get(`/api/books/${bookId}/chapters/${chapterId}/texts/`).then((response) => {
      //   commit('SET_TEXTS', { chapterId, value: response.data });
      // });
      return axios.get(`/api/books/${bookId}/chapters/${chapterId}/texts/`);
    },
    // fetch all textids using ready for use API /api/books/all/text_ids. It returns {book_ids: text_ids[]}
    fetchAllTextIds({ commit }) {
      return axios
        .get('/api/books/all/text_ids')
        .then((response) => {
          commit(SET_ALL_TEXT_IDS, { value: response.data });
          localStorage.setItem('/api/books/all/text_ids', JSON.stringify(response.data));
        })
        .catch((error) => {
          const cached = myhelper.safeJSONParse(
            localStorage.getItem('/api/books/all/text_ids'),
            null,
          );
          if (cached) {
            console.log(`Using cached text_ids for all books`);
            commit(SET_ALL_TEXT_IDS, { value: cached });
            this.$caemap.loadingcached = true;
          } else {
            console.log(error);
          }
        });
    },
    fetchTextIds({ commit }, bookId) {
      return axios
        .get(`/api/books/${bookId}/text_ids`)
        .then((response) => {
          commit('SET_TEXT_IDS', { bookId, value: response.data });
          localStorage.setItem(`/api/books/${bookId}/text_ids`, JSON.stringify(response.data));
        })
        .catch((error) => {
          const cached = myhelper.safeJSONParse(
            localStorage.getItem(`/api/books/${bookId}/text_ids`),
            null,
          );
          if (cached) {
            console.log(`Using cached text_ids for ${bookId}`);
            commit('SET_TEXT_IDS', { bookId, value: cached });
            this.$caemap.loadingcached = true;
          } else {
            console.log(error);
          }
        });
    },
    // eslint-disable-next-line no-unused-vars
    fetchTextByDate({ getters, dispatch }, { bookId, textDate }) {
      const textInfo = getters.getTextIdByDate({ bookId, textDate });
      textInfo.book = getters.bookByID(bookId);
      return dispatch('fetchText', { textId: textInfo.text_id });
    },
    // eslint-disable-next-line no-unused-vars
    async fetchAllBooksTextByDate(
      { getters, dispatch },
      { textDate, endDate, textIds: tIds, DateFormat },
    ) {
      let textInfos;

      // if textIds is empty array - don't auto detect for book texts
      if (Array.isArray(tIds) && tIds.length === 0) {
        return [];
      }

      // if textIds passed we used it, but if don't - we auto detect
      if (tIds && tIds.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const textInfosFromIds = Object.values(getters.textIds)
          .flat()
          .filter((text) => tIds.includes(text.text_id));

        const textInfosAutoDetect = getters
          .getAllTextIdsByDateRange({ textDate, endDate })
          .filter((text) => {
            if (textInfosFromIds.find((t) => t.text_id === text.text_id)) {
              return false;
            }
            // To not auto detect texts got AY if date is not accurate
            if (text.book_type === 'diary' || DateFormat !== 'YYYY-MM-DD') {
              return false;
            }
            if (text.book_type === 'ay' && process.env.VUE_APP_MODIFIER === 'prod') {
              return false;
            }
            return true;
          });
        textInfos = [...textInfosFromIds, ...textInfosAutoDetect];

        // textInfos = textInfos2.filter((text) => text.book_id > 3);
      } else {
        textInfos = getters.getAllTextIdsByDateRange({ textDate, endDate });
      }
      // console.log('textInfos', textInfos);

      const bookByTextId = {};
      const chapterByTextId = {};
      textInfos.forEach((text) => {
        bookByTextId[text.text_id] = getters.bookByID(text.book_id);
        chapterByTextId[text.text_id] = getters.chapterByChapterId(text.chapter_id);
      });
      const textPromises = textInfos.map((ti) => {
        return dispatch('fetchText', { textId: ti.text_id });
      });
      const allTexts = [];
      // wait until all texts are fetched
      const datas = await Promise.all(textPromises);
      datas.forEach((data) => {
        allTexts.push({
          ...data.data,
          book: bookByTextId[data.data.text_id],
          chapter: chapterByTextId[data.data.text_id],
        });
      });
      const sortedTextInfos = allTexts.sort((a, b) => {
        // console.log(a, b);
        if (a.book.book_id === b.book.book_id) {
          return a.text_seqid - b.text_seqid;
        }
        return a.book.book_id - b.book.book_id;
      });

      // console.log('allTexts', sortedTextInfos);
      return sortedTextInfos;
    },

    fetchText({}, { textId }) {
      // return axios.get(`/api/books/${bookId}/chapters/${chapterId}/texts/`).then((response) => {
      //   commit('SET_TEXTS', { chapterId, value: response.data });
      // });
      return axios.get(`/api/books/text/${textId}`);
    },

    async createNewText({ dispatch }, { bookId, chapterId, text }) {
      return new Promise((resolve, reject) => {
        const postData = {
          chapter_id: chapterId,
          ...text,
        };
        console.log('postDat', postData, text);
        axios
          .post(`/api/books/${bookId}/chapters/${chapterId}/texts/`, postData)
          .then((response) => {
            dispatch('initBooksStore', {}, { root: true }, null);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
    updateText({}, { bookId, chapterId, textId, textData }) {
      return axios.put(`/api/books/${bookId}/chapters/${chapterId}/texts/${textId}`, {
        ...textData,
      });
    },
    deleteText({ dispatch }, { bookId, chapterId, textId }) {
      return axios
        .delete(`/api/books/${bookId}/chapters/${chapterId}/texts/${textId}`)
        .then(() => dispatch('initBooksStore', {}, { root: true }, null));
    },

    updateTextSeqid({ dispatch }, { bookId, updateData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/books/${bookId}/change_text_seqids`, [...updateData])
          .then((response) => {
            dispatch('fetchTextIds', bookId);
            // myhelper.ElSuccess('Updating completed');
            resolve(response.data);
          })
          .catch((error) => {
            myhelper.ElError(`Seqid update error: ${error}`);
            reject(error);
          });
      });
    },
  },
};

export default booksStore;
