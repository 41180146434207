/* eslint-disable no-empty-pattern */
import axios from '@/plugins/axios';
import mutations from '@/store/mutations';
import myhelper from '@/plugins/myhelper';
import { ElNotification } from 'element-plus';

function serializeResponse(images) {
  return images.reduce((acc, image) => {
    const imagenew = image;
    acc[image.image_id] = imagenew;
    return acc;
  }, {});
}

const { IMAGES, UPDATE_IMAGE, DELETE_IMAGE, ADD_IMAGE } = mutations;

const imagesStore = {
  namespaced: true,
  state: {
    images: {},
  },
  getters: {
    imagesList: ({ images }) =>
      Object.values(images)
        .sort((a, b) => a.seqid - b.seqid)
        .map((img) => {
          return {
            ...img,
            url: `${process.env.VUE_APP_IMAGES_URL}/${myhelper.fixedEncodeURIComponent(img.filename)}`,
            thumb: `${process.env.VUE_APP_IMAGES_URL}/thumb/${myhelper.fixedEncodeURIComponent(img.filename)}`,
          };
        }),

    imageByID:
      ({ images }) =>
      (imageId) =>
        images[imageId],

    imagesCountByPlaceId:
      ({ images }) =>
      () => {
        // console.log('start');
        Object.values(images).reduce((acc, image) => {
          // eslint-disable-next-line camelcase
          const { place_id } = image;
          acc[place_id] = (acc[place_id] || 0) + 1;
          return acc;
        }, {});
        // console.log('end');
      },

    getImageByPlaceId:
      ({}, getters) =>
      (placeId) =>
        getters.imagesList
          .filter((x) => x.place_id === placeId)
          .sort((a, b) => {
            if (a.image_type === b.image_type) {
              return a.image_seqid - b.image_seqid;
            }
            const order = ['histexpphoto', 'histimage', 'painting', 'postcard', 'photo'];
            return order.indexOf(a.image_type) - order.indexOf(b.image_type);
          }),

    getPrevImage:
      ({}, getters) =>
      (imageId) =>
        getters.imagesList.filter((image) => image.image_id < imageId).reverse()[0], //

    getNextImage:
      ({}, getters) =>
      (imageId) =>
        getters.imagesList.filter((image) => image.image_id > imageId)[0], //

    isInitialized: ({ images }) => Object.keys(images).length > 0,
    // eslint-disable-next-line no-unused-vars
  },
  mutations: {
    [IMAGES](state, value) {
      state.images = value;
    },
    [UPDATE_IMAGE](state, { id, value }) {
      state.images[id] = value;
    },
    [DELETE_IMAGE](state, id) {
      console.log(`${DELETE_IMAGE} ${id}`);
      delete state.images[id.toString()];
    },
    [ADD_IMAGE](state, image) {
      state.images[image.image_id] = image;
    },
  },
  actions: {
    initImagesStore: {
      handler({ dispatch }) {
        dispatch('fetchImages');
      },
      root: true,
    },
    fetchImages({ commit }) {
      const api = '/images/';
      return new Promise((resolve, reject) => {
        axios
          .get(api, { timeout: 5000 })
          .then((response) => {
            const images = serializeResponse(response.data);
            commit(IMAGES, images);
            localStorage.setItem(api, JSON.stringify(images));
            resolve();
          })
          .catch((err) => {
            const cached = myhelper.safeJSONParse(localStorage.getItem(api), null);
            if (cached) {
              console.log(`Using cached images`);
              commit(IMAGES, cached);
              this.$caemap.loadingcached = true;
              resolve();
            } else {
              console.log(err);
              ElNotification.error({
                title: 'Ошибка инициализации',
                message: `Error while loading images from backend: ${err.message}`,
                duration: 50000,
              });
              reject(err);
            }
          });
      });
    },
    // eslint-disable-next-line
    deleteImage({ commit, state }, imageId) {
      console.log('deleting Image ', imageId);
      // console.log(state.images);
      return new Promise((resolve, reject) => {
        if (imageId in state.images) {
          axios
            .delete(`/images/${imageId}`)
            .then((response) => {
              console.log(response.data);
              commit(DELETE_IMAGE, imageId);
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.message);
            });
        }
      });
    },

    updateImageSeqIds({ dispatch }, { updateData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/images/change_seqids`, [...updateData])
          .then((response) => {
            dispatch('fetchImages');
            myhelper.ElSuccess('Bulk image SeqId updating has been completed');
            resolve(response.data);
          })
          .catch((error) => {
            myhelper.ElError(`Images seqid update error: ${error}`);
            reject(error);
          });
      });
    },
    // "/images/{image_id}/rename
    renameImage({ dispatch }, { imageId, newFilename }) {
      console.log(imageId, newFilename);
      return new Promise((resolve, reject) => {
        axios
          .put(`/images/${imageId}/rename`, null, { params: { newfilename: newFilename } })
          .then((response) => {
            myhelper.ElSuccess('Image renaming has been completed');
            resolve({ data: response.data, promise: dispatch('fetchImages') });
          })
          .catch((error) => {
            myhelper.ElError(`Image renaming error: ${error}`);
            reject(error);
          });
      });
    },

    // eslint-disable-next-line
    async updateImage({ commit, dispatch, state }, { imageId, image }) {
      // console.log('updateImage');
      return new Promise((resolve, reject) => {
        if (imageId in state.images) {
          const updatedImage = { ...state.images[imageId], ...image };
          if (updatedImage.latitude === '') {
            updatedImage.latitude = null;
          }
          if (updatedImage.longitude === '') {
            updatedImage.longitude = null;
          }
          // console.log(JSON.stringify(Object.keys(state.images[imageId])));
          axios
            .put(`/images/${imageId}`, { ...updatedImage })
            .then((response) => {
              if (
                !myhelper.isJsonsEqual(
                  updatedImage,
                  response.data,
                  ['updated_at', 'created_at', 'uploaded_at'],
                  true,
                )
              ) {
                ElNotification.error({
                  title: 'Save error',
                  message: `Error while updating image "${updatedImage.title_ru}"\nSome datas weren't saved.\nMore details in the console`,
                  duration: 50000,
                });
                reject();
              } else {
                ElNotification({
                  title: 'success',
                  message: `The image "${updatedImage.title_ru}" updated  successfully`,
                  type: 'success',
                  duration: 2000,
                });
                resolve();
              }
              commit(UPDATE_IMAGE, { id: imageId, value: response.data });
            })
            .catch((error) => {
              ElNotification.error({
                title: 'Save error',
                message: `Error while updating image "${imageId}"\n${error.message}`,
                duration: 50000,
              });
              reject(error.message);
            });

          // updatedPlace
        } else {
          ElNotification.error({
            title: 'Save error',
            message: `Error while updating image "${imageId}"\nMore details in the console`,
            duration: 50000,
          });

          reject();
        }
      });
    },

    // eslint-disable-next-line
    updateImageMetadata({ commit, dispatch, state }, { imageId, image }) {
      // console.log('updateImageMetadata');
      return new Promise((resolve, reject) => {
        if (imageId in state.images) {
          const updatedImage = { ...state.images[imageId], ...image };
          // console.log(JSON.stringify(Object.keys(state.images[imageId])));
          axios.get(`/image_meta_update/${imageId}`).then((response) => {
            ElNotification({
              title: 'success',
              message: `The image "${updatedImage.title_ru}" metadata has been updated successfully`,
              type: 'success',
              duration: 2000,
            });
            commit(UPDATE_IMAGE, { id: imageId, value: response.data });
            resolve();
          });
          // updatedPlace
        } else {
          reject();
        }
      });
    },
    async addNewVideo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/video_add/`)
          .then((response) => {
            const createdimage = response.data;
            // console.log(createdimage);
            commit(ADD_IMAGE, createdimage);
            resolve(createdimage);
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    async addNewImage({ commit }, image) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/images/`, image)
          .then((response) => {
            const createdimage = response.data;
            // console.log(createdimage);
            commit(ADD_IMAGE, createdimage);
            resolve(createdimage);
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },
    async generateThumb({}, { imageId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/images/thumb/gen/${imageId}`)
          .then((response) => {
            myhelper.ElSuccess(`Thumbs for ${imageId} generated successfully. `);
            resolve(response.data);
          })
          .catch((error) => {
            myhelper.ElError(`Error while generating thumb: ${error.message}`);
            reject(error.message);
          });
      });
    },
    // generate missing thumb using 'http://localhost:8001/images/thumb/gen/missed'
    async generateMissingThumbs() {
      return new Promise((resolve, reject) => {
        axios
          .get('/images/thumb/gen/missed')
          .then((response) => {
            console.log('test');
            const regencnt = response.data.regenerated;
            myhelper.ElSuccess(
              `Missing thumbs generated successfully. Total regenerated: ${regencnt} images`,
            );
            resolve(response.data);
          })
          .catch((error) => {
            myhelper.ElError(`Error while generating missing thumbs: ${error.message}`);
            reject(error.message);
          });
      });
    },
    // generate all thumb using 'http://localhost:8001/images/thumb/gen/all'
    async generateAllThumbs() {
      return new Promise((resolve, reject) => {
        axios
          .get('/images/thumb/gen/all')
          .then((response) => {
            const regencnt = response.data.regenerated;
            myhelper.ElSuccess(
              `All thumbs generated successfully. Total regenerated: ${regencnt} images`,
            );
            resolve(response.data);
          })
          .catch((error) => {
            myhelper.ElError(`Error while generating all thumbs: ${error.message}`);
            reject(error.message);
          });
      });
    },
  },

  modules: {},
};

export default imagesStore;
