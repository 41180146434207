<template>
  <div>
    <div id="lightGallery"></div>
    <nav :class="navClass">
      <img src="/assets/logo2-128x128.png" alt="logo" class="app-logo" />
      <!-- <router-link to="/oldhome">OldHome</router-link> | -->
      <!-- <a
        target="_blank"
        href="https://docs.google.com/spreadsheets/d/19T7R37emIrFJ4FnpJ_g_rt1Ehi9GvR0MrX8uAbTA9NM/edit#gid=0"
        >Google Sheet</a
        > | -->

      <router-link :to="{ path: '/' }"
        ><i class="fa fa-map"></i>&nbsp;<span class="menu-link-title">Карта</span></router-link
      >
      <span class="nav-separator">&#9679;</span>
      <router-link :to="{ name: 'books' }" :class="routerBookClass">
        <i class="fa fa-book"></i>&nbsp;<span class="menu-link-title">Книги</span></router-link
      >
      <span class="nav-separator">&#9679;</span>
      <router-link :to="{ name: 'aboutcae' }"
        ><i class="fa fa-place-of-worship"></i>&nbsp;<span class="menu-link-title">О ЦАЭ</span>
      </router-link>
      <span class="nav-separator">&#9679;</span>
      <router-link :to="{ name: 'about' }"
        ><i class="fa fa-info"></i>&nbsp;<span class="menu-link-title">О проекте</span></router-link
      >
      <!-- <span class="nav-separator">&#9679;</span>
      <a target="_blank" :href="$docs.map"
        ><i class="fa fa-question-circle"></i>&nbsp;<span class="menu-link-title">Руководство</span>
      </a> -->
      <span class="nav-separator">&#9679;</span>
      <router-link :to="{ name: 'guide' }"
        ><i class="fa fa-question-circle"></i>&nbsp;<span class="menu-link-title">Руководство</span>
      </router-link>
      <!-- <span class="nav-separator">&#9679;</span>
      <router-link :to="{ name: 'settings' }"
        ><i class="fa fa-cog"></i>&nbsp;<span class="menu-link-title">Настройки</span></router-link
      > -->

      <!-- <router-link to="/search">Search</router-link> | -->
    </nav>
    <div class="route-view">
      <router-view :key="$route.path" />
    </div>
    <div class="mobile-bottom-navbar" v-if="selectedInitiated">
      <CurvedBottomNavigation :options="options" v-model="selected" />
    </div>
  </div>
</template>

<script>
import myhelper from '@/plugins/myhelper';
// import bottomNavigationVue from 'bottom-navigation-vue';
import CurvedBottomNavigation from '@/components/CurvedBottomNavigation.vue';

export default {
  name: 'App',
  provide() {
    return {
      mapOptions: this.mapOptions,
      tracksList: this.tracksList,
    };
  },
  data() {
    return {
      curMapPosition: '',
      selected: 4,
      routerBookClass: '',
      selectedInitiated: false,
      mapOptions: {
        // User Options
        showMarkers: true,
        showAdditionalMaps: false,
        pathGroupBy: 'pathaccuracy', // 'transport' or 'pathaccuracy'
        // EditorOptions
        editorHighlightSelectedMarker: true,
        editorPlaceQuickEdit: false,
        showPath: true,
        motionStart: false,
        showDebugInfo: false,
        showModernTitle: false,
        animation: true,
        showHeartOfAsia: false,
        showAllPhotos: false,
        showGeoPhotos: true,
        showHeatmap: false,
        mapLanguage: 'ru',
        showExp: {
          precae: true,
          indiancae: true,
          cae: true,
          manchuriaexp: true,
        },
      },
      options: [
        { id: 1, icon: 'fas fa-place-of-worship', title: 'О ЦАЭ', path: { name: 'aboutcae' } },
        { id: 2, icon: 'fas fa-info', title: 'О проекте', path: { name: 'about' } },
        {
          id: 3,
          icon: 'fas fa-map',
          title: 'Карта',
          path: { name: 'MapView' },
        },
        { id: 4, icon: 'fas fa-book', title: 'Книги', path: { name: 'books' } },
        { id: 5, icon: 'fas fa-question-circle', title: 'Руководство', path: { name: 'guide' } },
        // { id: 6, icon: 'fas fa-cog', title: 'Настройки', path: { name: 'settings' } },
      ],
    };
  },

  components: {
    CurvedBottomNavigation,
  },
  created() {},
  mounted() {
    if (this.isWindows()) {
      console.log('test');
      document.body.classList.add('ua-windows');
    }
    this.$nextTick(() => {
      // console.log('App mounted');
      this.$router.isReady().then(() => {
        this.initSelected();
      });
    });

    this.loadOptions();

    this.$watch(
      'mapOptions',
      () => {
        localStorage.setItem('AppOptions', JSON.stringify(this.mapOptions));
      },
      { deep: true },
    );
  },
  methods: {
    isWindows: myhelper.isWindows,
    loadOptions() {
      const appOptions = JSON.parse(localStorage.getItem('AppOptions')) || {};
      if (appOptions) {
        const options = {};
        Object.assign(options, appOptions);
        options.showExp = { ...this.mapOptions.showExp, ...appOptions.showExp };
        Object.assign(this.mapOptions, options);
      }
    },
    initSelected() {
      // console.log('initSelected');
      if (!this.selectedInitiated) {
        const curOption = this.options.find((o) => o.path.name === this.$route.name);
        if (curOption) {
          this.selected = curOption.id;
        } else if (this.$route.name === 'BookView') {
          this.selected = 5;
        }
        this.selectedInitiated = true;
      }
    },
  },

  computed: {
    isAdmin: myhelper.isAdmin,
    navClass() {
      // generate class from route name
      return `route-${this.$route.name}`;
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.routerBookClass = this.$route.name === 'BookView' ? 'router-link-exact-active' : '';
    },
  },
};
</script>

<style lang="scss">
.app-logo {
  height: 68px;
  margin-right: 10px;
  position: absolute;
  top: 1px;
  left: 6px;
}
@media (max-width: 756px), (max-height: 500px) {
  .app-logo {
    display: none;
  }
}
.route-admin .app-logo,
.route-ImagesView .app-logo,
.route-ImagesReview .app-logo,
.route-EditImage .app-logo,
.route-EditPlace .app-logo,
.route-PlaceImageView .app-logo {
  display: none;
}

.nav-separator {
  color: #fff;
  // color: #2c3d50;
  padding-left: 10px;
  padding-right: 10px;
}
body.ua-windows .route-view::-webkit-scrollbar {
  // width: 5px !important;
  height: 3px !important;
}
body.ua-windows .route-view::-webkit-scrollbar-track {
  background: #fff !important;
}
body.ua-windows .route-view::-webkit-scrollbar-thumb {
  background-color: #606060 !important;
  border: 2px solid #fff !important;
  border-radius: 10px !important;
}

body.ua-windows .leaflet-sidebar-content::-webkit-scrollbar {
  width: 14px !important;
  height: 30px !important;
}
body.ua-windows .leaflet-sidebar-content::-webkit-scrollbar-track {
  background: #fff !important;
}
body.ua-windows .leaflet-sidebar-content::-webkit-scrollbar-thumb {
  background-color: #606060 !important;
  border: 3px solid #fff !important;
  border-radius: 10px !important;
}

.route-aboutcae,
.route-about,
.route-help,
.route-books,
.route-guide,
.route-MapView,
.route-BookView {
  // max-width: var(--markdown-page-width);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}

#app .route-EditPlace {
  z-index: 2000;
}

.router-link-exact-active > .menu-link-title {
  text-decoration: underline;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
// @import url('https://fonts.googleapis.com/css?family=EB+Garamond');
// @import url('https://fonts.googleapis.com/css?family=Fira+Sans');
@import url('https://fonts.googleapis.com/css2?family=Literata:ital,opsz,wght@0,7..72,200..900;1,7..72,200..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://use.fontawesome.com/releases/v5.15.4/css/all.css');

// @font-face {
//   font-family: Literata;
//   font-style: normal;
//   src: url('~@/assets/fonts/Literata-VariableFont_opsz,wght.ttf');
// }

// @font-face {
//   font-family: Literata;
//   font-style: italic;
//   src: url('~@/assets/fonts/Literata-Italic-VariableFont_opsz,wght.ttf');
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: normal;
//   src: url('~@/assets/fonts/NotoSans-VariableFont_wdth,wght.ttf') format('truetype-variations');
// }

// @font-face {
//   font-family: 'Noto Sans';
//   font-style: italic;
//   src: url('~@/assets/fonts/NotoSans-Italic-VariableFont_wdth,wght.ttf')
//     format('truetype-variations');
// }

@font-face {
  font-family: 'Fira Sans';
  src: url('~@/assets/fonts/FiraSans-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('~@/assets/fonts/FiraSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('~@/assets/fonts/FiraSans-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('~@/assets/fonts/FiraSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('~@/assets/fonts/FiraSans-Medium.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

:root {
  --book-bg-color: rgb(241, 233, 216);
  --markdown-page-width: 1000px;
  --app-link-color: #0022ff;
  --app-markdown-text-indent: 20px;
  --app-menu-bg-color: #42a5f5;
  --app-menu-height: 34px;
}

.main-text-content {
  max-width: var(--markdown-page-width);
  margin: auto;
  font-family: 'Literata', serif;
  box-sizing: border-box;
  // box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
  padding: 1px 25px 20px 25px;
  line-height: 1.5;
  // padding: 20px;
}
// .main-inner-content {
// }

#app nav {
  // font-family: 'Noto Sans', Arial, sans-serif;
  // font-family: 'Arial', sans-serif;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
  -moz-osx-font-smoothing: grayscale;
  text-align: center !important;
  color: #2c3e50;
  background-color: var(--app-menu-bg-color);
  margin: auto;
  position: sticky;
  top: 0;
  z-index: 2001;
  height: var(--app-menu-height);
  box-sizing: border-box;
  text-shadow: 1px 1px 2px #00000082;
}
@media print {
  #app nav {
    display: none;
  }
}

body {
  margin: 0;
}

nav {
  padding-top: 5px;
  padding-bottom: 7px;

  a {
    font-weight: bold;
    // color: #2c3e50;
    color: #fff;
    text-decoration: none;

    &.router-link-exact-active {
      // color: #42b983;
      text-decoration: none;
    }
  }
}

.mobile-bottom-navbar {
  display: none;
}
.mobile-bottom-navbar .unchecked .btn-title {
  visibility: visible;
  bottom: 4px;
}
.mobile-bottom-navbar .checked .active-label {
  transform: translateY(-10px);
  color: #0022ff;
}
.route-view {
  height: calc(100dvh - 34px);
  overflow: auto;
}

@media only screen and (hover: none) and (pointer: coarse) and (max-width: 1023px),
  only screen and (max-width: 768px) {
  nav {
    display: none;
  }
  .mobile-bottom-navbar {
    display: block;
  }
  // .lg-components {
  //   bottom: 60px !important;
  // }
  // .lg-content {
  //   margin-bottom: 60px !important;
  // }
  .route-view {
    position: relative;
    height: calc(100dvh - 60px);
  }
  .leaflet-tooltip {
    font-size: 0.65rem !important;
  }
  .imgcount {
    font-size: 1.1em !important;
  }
  .imgcount img {
    width: 13.2px !important;
    height: 13.2px !important;
  }
  .imgcount:hover {
    width: 13.2px !important;
    height: 13.2px !important;
    padding-bottom: 4px !important;
    padding-top: 5.5px !important;
  }

  /* Regular CSS rules here*/
}

// Light gallery global styles
.lg-container .lg-backdrop {
  z-index: 2001;
}
.lg-container .lg-outer {
  z-index: 2010;
}
.lg-title-alone {
  padding-top: 8px;
  padding-bottom: 8px;
}
.lg-outer .lg-thumb-item img {
  object-fit: contain !important;
}

.lg-container .lg-sub-html {
  font-size: 14px !important;
  text-wrap: balance;
  // font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5em;
  margin: 0px 0 0px 0;
  padding: 10px 15px 5px 15px;
}

// Need to enlarge small image to fit the screen
img.lg-object.lg-image {
  min-height: 100% !important;
  object-fit: contain;
}
// .lg-outer .lg-img-wrap {
//   max-width: 100vw;
//   max-height: 100vh;
// }
.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  text-shadow:
    #000 1px 2px 3px,
    #000 -1px -1px 3px;
}
@media print {
  .route-view {
    overflow: visible !important;
    height: auto;
  }
}

@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-thumbnail.css';
@import 'lightgallery/css/lg-video.css';
@import 'lightgallery/css/lg-zoom.css';
</style>
