import { createRouter, createWebHistory } from 'vue-router';
// import PlaceEdit from '../views/PlaceEdit.vue';
import MapView from '../views/MapView.vue';

const defaultTitle = 'Карта ЦАЭ';

const routes = [
  {
    path: '/admin/images/review',
    name: 'ImagesReview',
    component: () => import('../views/admin/ImagesReview.vue'),
    meta: { title: 'Images review' },
  },
  {
    path: '/admin/images',
    name: 'ImagesView',
    component: () => import('../views/admin/ImagesView.vue'),
    meta: { title: 'Images list' },
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminView.vue'),
    meta: { title: 'Админка', noCache: true },
  },
  {
    path: '/placeslist',
    name: 'placeslist',
    component: () => import('../views/PlacesList.vue'),
  },

  {
    path: '/bookslist',
    name: 'bookslist',
    component: () => import('../views/BooksList.vue'),
  },
  {
    path: '/books',
    name: 'books',
    component: () => import('../views/Books.vue'),
    meta: { title: `Книги | ${defaultTitle}`, noCache: true },
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('../views/GuideView.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/SettingsView.vue'),
    meta: { title: `Настройки | ${defaultTitle}`, noCache: true },
  },
  {
    path: '/tracks',
    name: 'tracks',
    component: () => import('../components/AppTracksManage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    meta: { title: `О проекте | ${defaultTitle}`, noCache: true },
  },
  {
    path: '/aboutcae',
    name: 'aboutcae',
    component: () => import('../views/AboutCAEView.vue'),
    meta: { title: `О ЦАЭ | ${defaultTitle}`, noCache: true },
  },
  {
    path: '/book/view/:book_id(\\d+)/:chapter_id(\\d+)',
    name: 'BookView',
    component: () => import('../views/BookView.vue'),
  },
  {
    path: '/admin/book/edit/:book_id(\\d+)//chapter/:chapter_id(\\d+)/text/:text_id(\\d+)',
    name: 'AdminBookEdit',
    component: () => import('../views/admin/BookEdit.vue'),
    meta: { title: 'Редактор книг', noCache: true },
  },
  {
    path: '/admin/book/table/:book_id(\\d+)',
    name: 'BookAdminTable',
    component: () => import('../views/admin/BookAdminTable.vue'),
  },
  {
    path: '/search',
    name: 'PlacesSearch',
    component: () => import('../views/PlacesSearch.vue'),
  },

  {
    path: '/',
    // component: () => import('../views/MapView.vue'),
    name: 'MapView',
    component: MapView,
    meta: { title: defaultTitle, noCache: true },
    hidden: true,
  },

  {
    path: '/place/edit/:id(\\d+)',
    // path: '/place_edit',
    component: () => import('../views/admin/PlaceEdit.vue'),
    // component: 'views/PlaceEdit',
    name: 'EditPlace',
    meta: { title: 'Place editor', noCache: true },
    hidden: true,
  },

  {
    path: '/image/edit/:id(\\d+)',
    component: () => import('../views/admin/ImageEdit.vue'),
    name: 'EditImage',
    meta: { title: 'Image info editor', noCache: true },
    hidden: true,
  },
  {
    path: '/place/imageview/:id(\\d+)',
    component: () => import('../views/admin/PlaceImageView.vue'),
    name: 'PlaceImageView',
    meta: { title: 'View Image for place', noCache: true },
    hidden: true,
  },
  // not found
  {
    /* Wildcard path to catch other paths */
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: () => import('../views/NotFound.vue'),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  // console.log(`scrollBehavior: ${to} ${from} ${savedPosition}`);
  if (savedPosition) {
    return savedPosition;
  }
  return { top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
