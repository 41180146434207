<template>
  <div></div>
</template>

<script>
import { ref } from 'vue';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import '@/services/Leaflet.Photo';
import gallery from '@/plugins/gallery';
// import myhelper from '@/plugins/myhelper';

export default {
  name: 'GeoPhotos',
  props: {
    placeImages: { type: Array, required: true },
    minZoom: { type: Number, default: 10 },
    zomonFilter: {
      type: String,
      default: 'perphoto',
      validator(value) {
        return ['globalonly', 'perphoto'].includes(value);
      },
    },
    showLayer: { type: Boolean, default: true },
  },
  inject: {
    getLeafletMap: { default: ref(false) },
    mapOptions: { default: {} },
  },
  data() {
    return {
      lgElementID: 'lightGallery',
      photoLayer: null,
    };
  },
  watch: {
    showLayer(value) {
      if (value) {
        this.photoLayer.addToMap(this.myMap);
      } else {
        this.photoLayer.remove();
      }
    },
  },
  methods: {
    addPhotoOnMap() {
      // add layer on map
      this.photoLayer = L.photo.cluster({
        globalMinZoom: this.minZoom,
        zoomFilter: this.zomonFilter,
        currentZoom: this.currentZoom,
        defaultMinZoom: 10,
      });
      this.photoLayer.add(this.geoPhotos);

      // bind cluster click
      this.photoLayer.on('clusterclick', (evt) => {
        // console.log('evt', evt.layer.getAllChildMarkers());
        const imgList = evt.layer
          .getAllChildMarkers()
          .map((marker) => marker.photo)
          .sort((a, b) => {
            if (a.image_type === b.image_type) {
              return a.image_seqid - b.image_seqid;
            }
            const order = ['histexpphoto', 'histimage', 'painting', 'postcard', 'photo'];
            return order.indexOf(a.image_type) - order.indexOf(b.image_type);

            // // sort by place_id, image_seqid (if place_id is equal), filename_orig
            // if (a.place_id === b.place_id) {
            //   if (a.image_seqid === b.image_seqid) {
            //     return a.filename_orig.localeCompare(b.filename_orig);
            //   }
            //   return a.image_seqid - b.image_seqid;
            // }
            // return a.place_id - b.place_id;
          });
        gallery.show(imgList, 0, this.mapOptions.showDebugInfo, this.mapOptions.animation);
      });

      // bind click on single photo
      this.photoLayer.on('click', (evt) => {
        // console.log('evt', evt);
        const imgList = [evt.layer.photo];
        gallery.show(imgList, 0, this.mapOptions.showDebugInfo, this.mapOptions.animation);
      });
      if (this.showLayer) {
        this.photoLayer.addToMap(this.myMap);
      }
    },
    // onMapZoom() {
    //   const currentZoom = this.myMap.getZoom();
    //   const layerAdded = this.myMap.hasLayer(this.photoLayer);

    //   if (currentZoom < this.minZoom) {
    //     if (layerAdded) {
    //       console.log('Photolayer: removed');
    //       this.photoLayer.remove();
    //     }
    //   } else if (!layerAdded) {
    //     console.log('Photolayer: added');
    //     this.photoLayer.addTo(this.myMap);
    //   }
    // },
  },
  computed: {
    geoPhotos() {
      return this.placeImages
        .filter((image) => image.latitude && image.longitude)
        .map((image) => ({
          lat: image.latitude,
          lng: image.longitude,
          thumbnail:
            image.image_type === 'video'
              ? `https://img.youtube.com/vi/${image.video_id}/maxresdefault.jpg`
              : image.thumb,
          caption: image.title_ru,
          ...image,
        }));
    },
    myMap() {
      return this.getLeafletMap();
    },
    currentZoom() {
      return this.myMap.getZoom();
    },
  },
  mounted() {
    this.addPhotoOnMap();
  },
  beforeUnmount() {
    this.photoLayer.off();
    this.photoLayer.remove();
    // console.log('GeoPhotos: beforeUnmount45');
  },
};
</script>

<style>
.leaflet-marker-photo {
  border: 2px solid #fff;
  box-shadow: 3px 3px 10px #888;
}
.leaflet-marker-photo div {
  width: 100%;
  height: 100%;
  /* border-radius: 50px; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.leaflet-marker-photo b {
  position: absolute;
  top: -7px;
  right: -11px;
  color: #555;
  background-color: #fff;
  border-radius: 8px;
  height: 12px;
  min-width: 12px;
  line-height: 12px;
  text-align: center;
  padding: 3px;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
</style>
