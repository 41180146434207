<template>
  <div></div>
</template>
<script>
// import { ref, toRaw, nextTick } from 'vue';
import L from 'leaflet';
// import * as L from 'leaflet/dist/leaflet-src.esm';
import { mapGetters } from 'vuex';
import { ref } from 'vue';
import myhelper from '@/plugins/myhelper';
import 'leaflet-arrowheads';
// import leafletPolycolor from 'leaflet-polycolor';
// import { AntPath, antPath } from 'leaflet-ant-path';

export default {
  name: 'LMapPolyline',
  props: {
    leafletRef: { type: Object, required: true },
    groupBy: {
      type: String,
      required: true,
      validator(value) {
        return ['transport', 'pathaccuracy'].includes(value);
      },
    },
    show: {
      type: Boolean,
      default: true,
    },
    visitedOn: {
      type: String,
      default: 'all',
      validator(value) {
        return ['all', 'precae', 'indiancae', 'cae', 'manchuriaexp'].includes(value);
      },
    },
  },
  // watch groupby changes
  watch: {
    groupBy() {
      this.reAddPolylines();
    },
    show() {
      if (this.show) {
        this.myMap.addLayer(this.polylineLayer);
      } else {
        this.myMap.removeLayer(this.polylineLayer);
      }
    },
  },
  data() {
    return {
      polylineLayer: ref(new L.FeatureGroup()),
      polylineStyle: {
        default: {
          weight: 3.5,
          opacity: 0.99,
          smoothFactor: 1,
          lineCap: 'square',
          dashArray: '5, 10',
        },
        hiddenLine: {
          weight: 0,
        },
        byAccuracy: {
          high: {
            weight: 3.5,
            color: '#b4f',
            dashArray: '1',
          },
          low: {
            weight: 3.5,
            color: '#f00',
            dashArray: '5, 10',
          },
        },
        byTransport: {
          default: {
            color: '#00f', // unknown
          },

          horse: {
            color: '#b4f', // caravan
          },
          horsecart: {
            color: '#b40', // caravan
          },
          car: {
            color: '#b4f', // caravan
          },
          steamship: {
            color: '#f0f', // steam_or_rail
            dashArray: '5, 15',
          },
          train: {
            color: '#a00', // steam_or_rail
          },
        },
      },
    };
  },
  methods: {
    ...mapGetters('places', ['placesListByVisit']),
    pathPlaceList() {
      return this.placesListByVisit()(this.visitedOn).filter((place) => place.ispathpart);
    },
    reAddPolylines() {
      this.polylineLayer.clearLayers();
      this.addPolylinesOnMap();
    },
    getTransportType(transportType) {
      // if () transportType === 'horse' ? 'horse' : 'nonhorse';
      switch (transportType) {
        case 'horse':
          return 'caravan';
        case 'car':
          return 'caravan';
        default:
          return 'steam_or_rail';
      }
    },
    // getPathColorByP
    getStyleByTransportType(transportType) {
      const defaultColor = this.polylineStyle.byTransport.default;
      return this.polylineStyle.byTransport[transportType] || defaultColor;
    },
    getStyleByAccuracy(pathRefReq) {
      const accMap = this.polylineStyle.byAccuracy;
      return pathRefReq ? accMap.low : accMap.high;
    },
    getStyleByPlace(place) {
      // FIXME dirty hack - hardcoded hidden line from Darjeeling to Colombo
      if (place.place_id === 598) {
        return this.polylineStyle.hiddenLine;
      }
      if (this.groupBy === 'pathaccuracy') {
        return this.getStyleByAccuracy(place.path_ref_req);
      }
      return this.getStyleByTransportType(place.go_by);
    },

    splitPolyLineByStyle() {
      const result = this.pathPlaceList().reduce((acc, place) => {
        if (acc.length === 0) {
          acc.push([place]);
          return acc;
        }
        const lastPlace = acc.at(-1).at(-1);
        if (this.getStyleByPlace(lastPlace) === this.getStyleByPlace(place)) {
          acc.at(-1).push(place);
        } else {
          acc.at(-1).push({ ...place, routejson: '[]' }); // here should be only marker but without its routejson
          acc.push([place]);
        }
        return acc;
      }, []);
      return result;
    },

    // eslint-disable-next-line no-unused-vars
    plotPolyline(plotStyle, pathroute, layerGroup, simplify = true) {
      // set padding to 1 to show line out of the viewport
      const myRenderer = L.svg({ padding: 0.3 }); // L.canvas({ padding: 0.5 }); //
      // eslint-disable-next-line no-unused-vars
      const tolerance = 0.01; // best value is 0.001 for zoom level 12 and below.
      // const simplepathroute = this.simplifyLatlngs(pathroute, tolerance);
      // eslint-disable-next-line no-unused-vars
      // console.log(`Changed legth from ${pathroute.length} to ${simplepathroute.length}`);
      // const arrowheadoptions = {
      //   size: '10px',
      //   frequency: '150px',
      //   fill: true,
      //   yawn: 40,
      // };
      // .arrowheads(arrowheadoptions)

      L.polyline(pathroute, {
        ...this.polylineStyle.default,
        ...plotStyle,
        renderer: myRenderer,
      }).addTo(this.polylineLayer);
    },

    addPolylinesOnMap() {
      const splittedPlaces = this.splitPolyLineByStyle();
      splittedPlaces.forEach((placesList) => {
        // const polyPath = placesList.reduce((acc, place) => {
        //   acc.push(...myhelper.getPathFromPlace(place));
        //   return acc;
        // }, []);
        this.plotPolyline(
          this.getStyleByPlace(placesList[0]),
          myhelper.getPolyPathFromPlaces(placesList),
        );
      });
      if (this.show) {
        this.myMap.addLayer(this.polylineLayer);
      }
    },
    removePolylineLayer() {
      if (this.polylineLayer) {
        this.polylineLayer.clearLayers();
        this.myMap.removeLayer(this.polylineLayer);
      }
    },
    onStoreMutation(mutation) {
      if (mutation.type === 'places/UPDATE_PLACE') {
        this.$nextTick(() => {
          this.reAddPolylines();
        });
      }
    },
  },
  computed: {
    ...mapGetters('places', ['mapPathRoute', 'filledCAEPlacesList', 'isInitialized']),
    myMap() {
      return this.leafletRef.leafletObject;
    },
  },
  mounted() {
    // leafletPolycolor(L);
    // console.log('LMapPolyline mounted');

    if (this.isInitialized) {
      this.addPolylinesOnMap();
    } else {
      const unwatch = this.$watch('isInitialized', () => {
        this.addPolylinesOnMap();
        unwatch();
      });
    }
    this.storeUnsubscribe = this.$store.subscribe(this.onStoreMutation);
  },
  beforeUnmount() {
    this.removePolylineLayer();
    this.storeUnsubscribe();
    // console.log('umounted LMapPolyline');
  },
};
</script>
