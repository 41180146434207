<template><div></div></template>
<script>
// import { ref, toRaw, nextTick } from 'vue';
import L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import { mapGetters } from 'vuex';
import { ref, toRaw } from 'vue';
import gallery from '@/plugins/gallery';
import myhelper from '@/plugins/myhelper';

export default {
  name: 'LMapMarkers',
  props: {
    leafletRef: { type: Object, required: true },
    markersList: { type: Object, required: true },
    minZoom: { type: Number, required: true },
    maxZoom: { type: Number, default: 100 },
    selectedPlaceId: { type: Number, default: 0 },
    markerType: {
      required: false,
      validator(value) {
        // The value must match one of these strings
        return ['circle', 'marker'].includes(value);
      },
      default: 'marker',
    },
    circleRadius: { type: Number, default: 10 },
    circleWeight: { type: Number, default: 2 },
    markerinfo: {
      required: true,
      validator(value) {
        // The value must match one of these strings
        return ['tooltip', 'popup'].includes(value);
      },
    },
  },
  inject: {
    mapOptions: { default: {} },
  },
  data() {
    return {
      visPadding: 1,
      markersLayers: [],
      selectedMarkerLayer: null,
      mapFeatureGroup: ref(new L.FeatureGroup()),
      markerOptimizationMode: 'addRemoveMarkers', // markerInitHook or addRemoveMarkers
      markerOffset: {
        left: L.point({ x: -20, y: 0 }),
        right: L.point({ x: 20, y: 0 }),
        top: L.point({ x: 0, y: -25 }),
        bottom: L.point({ x: 0, y: 25 }),
      },
      circleMarkerOffset: {
        left: L.point({ x: 10, y: -20 }),
        right: L.point({ x: 30, y: -20 }),
        top: L.point({ x: 20, y: -30 }),
        bottom: L.point({ x: 20, y: -5 }),
      },
      icons: {
        PassedBy: L.AwesomeMarkers.icon({
          // icon: 'info-circle',
          icon: 'fa-chevron-circle-right',
          // icon: "anchor",
          iconColor: 'white',
          markerColor: 'blue',
          prefix: 'fa',
          extraClasses: 'fa-rotate-0',
          spin: false,
          tooltipAnchor: [20, -40],
        }),
        StayedTonight: L.AwesomeMarkers.icon({
          // icon: 'info-circle',
          icon: 'campground',
          iconColor: 'white',
          markerColor: 'blue',
          prefix: 'fa',
          extraClasses: 'fa-rotate-0',
          spin: false,
          tooltipAnchor: [20, -40],
        }),

        StayedLong: L.AwesomeMarkers.icon({
          // icon: 'info-circle',
          // icon: "anchor",
          iconColor: 'white',
          markerColor: 'red',
          prefix: 'fa',
          extraClasses: 'fa-rotate-0',
          spin: false,
          tooltipAnchor: [20, -40],
        }),

        MountainPass: L.AwesomeMarkers.icon({
          icon: 'fa-chevron-circle-up',
          // icon: "anchor",
          iconColor: 'white',
          markerColor: 'orange',
          prefix: 'fa',
          extraClasses: 'fa-rotate-0',
          spin: false,
          tooltipAnchor: [20, -40],
        }),
        OffExpedition: L.AwesomeMarkers.icon({
          // icon: 'check-circle',
          icon: 'fa-star-of-life',
          // icon: 'stop-circle',
          // icon: "anchor",
          iconColor: 'white',
          markerColor: 'darkred',
          prefix: 'fa',
          extraClasses: 'fa-rotate-0',
          spin: false,
          tooltipAnchor: [20, -40],
        }),
      },
    };
  },

  methods: {
    ...mapGetters('images', ['getImageByPlaceId']),
    createDynamicCss() {
      // console.log(this);
      this.$styleTag = document.createElement('style');
      this.$styleTag.appendChild(
        document.createTextNode(
          `.${this.mapHideClassName} .${this.markerClassName} { display: none; }`,
        ),
      );
      this.$styleTag.appendChild(
        document.createTextNode(
          `.${this.mapHideClassName} .${this.tooltipClassName} { display: none; }`,
        ),
      );
      document.head.appendChild(this.$styleTag);
    },
    onMarkerClick(e) {
      L.DomEvent.stopPropagation(e);
      // eslint-disable-next-line camelcase
      const { place_id } = e.target;
      this.$emit('markerClick', e, place_id);
    },
    onMarkerAdd(markerOnAdd) {
      // eslint-disable-next-line no-underscore-dangle
      toRaw(markerOnAdd.target)._shadow.classList.add(this.markerClassName);
      const markerElem = markerOnAdd.target.getElement();
      markerElem.classList.add(this.markerClassName);
    },
    isPlaceStayedLong: myhelper.isPlaceStayedLong,

    iconByPlace(place) {
      if (place.place_type === 'pass') {
        return this.icons.MountainPass;
      }
      if (place.visited_on === 'other' || !place.ispathpart) {
        return this.icons.OffExpedition;
      }
      if (place.date === place.enddate) {
        return this.icons.PassedBy;
      }
      if (place.date_format !== 'YYYY-MM-DD') {
        return this.icons.StayedTonight;
      }

      if (this.isPlaceStayedLong(place)) {
        return this.icons.StayedLong;
      }
      if (place.date !== place.enddate) {
        return this.icons.StayedTonight;
      }
      return this.icons.PassedBy;
    },

    onShowImageClick(event, placeId) {
      // if shiftKey pressed - send user to /place/imageview/:placeId
      event.preventDefault();
      L.DomEvent.stopPropagation(event);
      if (event.shiftKey) {
        this.$router.push({ path: `/place/imageview/${placeId}` });
        return;
      }
      const imagesForPlace = this.getImageByPlaceId()(placeId).filter((image) => {
        if (this.mapOptions.showAllPhotos) return true;
        return image.visibility_level === 'high';
      });
      gallery.show(imagesForPlace, 0, this.mapOptions.showDebugInfo, this.mapOptions.animation);
    },
    markerToLayer(marker, forSelectedMarker = false) {
      let layer;
      if (this.markerType === 'circle') {
        layer = L.circleMarker([marker.latitude, marker.longitude], {
          radius: this.circleRadius,
          weight: this.circleWeight,
          color: '#d33d29',
        });
      } else {
        layer = L.marker([marker.latitude, marker.longitude], { virtual: true });
        layer.setIcon(this.iconByPlace(marker));
        layer.on('add', (m) => this.onMarkerAdd(m));
      }
      layer.place_id = marker.place_id;
      layer.marker = toRaw(marker);
      layer.placedata = { id: marker.place_id, accuracy_level: marker.accuracy_level };
      layer.on('click', (event) => this.onMarkerClick(event));
      this.markerAddTooltip(layer, forSelectedMarker);
      return toRaw(layer);
    },
    markerAddTooltip(layer, forSelectedMarker) {
      const { marker } = layer;
      let tooltipOffset;
      if (this.markerType === 'circle') {
        tooltipOffset = this.circleMarkerOffset[marker.tooltip_position];
      } else {
        tooltipOffset = this.markerOffset[marker.tooltip_position];
      }
      const tooltiptext = myhelper.tooltipByMarker(marker, {
        forSelectedMarker,
        showModern: this.mapOptions.showModernTitle,
      });

      // eslint-disable-next-line no-param-reassign
      layer.tooltiptext = tooltiptext;
      // eslint-disable-next-line no-param-reassign
      layer.tooltipoptions = {
        offset: tooltipOffset,
        permanent: forSelectedMarker || this.markerinfo !== 'popup',
        direction: marker.tooltip_position,
        className: this.tooltipClassName,
        interactive: true,
      };
      layer.bindTooltip(tooltiptext, layer.tooltipoptions);
    },
    initMarkerLayers() {
      this.markersLayers = this.markersList.map((mapMarker) => {
        return this.markerToLayer(mapMarker);
      });
    },
    removeSingleMarkerTooltip(marker) {
      if (!marker) {
        return;
      }
      marker.unbindPopup();
      marker.unbindTooltip();
    },
    removeSingleMarkerLayer(marker) {
      if (!marker) {
        return;
      }
      this.removeSingleMarkerTooltip(marker);
      // remove all listeners
      marker.off();
      this.mapFeatureGroup.removeLayer(marker);
    },
    updateMarkersLayersList() {
      // check every markersLayers and place_id is not exist in markersList than remove it
      const placeIds = this.markersList.map((m) => m.place_id);
      this.markersLayers = this.markersLayers.filter((marker) => {
        if (!placeIds.includes(marker.place_id)) {
          console.log('Removing marker', marker.place_id, marker.title_ru);
          this.removeSingleMarkerLayer(marker);
          return false;
        }
        return true;
      });
      // if marerList contains a new marker - add it to markerLayers
      this.markersList.forEach((marker) => {
        if (!this.markersLayers.find((m) => m.place_id === marker.place_id)) {
          console.log('Adding marker', marker.place_id, marker.title_ru);
          const newMarker = this.markerToLayer(marker);
          this.markersLayers.push(newMarker);
        }
      });
    },
    isTooltipChanged(marker, mapMarker) {
      if (mapMarker.tooltip_position !== marker.tooltipoptions.direction) return true;
      if (mapMarker.accuracy_level !== marker.placedata.accuracy_level) return true;
      return false;
    },
    updateMarkersTooltips() {
      // iterate through markersLayers and compare tooltip_position with markersList
      const markers2Recreate = [];
      this.markersLayers.forEach((marker) => {
        const mapMarker = this.markersList.find((m) => m.place_id === marker.place_id);
        if (this.isTooltipChanged(marker, mapMarker)) {
          markers2Recreate.push(mapMarker);
        }
      });
      markers2Recreate.forEach((marker) => {
        const markerLayer = this.markersLayers.find((m) => m.place_id === marker.place_id);
        this.removeSingleMarkerLayer(markerLayer);
        const removeIndex = this.markersLayers.map((m) => m.place_id).indexOf(marker.place_id);
        if (removeIndex > -1) {
          this.markersLayers.splice(removeIndex, 1);
        }

        const newMarker = this.markerToLayer(marker);
        this.markersLayers.push(newMarker);
      });
      this.updateLayerVisibility();
    },
    removeMarkersFromMap() {
      this.markersLayers.forEach((marker) => {
        this.removeSingleMarkerLayer(marker);
      });
      this.myMap.removeLayer(this.mapFeatureGroup);
      if (this.selectedMarkerLayer) {
        this.myMap.removeLayer(this.selectedMarkerLayer);
      }
      this.myMap.off('zoomend resize moveend', this.onMapZoom);
    },
    addMarkersOnMap() {
      // console.log(`addMarkersOnMap ${this.markersList.length}`);
      // const t1 = performance.now();
      this.initMarkerLayers();
      this.updateLayerVisibility();
      this.updateSelectedMarkerVisibility();
      this.myMap.on('zoomend resize moveend', this.onMapZoom);
      // const diff = performance.now() - t1;
      // console.log(`addMarkersOnMap ${this.markersLayers.length} took ${diff} ms`);
    },

    updateLayerVisibility() {
      // const mapDiv = this.myMap.getContainer();
      const curZoom = this.mapOptions.showMarkers ? this.myMap.getZoom() : 1;
      // is Layer visible?
      if (curZoom < this.minZoom || curZoom > this.maxZoom) {
        // console.log(`Removing layer ${this.mapHideClassName}`);
        this.myMap.removeLayer(this.mapFeatureGroup);
        // mapDiv.classList.add(this.mapHideClassName);
      } else {
        // console.log(`Adding layer ${this.mapHideClassName}`);
        // this.myMap.removeLayer(this.mapFeatureGroup);
        this.updateMarkersVisibility();
        this.myMap.addLayer(this.mapFeatureGroup);
        // console.log(this.mapHideClassName);
        // mapDiv.classList.remove(this.mapHideClassName);
      }
    },
    showHideModernTitle() {
      this.markersLayers.forEach((markerLayer) => {
        this.removeSingleMarkerTooltip(markerLayer);
        this.markerAddTooltip(markerLayer);
      });
      if (this.selectedMarkerLayer) {
        this.removeSingleMarkerTooltip(this.selectedMarkerLayer);
        this.markerAddTooltip(this.selectedMarkerLayer, true);
      }
    },
    updateMarkersVisibility(clearLayers = false) {
      if (clearLayers) {
        // console.log('ClearLayers');
        this.mapFeatureGroup.clearLayers();
      }
      this.markersLayers.forEach((marker) => {
        let isVisible = this.myMap.getBounds().pad(this.visPadding).contains(marker.getLatLng());
        isVisible = isVisible && marker.place_id !== this.selectedPlaceId;
        if (isVisible) {
          this.mapFeatureGroup.addLayer(marker);
        } else {
          this.mapFeatureGroup.removeLayer(marker);
        }
      });
    },
    updateSelectedMarkerVisibility() {
      if (this.selectedMarkerLayer) {
        this.myMap.removeLayer(this.selectedMarkerLayer);
        this.selectedMarkerLayer.unbindPopup();
        this.selectedMarkerLayer.unbindTooltip();
        this.selectedMarkerLayer.off();
      }
      if (this.minZoom === this.maxZoom) {
        return;
      }

      if (this.selectedPlaceId === 0) {
        return;
      }
      const placeIds = this.markersList.map((m) => m.place_id);
      if (!placeIds.includes(this.selectedPlaceId)) {
        return;
      }
      // console.log('updateSeletced', this.selectedPlaceId);
      // forSelectedMarker;
      const marker = this.markersList.find((m) => m.place_id === this.selectedPlaceId);
      if (!marker) {
        return;
      }
      // console.log(marker);
      this.selectedMarkerLayer = this.markerToLayer(marker, true);
      // console.log(this.selectedMarkerLayer);
      this.myMap.addLayer(this.selectedMarkerLayer);
      // console.log(marker);
    },

    onMapZoom() {
      this.updateLayerVisibility();
    },
  },
  computed: {
    ...mapGetters('places', ['mapMarkersList', 'isInitialized']),
    markerLayerName() {
      return `mapMarkers (${this.minZoom}-${this.maxZoom})`;
    },
    showMarkers() {
      return this.mapOptions.showMarkers;
    },
    mapHideClassName() {
      return `hide-point-zoom-${this.markerinfo}-${this.minZoom}`;
    },
    markerClassName() {
      return `marker-zoom-${this.markerinfo}-${this.minZoom}`;
    },
    tooltipClassName() {
      return `tooltip-zoom-${this.markerinfo}-${this.minZoom}`;
    },
    myMap() {
      return this.leafletRef.leafletObject;
    },
    isLayerVisible: {
      cache: false,
      get() {
        const curZoom = this.mapOptions.showMarkers ? this.myMap.getZoom() : 1;
        return curZoom < this.minZoom || curZoom > this.maxZoom;
      },
    },
  },
  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line no-unused-vars
    markersList(newval) {
      // const filteredMarkers = newval.filter((m) => m.place_id === this.selectedPlaceId);
      // console.log(
      //   `markersList changed ${this.minZoom}, ${this.maxZoom}`,
      //   filteredMarkers[0]?.minzoom,
      // );
      this.updateMarkersVisibility(true);
      this.updateSelectedMarkerVisibility();
      this.updateMarkersLayersList();
      this.updateMarkersTooltips();
    },
  },
  mounted() {
    // console.log(`Init LMapMarkers with ${this.minZoom}, ${this.maxZoom}`);
    this.createDynamicCss();
    this.$watch('mapOptions.showMarkers', () => this.updateLayerVisibility());
    this.$watch('mapOptions.showModernTitle', () => this.showHideModernTitle());

    this.$watch('selectedPlaceId', () => {
      this.updateMarkersVisibility();
      this.updateSelectedMarkerVisibility();
    });
    window.onShowImageClick = this.onShowImageClick;

    if (this.isInitialized) {
      this.addMarkersOnMap();
    } else {
      const unwatch = this.$watch('isInitialized', () => {
        this.addMarkersOnMap();
        unwatch();
      });
    }
  },
  beforeUnmount() {
    if (this.$styleTag) {
      this.$styleTag.remove();
    }
    this.removeMarkersFromMap();
  },
};
</script>

<style>
/* .hide-point-layer .point-layer {
  display: none;
}

.hide-point-layer .tt-largezoom {
  display: none;
} */

.circle {
  background: #0000ff; /* color of the circle */
  border-radius: 50%; /* make the div a circular shape */
  box-shadow: 4px 4px 3px grey; /* see http://www.w3schools.com/css/css3_shadows.asp */
  -moz-box-shadow: 4px 4px 3px grey;
  -webkit-box-shadow: 4px 4px 3px grey;
}

.mountainpass-tooltip {
  width: 10px;
  height: 10px;
  /* background-color: yellow; */
  /* border: 1px solid black; */
  display: inline-block;
  vertical-align: middle;
}

/* leaflet-tooltip that contains imgcounter class in it - display: none  for leaflet-tooltip  using :has selector */
.leaflet-tooltip:has(.imgcount) {
  pointer-events: initial;
}

/* need to to make hover working */
.leaflet-tooltip {
  pointer-events: initial !important;
  padding: 2px 2px 2px 0px;
  /* font-size: 0.65rem; */
}
.mainmap-div .leaflet-tooltip .tooltip-place-title {
  padding: 4px 0 4px 4px;
}

.leaflet-tooltip .selected-place {
  font-weight: bold;
  font-size: 1.2em;
}

.leaflet-tooltip:has(.tooltip-place-title.selected-place) {
  background-color: #a2fdff;
}

.mainmap-div .leaflet-tooltip .tooltip-place-title:hover {
  background-color: #f5ff00;
  /* background-color: #f00; */
}
.leaflet-tooltip .imgcount:hover {
  background-color: #f5ff00;
  /* background-color: #f00; */
}
.leaflet-tooltip:has(.tooltip-place-title.selected-place) .imgcount:hover {
  padding-top: 6.9px;
  padding-bottom: 4.5px;
}

.leaflet-tooltip .tooltip-place-latlng {
  display: none;
}

@media print {
  .leaflet-tooltip .imgcount,
  .leaflet-tooltip .acclevel {
    display: none;
  }
  .leaflet-tooltip .tooltip-place-latlng {
    display: initial;
  }
}

.leaflet-tooltip:hover img.acclevel {
  display: inline-block;
  pointer-events: initial;
}

img.acclevel {
  height: 9px;
  /* background-color: yellow; */
  /* border: 1px solid black; */
  display: inline-block;
  /* display: none; */
  vertical-align: middle;
  /* padding-bottom: 7px; */
  padding-left: 3px;
  position: relative;
  bottom: 1.5px;
}

.imgcount img {
  width: 14px;
  height: 14px;
  /* background-color: yellow; */
  /* border: 1px solid black; */
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 2px;
  padding-right: 3px;
}

.imgcount {
  font-family: monospace;
  text-decoration: none;
  position: relative;
  padding-top: 4.5px;
  padding-bottom: 4px;
  padding-right: 4px;
  margin-right: -2px;
  /* color: #ff0; */
}
</style>
