<template>
  <div>
    <l-marker
      v-for="ayPlace in ayPlaces2"
      :key="ayPlace.id"
      :lat-lng="[ayPlace.latitude, ayPlace.longitude]"
      :radius="ayPlace.radius"
      :fill-color="'orange'"
      :fill-opacity="0.9"
      :stroke="false"
      @click="onMarkerClick($event, ayPlace.place_id)"
      class="aybook-marker"
    >
      <l-icon :icon-size="[ayPlace.radius, ayPlace.radius]">
        <div class="circle-container">
          <div
            class="gradient-circle"
            :style="{
              width: `${ayPlace.radius}px`,
              height: `${ayPlace.radius}px`,
              background: `radial-gradient(circle, rgb(250 118 0), rgb(255 28 0 / 19%))`,
            }"
          ></div>
        </div>
      </l-icon>
      <l-tooltip>
        <span v-html="ayplaceTooltip(ayPlace)" />
      </l-tooltip>
    </l-marker>
  </div>
</template>
<script>
// import L from 'leaflet';
import { mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { toRaw } from 'vue';
// import gallery from '@/plugins/gallery';
import myhelper from '@/plugins/myhelper';
import { LMarker, LCircleMarker, LTooltip, LIcon } from '@vue-leaflet/vue-leaflet';

export default {
  name: 'LHeatMap',
  data() {
    return {
      message: 'Hello, World!',
      ayPlaces: [],
      ayPlaces2: [],
      placesHash: [],
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LCircleMarker,
    LTooltip,
    LMarker,
    LIcon,
  },
  methods: {
    ...mapGetters('books', ['textIds']),
    dateRange2Array: myhelper.dateRange2Array,
    createPlacesHashWithDateAsKey: myhelper.createPlacesHashWithDateAsKey,
    // createDict_Place2AYBooksOld() {
    //   // console.time('Function #1');
    //   // iterate throught filledPlacesList
    //   // for each place, get the books
    //   // this.filledPlacesList.forEach((place) => {
    //   // if (place.place_id !== 624) {
    //   //   return;
    //   // }
    //   const textInfos = this.getAllTextIdsByDateRange({
    //     textDate: place.date,
    //     endDate: place.enddate,
    //   });
    //   if (textInfos.length > 0) {
    //     const aybooks = textInfos.filter((textInfo) => textInfo.book_type === 'ay');
    //     if (aybooks.length > 0) {
    //       // console.log('place', place);
    //       // console.log('aybooks', aybooks);
    //       this.ayPlaces.push({
    //         ...place,
    //         aybooks,
    //         radius: 10 * aybooks.length ** (1 / 5),
    //       });
    //     }
    //   }
    //   // console.timeEnd('Function #1');
    //   // console.log('ayPlaces', toRaw(this.ayPlaces));
    //   // console.log('ayPlaces', toRaw(this.ayPlaces.map((place) => place.radius)));
    // },
    // using date and enddate
    createDict_Place2AYBooks() {
      // console.time('Function #1');
      this.placesHash = this.createPlacesHashWithDateAsKey(this.filledPlacesList);
      // console.log(this.placesHash);
      // eslint-disable-next-line no-unused-vars
      const ayBooksMap = {};
      // FIXME: optimize it using book_id  in keys instead of book_type
      // eslint-disable-next-line no-unused-vars
      const bookTextIds = Object.values(this.textIds())
        .flat()
        .filter((textInfo) => textInfo.book_type === 'ay');
      // get hash with place_id as key and array of text_date as value
      bookTextIds.forEach((textInfo) => {
        const textDate = textInfo.text_date;
        if (this.placesHash[textDate]) {
          const placeId = this.placesHash[textDate].place_id;
          if (!ayBooksMap[placeId]) {
            ayBooksMap[placeId] = [];
          }
          ayBooksMap[placeId].push(textDate);
        }
      });
      // console.log(ayBooksMap);
      const aybooks2 = [];
      Object.keys(ayBooksMap).forEach((placeId) => {
        const place = this.placeByID(placeId);
        aybooks2.push({
          place_id: place.place_id,
          latitude: place.latitude,
          longitude: place.longitude,
          title_ru: place.title_ru,
          length: ayBooksMap[placeId].length,
          radius: 10 * ayBooksMap[placeId].length ** (1 / 5),
        });
      });
      // console.log(aybooks2);
      this.ayPlaces2 = aybooks2;
      // console.timeEnd('Function #1');
    },
    ayplaceTooltip(ayPlace) {
      return `
        <span>${ayPlace.title_ru} (${ayPlace.length})</span>
      `;
    },
    onMarkerClick(e, placeId) {
      this.$emit('markerClick', e, placeId);
    },
  },
  mounted() {
    this.createDict_Place2AYBooks();
  },
  computed: {
    ...mapGetters('places', ['isInitialized', 'filledPlacesList', 'placeByID']),
    ...mapGetters('books', ['getAllTextIdsByDateRange']),
  },
};
</script>

<style>
/* Your component styles here */
.circle-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  padding: 0px;
}

.gradient-circle {
  display: inline-block;
  margin: 0px; /* Adjust spacing between circles */
  border-radius: 50%;
  background-clip: border-box;
  background-size: cover;
  border: none; /* Ensure no border exists around the circle */
}
.aybook-marker-icon {
  border: none;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
</style>
