import lightgallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import myhelper from '@/plugins/myhelper';

function getTitleSpan(image) {
  return `${image.title_ru}`;
}
function getDebugSpan(image, showDebug) {
  if (myhelper.isMobile() || !showDebug) {
    return '';
  }
  const editSpan = `<span><a href="/image/edit/${image.image_id}">Edit</a></span>`;
  const filenameToShow = image.filename_orig ? image.filename_orig : image.filename;
  const filenameSpan = `<br /><span style="font-family: mono">filename=${filenameToShow}</span>`;
  const relatedSpan = image.related_image_id
    ? `&nbsp;<a href="/image/edit/${image.related_image_id}">Related</a></span>, `
    : '';
  // if coord exist
  const coordSpan =
    image.latitude && image.longitude
      ? `&nbsp;<span><i class="fa fa-map-marker-alt" style="color: #00ffa5"></i></span>`
      : '';
  const isNonEnabled = image.image_enabled ? `` : '<span style="color: red">disabled</span> ';
  return `<span style="font-family: mono">debug:${coordSpan} ${isNonEnabled}mz=${image.minzoom}, ${relatedSpan}p_id=${image.place_id}&nbsp;${editSpan}</span>${filenameSpan}`;
}
function getAuthorAndYearSpan(image) {
  const formattedDate = myhelper.humanizeImageDate(image);
  const authorSpan = image.image_type === 'painting' ? `<span>${image.author_ru}</span>` : '';
  const yearSpan = `<span>${formattedDate}</span>`;
  let authorAndYear = '';
  if ((authorSpan || formattedDate) && image.image_type === 'painting') {
    authorAndYear = `${authorSpan}, ${yearSpan}`;
  }
  if (image.image_type !== 'painting' && image.ownership === '' && formattedDate) {
    authorAndYear = `${yearSpan}`;
  }
  if (image.image_type !== 'painting' && image.ownership !== '' && formattedDate) {
    authorAndYear = `${yearSpan}`;
  }
  const ownershipSpan = image.ownership ? `&nbsp;&nbsp;<span>${image.ownership}</span>` : '';
  return `${authorAndYear}${ownershipSpan}`;
}

function subHtml(image, showDebug) {
  const titleSpan = getTitleSpan(image);
  const debugSpan = getDebugSpan(image, showDebug);
  const authorAndYear = getAuthorAndYearSpan(image);
  const additionalclass = authorAndYear ? '' : 'lg-title-alone';
  let renderedHtml = `<div class="lg-title-span ${additionalclass}">${titleSpan}</div>`;
  if (authorAndYear) {
    renderedHtml += `<div class="lg-author-year">${authorAndYear}</div>`;
  }
  if (debugSpan) {
    renderedHtml += debugSpan;
  }
  return renderedHtml;
}

function show(images, slidenum = 0, showDebug = false, animation = true) {
  const source = 'store';
  // console.log(images);
  let dynamicEl;
  if (source === 'store') {
    dynamicEl = images.map((image) => ({
      // src: `${process.env.VUE_APP_API_URL}/image/${image.filename}`,
      src:
        image.image_type === 'video'
          ? `https://www.youtube.com/${image.video_id}?start=${image.video_starttime}&mute=1`
          : image.url,
      poster:
        image.image_type === 'video'
          ? `https://img.youtube.com/vi/${image.video_id}/maxresdefault.jpg`
          : '',
      thumb: image.thumb,
      // thumb: `${process.env.VUE_APP_API_URL}/image/thumb/${image.filename}`,
      subHtml: subHtml(image, showDebug),
      downloadUrl: `${process.env.VUE_APP_API_URL}/images/download/${image.image_id}`,
      // download: 'filename.jpg',
    }));
  }
  // if (source === 'cluster') {
  //   dynamicEl = images.map((photo) => ({
  //     src: photo.photo.url,
  //     thumb: photo.photo.url,
  //     // subHtml: photo.photo.caption,
  //     subHtml: `<div><span style="font-size: 1.2em; font-weight: bold;">${photo.photo.title_ru}</span><span>${photo.photo.year}&nbsp;<a href="/image/edit/${photo.photo.image_id}">Edit</a></span></div>`,
  //   }));
  // }

  const gallery = lightgallery(document.getElementById('lightGallery'), {
    dynamic: true,
    hash: false,
    closable: true,
    showMaximizeIcon: false,
    speed: animation ? 400 : 0,
    isMobile: myhelper.isMobile(),
    download: false,
    mobileSettings: { controls: false, showCloseIcon: true, download: false },
    // appendSubHtmlTo: '.lg-item', // commented to avoid placing title over the image
    slideDelay: 100,
    // show counter if count of photos > 1
    counter: dynamicEl.length > 1,
    // toggleThumb: true,
    // allowMediaOverlap: dynamicEl.length === 1, // commented to avoid placing title over the image
    thumbnail: dynamicEl.length > 1,
    plugins: [lgZoom, lgThumbnail, lgVideo],
    dynamicEl,
  });
  gallery.openGallery(slidenum);
  function destroyGallery() {
    console.log('destroy gallery');
    gallery.destroy();
    gallery.el.removeEventListener('lgAfterClose', destroyGallery);
    window.destroyLightGallery = undefined;
  }
  window.destroyLightGallery = destroyGallery;

  gallery.el.addEventListener('lgAfterClose', destroyGallery);
}

export default {
  show,
};
