<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <l-control position="topleft">
    <div class="leaflet-control-settings">
      <el-button
        @click="showSettings()"
        style="width: 30px; height: 30px; padding: 0; margin: 0; border: none"
      >
        <el-icon :size="18"><i style="color: #000" class="fa fas fa-cog"></i></el-icon>
      </el-button>
    </div>
  </l-control>

  <div></div>
</template>

<script>
import { LControl } from '@vue-leaflet/vue-leaflet';
import eventBus from '@/services/eventBus';

export default {
  components: {
    LControl,
  },
  methods: {
    showSettings() {
      eventBus.emit('open-sidebar'); // Emit the event
    },
  },
};
</script>

<style scoped>
.leaflet-control-settings .el-button {
  border-radius: 0;
}
.leaflet-control-settings .el-icon {
  width: 34px;
}
.leaflet-pseudo-fullscreen .leaflet-control-settings {
  display: none;
}
.leaflet-control {
  border: 2px solid rgba(0, 0, 0, 0.2);
}
@media (max-height: 500px) {
  .leaflet-control {
    border-left: none;
    border-right: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
    border-bottom: 1px solid #a3a3a3;
  }
}
</style>
