<script>
import L from 'leaflet';
// eslint-disable-next-line no-unused-vars
import { onMounted, ref, inject, nextTick, onBeforeUnmount } from 'vue';

import 'leaflet.gridlayer.googlemutant';
// eslint-disable-next-line no-unused-vars
import { InjectionKeys, Functions, Utilities } from '@vue-leaflet/vue-leaflet';

const props = {
  options: {
    type: Object,
    default() {
      return {};
    },
  },
  apikey: {
    type: String,
    default() {
      return '';
    },
  },
  lang: {
    type: String,
    default: null,
  },
  region: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: '',
  },
  layerType: {
    type: String,
    default: 'base',
  },
  visible: {
    type: Boolean,
    default: true,
  },
};

// export default {
//   name: 'VueLeafletGoogleMutant',
//   // components: {
//   //   L,
//   // },
//   props,
//   mounted() {
//     console.log(this.options);
//     this.mapObject = L.gridLayer.googleMutant(this.options);
//     this.parentContainer = findRealParent(this.$parent);
//     this.parentContainer.addLayer(this, !this.visible);
//   },
// };

export default {
  props,
  setup(props1, context) {
    // console.log(L);
    // console.log(props1.options);
    const leafletObject = ref({});
    const addLayer = inject(InjectionKeys.AddLayerInjection);
    // const removeLayer = inject(InjectionKeys.RemoveLayerInjection);
    // eslint-disable-next-line no-unused-vars
    const { options, methods } = Functions.GridLayer.setupGridLayer(props1, leafletObject);
    // const { methods: controlMethods } = Functions.ControlLayers.setupControlLayers(
    //   props1,
    //   leafletObject,
    // );
    // console.log('Options: ', options);
    // console.log('Methods: ', controlMethods);
    onMounted(async () => {
      // const { tileLayer, DomEvent } = L;
      // console.log(Utilities);
      leafletObject.value = L.gridLayer.googleMutant(props1.options);
      if (props1.name === 'Transit') {
        leafletObject.value.addGoogleLayer('TransitLayer');
      }
      if (props1.name === 'Traffic') {
        // console.log('TrafficLayer adding');
        leafletObject.value.addGoogleLayer('TrafficLayer');
      }
      // const listeners = remapEvents(context.attrs);
      // DomEvent.on(leafletObject.value, listeners);
      // propsBinder(methods, leafletObject.value, props);
      addLayer({
        // ...props,
        // ...methods,
        leafletObject: leafletObject.value,
        layerType: 'base',
        visible: props1.options.visible,
        name: props1.name,
      });

      // addLayer({
      //   ...props1,
      //   ...methods,
      //   leafletObject: leafletObject.value,
      // });
      nextTick(() => context.emit('ready', leafletObject.value));
    });
    // onBeforeUnmount(() => {
    // leafletObject.value.remove();
    // removeLayer({ leafletObject: leafletObject.value });
    // console.log(leafletObject.value.addBaseLayer);
    // controlMethods.removeLayer({ leafletObject });
    // });
    return { leafletObject };
  },
  render() {
    return null;
  },
};
</script>

<style>
.leaflet-control-layers label {
  text-align: left;
}
</style>
