// eslint-disable-next-line no-unused-vars
const zoom2distance = {
  1: 50000000,
  2: 25000000,
  3: 12500000,
  4: 6500000,
  5: 3000000,
  6: 1500000,
  7: 750000,
  8: 400000,
  9: 200000,
  10: 100000,
  11: 50000,
  12: 25000,
  13: 12500,
  14: 6000,
  15: 3000,
  16: 1500,
  17: 750,
  18: 400,
  19: 200,
  20: 100,
};

const mapLinkGens = {
  bingMapLink(latlng, zoom) {
    // console.log(latlng);
    // const link = 'https://geohack.toolforge.org/geohack.php?language=ru&pagename=%D0%9A%D0%B0%D1%80%D0%B0%D1%88%D0%B0%D1%80&params=42.05_N_86.56667_E_scale:8000';
    const link = `https://www.bing.com/maps?v=2&cp=${latlng.lat}%7E${latlng.lng}&lvl=${zoom}`;
    // https://maps.bing.com/default.aspx?v=2&cp=42.05~86.56667&style=r&lvl=16&sp=Point.42.05_86.56667_%D0%9A%D0%B0%D1%80%D0%B0%D1%88%D0%B0%D1%80___
    // const title = 'Bing';
    return link;
    // return { href: link, title };
    // return `<a target=_blank href=${link}>Bing</a>`;
  },
  googleMapLink(latlng, zoom) {
    return `https://www.google.com/maps/@${latlng.lat},${latlng.lng},${zoom}z`;
  },
  geohackMapLink(latlng, zoom) {
    const scale = 2 ** (19 - zoom) * 1000;
    return `https://geohack.toolforge.org/geohack.php?language=ru&params=${latlng.lat}_N_${latlng.lng}_E_scale:${scale}`;
  },

  nakarteMapLink(latlng, zoom) {
    return `https://nakarte.me/#m=${zoom}/${latlng.lat}/${latlng.lng}&l=T`;
    // return `https://nakarte.me/#m=7/49.73868/33.45612&l=O`;
  },

  // nakarteMapyczMapLink(latlng, zoom) {
  //   return `https://nakarte.me/#m=${zoom}/${latlng.lat}/${latlng.lng}&l=Czt`;
  // },

  wmflabsMapLink(latlng, zoom) {
    // return `https://nakarte.me/#m=${zoom}/${latlng.lat}/${latlng.lng}&l=Czt`;
    return `https://wma.wmflabs.org/iframe.html?wma=${latlng.lat}_${latlng.lng}_700_500_ru_${zoom}_en&globe=Earth&lang=ru&page=%D0%9A%D0%B0%D1%80%D0%B0%D1%88%D0%B0%D1%80`;
  },

  wikimapiaMapLink(latlng, zoom) {
    // return `https://nakarte.me/#m=${zoom}/${latlng.lat}/${latlng.lng}&l=Czt`;
    return `https://wikimapia.org/#lang=ru&lat=${latlng.lat}&lon=${latlng.lng}&z=${zoom}&m=w`;
  },

  copernixMapLink(latlng, zoom) {
    // return `https://nakarte.me/#m=${zoom}/${latlng.lat}/${latlng.lng}&l=Czt`;
    return `https://copernix.io/#?where=${latlng.lng},${latlng.lat},${zoom}&?query=&?map_type=hybrid`;
  },
  appleMapLink(latlng, zoom) {
    return `https://maps.apple.com/?ll=${latlng.lat},${latlng.lng}&z=${zoom}`;
  },
  retroMapMongolia1916Link(latlng, zoom) {
    const maxZoom = 8;
    const z = Math.min(zoom, maxZoom);
    return `https://retromap.ru/1519162_z${z}_${latlng.lat},${latlng.lng}`;
  },
  retroMapAsia1911Link(latlng, zoom) {
    const maxZoom = 8;
    const z = Math.min(zoom, maxZoom);
    return `https://retromap.ru/14191132_z${z}_${latlng.lat},${latlng.lng}`;
  },
  oldMapsOnlineLink(latlng, zoom) {
    const maxZoom = 12;
    const z = Math.min(zoom, maxZoom);
    return `https://www.oldmapsonline.org/en/#position=${z}/${latlng.lat}/${latlng.lng}&year=1850`;
  },
  googleEarthLink(latlng, zoom) {
    // const distance = zoom2distance[zoom];
    const windowSize = window.innerHeight;
    const degree = Math.PI / 180;
    const viewAngle = 35;
    const distInPixels = windowSize / 2 / Math.tan((viewAngle * degree) / 2);
    const earthPerimeter = 40075016;
    const elevation = 8000;
    const mercatorPixelSize = (earthPerimeter / (256 * 2 ** zoom)) * Math.cos(latlng.lat * degree);
    const distance = distInPixels * mercatorPixelSize + elevation;
    return `https://earth.google.com/web/@${latlng.lat},${latlng.lng},${distance}d,35y,0h,0t,0r/data=ClQaUxJJCiUweDQyMzYwNzIwMzYwMzYwMzY6MHg4NjY2NzY3NzY3NzY3NzY`;
    // return `https://earth.google.com/web/search/${latlng.lat},${latlng.lng}/`;
  },
  etoMestoMapLink(latlng) {
    // example http://www.etomesto.ru/map-index/?x=93.2863&y=35.8072
    return `http://www.etomesto.ru/map-index/?x=${latlng.lng}&y=${latlng.lat}`;
  },
  pastvuMapLink(latlng, zoom) {
    const maxZoom = 10;
    const z = Math.min(zoom, maxZoom);
    // pastvu.com?g=47.89793,106.770629&z=9&s=osm&t=kosmosnimki&type=1&y=1847&y2=1931
    return `https://pastvu.com/?g=${latlng.lat},${latlng.lng}&z=${z}&y2=1930`;
  },
};

const mapList = {
  'Google Map': mapLinkGens.googleMapLink,
  'Google Earth': mapLinkGens.googleEarthLink,
  'Bing Road': mapLinkGens.bingMapLink,
  GeoHack: mapLinkGens.geohackMapLink,
  Nakarte: mapLinkGens.nakarteMapLink,
  'Это Место': mapLinkGens.etoMestoMapLink,
  'Карта Монголии 1916': mapLinkGens.retroMapMongolia1916Link,
  'Карта Азии 1911': mapLinkGens.retroMapAsia1911Link,
  'OldMaps Online': mapLinkGens.oldMapsOnlineLink,
  Wikimapia: mapLinkGens.wikimapiaMapLink,
  // 'Map.cz': mapLinkGens.nakarteMapyczMapLink,
  Wmflabs: mapLinkGens.wmflabsMapLink,
  Copernix: mapLinkGens.copernixMapLink,
  AppleMaps: mapLinkGens.appleMapLink,
  PastVu: mapLinkGens.pastvuMapLink,
};

const funcs = {
  bar() {
    console.log('bar');
  },
  baz() {
    funcs.foo();
    funcs.bar();
  }, // here is the fix

  genLink(link) {
    return `<a target=_blank href=${link.href}>${link.title}</a>`;
  },
  getExernalLinks2(latlng, zoom) {
    // console.log(latlng);
    // console.log(zoom);
    const links = [];
    Object.keys(mapList).forEach((mapName) => {
      // for (const mapName in mapList) {
      // console.log(mapName);
      const f = mapList[mapName];
      // console.log(f);
      // console.log(f());
      // htmlstring += this.genLink(f(latlng));
      // const genlink = this.genLink({ href: f(latlng, zoom), title: mapName });
      links.push({ href: f(latlng, zoom), title: mapName });
      // htmlstring += `<li>${this.genLink({ href: f(latlng, zoom), title: mapName })}</li>`;
    });
    // htmlstring += '</ul>';

    // console.log('<p> Hello popup55 </p>');
    // this.bar();
    return links;
  },

  getExernalLinks(latlng, zoom) {
    // console.log(latlng);
    // console.log(zoom);
    let htmlstring = `<p><b> Map links </b>(zoom: ${zoom})</p>`;
    htmlstring += '<ul>';
    Object.keys(mapList).forEach((mapName) => {
      // for (const mapName in mapList) {
      // console.log(mapName);
      const f = mapList[mapName];
      // console.log(f);
      // console.log(f());
      // htmlstring += this.genLink(f(latlng));
      htmlstring += `<li>${this.genLink({ href: f(latlng, zoom), title: mapName })}</li>`;
    });
    htmlstring += '</ul>';

    // console.log('<p> Hello popup55 </p>');
    // this.bar();
    return htmlstring;
  },
};

export default funcs;
