import myhelper from '@/plugins/myhelper';
import tokml from 'geojson-to-kml';

function Place2GeoJson(Place) {
  const elevation = 0;
  const lat = Place.latitude;
  const lng = Place.longitude;
  const title = myhelper.getPlaceTitle(Place, true);
  return {
    type: 'Feature',
    properties: {
      name: title,
    },
    geometry: {
      type: 'Point',
      coordinates: [lng, lat, elevation],
    },
  };
}

function Places2GeoJson(Places) {
  const features = [];
  Places.forEach((place) => {
    const feature = Place2GeoJson(place);
    features.push(feature);
  });
  return features;
}

function Polyline2GeoJson(points) {
  const reversedpoints = [];
  // iterate every point and change lat, lng to lng, lat
  points.forEach((point) => {
    reversedpoints.push([point[1], point[0]]);
  });
  return {
    type: 'Feature',
    properties: {
      name: 'Path',
    },
    geometry: {
      type: 'LineString',
      coordinates: reversedpoints,
    },
  };
}

function Export2GeoJson(Places, Polyline) {
  const features = [];
  const allPlaces = Places2GeoJson(Places);
  const PolyLineFeature = Polyline2GeoJson(Polyline);
  features.push(...allPlaces);
  features.push(PolyLineFeature);
  return {
    type: 'FeatureCollection',
    features,
  };
}

function saveAs(filename, format, Places, Polyline) {
  const geojson = Export2GeoJson(Places, Polyline);
  const type = 'text/plain';
  let data;
  // if format is not supported
  if (format !== 'geojson' && format !== 'kml') {
    console.error('Unsupported format');
    return;
  }
  const fileext = format === 'geojson' ? 'geojson' : 'kml';
  if (format === 'geojson') {
    data = JSON.stringify(geojson, null, 2);
  }
  if (format === 'kml') {
    data = JSON.stringify(geojson, null, 2);
    data = tokml(geojson);
  }

  myhelper.generateFileFromData(data, `${filename}.${fileext}`, type);
}

export default {
  Export2GeoJson,
  Places2GeoJson,
  Place2GeoJson,
  Polyline2GeoJson,
  saveAs,
};
