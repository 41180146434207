<template>
  <l-geo-json :geojson="geojson" :options-style="geoStyler" ref="geoJson"></l-geo-json>
</template>
<script>
import { LGeoJson } from '@vue-leaflet/vue-leaflet';
// import { toRaw } from 'vue';
// eslint-disable-next-line no-unused-vars
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import toGeoJSON from '@mapbox/togeojson';

export default {
  name: 'LMapTrack',
  props: {
    trackurl: { type: String, required: true },
  },
  components: {
    LGeoJson,
  },
  inject: {
    tracksList: { default: {} },
  },
  data() {
    return {
      geojson: null,
      currentTrack: {},
      geoStyler: (feature) => ({
        opacity: feature.properties.code / 100000,
      }),
    };
  },
  computed: {},
  async created() {
    // console.log(this.trackurl);
    // console.log(toRaw(this.tracksList));
    const response = await fetch(this.trackurl);
    const kmlXml = new DOMParser().parseFromString(await response.text(), 'text/xml');
    // const toGeoJSON = require('togeojson');

    this.geojson = toGeoJSON.kml(kmlXml);
    // console.log(toRaw(this.geojson));
    const trackName = this.geojson.features[0].properties.description;
    this.currentTrack = { visible: true, name: trackName, url: this.trackurl };
    // console.log(this.currentTrack);
    this.tracksList[trackName] = this.currentTrack;
    // console.log(toRaw(this.tracksList));
    this.$watch('currentTrack.visible', (newVal, oldVal) => {
      console.log('changed', oldVal, newVal);
      if (newVal) {
        this.showTrack();
      } else {
        this.hideTrack();
      }
    });

    // console.log(toRaw(this.geojson.features[0].properties.description));

    // this.geojson = await response.json();
  },
  // mounted() {
  //   console.log(this.$refs.geoJson.leafletObject.clearLayers());
  // },
  methods: {
    showTrack() {
      this.$refs.geoJson.leafletObject.addData(this.$refs.geoJson.geojson);
      // this.$refs.geoJson.visible = true;
      // this.$refs.geoJson.setStyle({ opacity: 1 });
    },
    hideTrack() {
      this.$refs.geoJson.leafletObject.clearLayers();
      // this.$refs.geoJson.visible = false;
      // this.$refs.geoJson.setStyle({ opacity: 0 });
    },
  },
};
</script>
