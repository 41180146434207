<template>
  <!-- Actual auto complete -->
  <div id="place-autocomplete">
    <!-- eslint-disable max-len -->
    <el-autocomplete
      v-model="state"
      :fetch-suggestions="FuzzySearchByTitle"
      class="inline-input w-80"
      popper-class="my-autocomplete"
      placeholder="Поиск по названию..."
      @select="handleSelect"
      @handleKeyEnter="handleKeyEnter"
      :trigger-on-focus="false"
      ref="autocomplete"
    >
      <!-- https://medium.com/js-dojo/inline-svg-icons-in-vuejs-6b3e3f488912 -->
      <!-- https://calebporzio.com/using-inline-svgs-in-vue-compoments -->

      <template #prefix>
        <div>
          <!-- FIXME - start using SVG or remove search.svg file -->
          <!-- <img
            alt="Vue logo"
            class="logo"
            :src="require('@/assets/search.svg')"
            width="125"
            height="125"
          /> -->
          <svg
            viewBox="0 0 16 16"
            fill="none"
            preserveAspectRatio="xMidYMid meet"
            class="searchModalHeaderIcon"
            style="vertical-align: middle"
          >
            <g clip-path="url(#Search_svg__clip0_1372_9691)" fill="currentColor">
              <path
                d="M6.9 4.5a.6.6 0 01.6-.6 3.6 3.6 0 013.6 3.6.6.6 0 11-1.2 0 2.4 2.4 0 00-2.4-2.4.6.6 0 01-.6-.6z"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M.9 7.5a6.6 6.6 0 1111.672 4.223l2.352 2.353a.6.6 0 01-.848.848l-2.353-2.352A6.6 6.6 0 01.9 7.5zm6.6-5.4a5.4 5.4 0 100 10.8 5.4 5.4 0 000-10.8z"
              ></path>
            </g>
            <defs>
              <clipPath id="Search_svg__clip0_1372_9691">
                <path fill="#fff" d="M0 0h16v16H0z"></path>
              </clipPath>
            </defs>
          </svg>
        </div>
      </template>
      <template #default="{ item }">
        <div style="float: left">
          <div class="place-title" v-html="item.markedTitle"></div>
          <span class="place-date">{{ formatItemDate(item) }}</span>
        </div>
        <div style="float: right">
          <p class="searchResultActionText" style="color: #0049ff">
            <svg
              viewBox="0 0 16 16"
              fill="none"
              preserveAspectRatio="xMidYMid meet"
              class="searchResultActionIcon_dkZSk"
              style="vertical-align: middle; width: 16px; color: #0049ff"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.4 3.5a.6.6 0 01.6-.6h2a3.6 3.6 0 010 7.2H4.449l1.975 1.976a.6.6 0 01-.848.848l-3-3a.6.6 0 010-.848l3-3a.6.6 0 01.848.848L4.45 8.9H10a2.4 2.4 0 100-4.8H8a.6.6 0 01-.6-.6z"
                fill="currentColor"
              ></path>
            </svg>

            Перейти к месту
          </p>
        </div>
        <div style="clear: both"></div>
      </template>
    </el-autocomplete>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRaw, nextTick } from 'vue';
// import L from 'leaflet';
import { mapGetters } from 'vuex';
import '@/services/leaflet-searchbox';
import myhelper from '@/plugins/myhelper';
import uFuzzy from '@leeoniya/ufuzzy';

export default {
  name: 'PlacesAutocomplete',
  data() {
    return {
      dialogSearchVisible: ref(false),
      state: ref(''),
    };
  },
  props: {
    isVisible: { type: Boolean, required: true },
  },
  methods: {
    getPlaceTitle: myhelper.getPlaceTitle,
    getPlaceFullTitle: myhelper.getPlaceFullTitle,
    ...mapGetters('places', ['searchByTitle']),
    watchVisibility() {
      this.onVisibilityChange(this.isVisible);
      this.$watch('isVisible', this.onVisibilityChange);
    },
    formatItemDate(item) {
      return myhelper.humanizeDateRange(item.date, item.enddate, item.date_format);

      // if (!item.date) {
      //   return '';
      // }
      // if (!item.enddate) {
      //   return item.date;
      // }
      // return `${item.date} - ${item.enddate}`;
    },
    onVisibilityChange(v) {
      try {
        if (v) {
          this.$refs.autocomplete.inputRef.focus();
        }
      } catch (error) {
        console.warn(error);
      }
    },
    handleSelect(item) {
      // console.log('handleSelect');
      this.$emit('onPlaceSelect', item);
    },
    handleKeyEnter(ev) {
      // console.log('Enter event');
      console.log(ev);
    },
    FuzzySearchByTitle(query, cb) {
      try {
        // this.$refs.autocomplete.$refs.suggestionsList.$el.scrollTop = 0;
        document.getElementsByClassName(
          'el-autocomplete-suggestion__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default',
        )[0].scrollTop = 0;
      } catch (error) {
        console.warn(error);
      }
      if (query.length <= 1) {
        cb([]);
        return;
      }
      const needle = myhelper.removeDiacritics(query);
      const allPlaces = this.$store.getters['places/filledPlacesList'].filter(
        (place) => place.minzoom > 0,
      );

      const haystack = allPlaces.map((p) => this.getPlaceFullTitle(p, true));
      const haystackNorm = haystack.map((p) => myhelper.removeDiacritics(p));
      // pre-filter
      if (!needle) {
        return;
      }
      const idxs = this.uf.filter(haystackNorm, needle);
      const info = this.uf.info(idxs, haystackNorm, needle);
      const order = this.uf.sort(info, haystackNorm, needle);
      // console.log(idxs);
      // const innerHTML = '';
      const r = [];

      for (let i = 0; i < order.length; i += 1) {
        const infoIdx = order[i];
        // console.log(allPlaces[info.idx[order[i]]]);

        r.push({
          ...allPlaces[info.idx[order[i]]],
          markedTitle: uFuzzy.highlight(haystack[info.idx[infoIdx]], info.ranges[infoIdx]),
        });
        // console.log(r);
      }
      cb(r);
    },
  },
  created() {
    // eslint-disable-next-line new-cap
    this.uf = myhelper.uFuzzyInit();
  },
  mounted() {
    // console.log(`${this.$options.name} mounted`);
    this.watchVisibility();
  },
};
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Inter'); */

.searchModalHeaderIcon {
  width: 18px;
  height: 18px;
  color: #8899a8;
}

.searchResultActionText {
  color: #0049ff;
  font-family: 'Inter', sans-serif;
  /* font-weight: 500; */
}

#place-autocomplete .inline-input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.my-autocomplete li .name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.my-autocomplete li .addr {
  font-size: 12px;
  color: #b4b4b4;
}
.my-autocomplete li .highlighted .addr {
  color: #ddd;
}

.my-autocomplete li .place-title {
  font-size: 1em;
}
.my-autocomplete li .place-title mark {
  /* color: #f00; */
  color: #001aff;
  background-color: #faaeae00;
  font-weight: bold;
}

.my-autocomplete li .place-date {
  font-family: monospace;
}

.searchResultActionIcon_dkZSk {
  width: 16px;
  color: #0049ff;
}

.el-overlay {
  background-color: #000000c2 !important;
}
</style>
