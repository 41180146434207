/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-underscore-dangle */

// eslint-disable-next-line no-unused-vars
import { MarkerClusterGroup } from 'leaflet.markercluster';

const L = require('leaflet');

L.Photo = L.FeatureGroup.extend({
  options: {
    icon: {
      iconSize: [40, 40],
    },
  },

  initialize(photos, options) {
    L.setOptions(this, options);
    L.FeatureGroup.prototype.initialize.call(this, photos);
  },

  addLayers(photos) {
    if (photos) {
      for (let i = 0, len = photos.length; i < len; i += 1) {
        this.addLayer(photos[i]);
      }
    }
    return this;
  },

  addLayer(photo) {
    L.FeatureGroup.prototype.addLayer.call(this, this.createMarker(photo));
  },

  removeLayer(layer) {
    L.FeatureGroup.prototype.removeLayer.call(this, layer);
  },

  createMarker(photo) {
    const marker = L.marker(photo, {
      icon: L.divIcon(
        L.extend(
          {
            html: `<div style="background-image: url(${photo.thumbnail});"></div>​`,
            className: 'leaflet-marker-photo',
          },
          photo,
          this.options.icon,
        ),
      ),
      title: photo.caption || '',
    });
    marker.photo = photo;
    return marker;
  },
});

L.photo = (photos, options) => {
  return new L.Photo(photos, options);
};

if (L.MarkerClusterGroup) {
  L.Photo.Cluster = MarkerClusterGroup.extend({
    options: {
      featureGroup: L.photo,
      maxClusterRadius: 80,
      // showCoverageOnHover: false,
      // zoomToBoundsOnClick: false,
      spiderfyOnEveryZoom: false,
      zoomToBoundsOnClick: false,
      spiderfyOnMaxZoom: false,
      // eslint-disable-next-line no-unused-vars
      iconCreateFunction(cluster) {
        return new L.DivIcon(
          L.extend(
            {
              className: 'leaflet-marker-photo',

              html: `<div style="background-image: url(${
                cluster.getAllChildMarkers()[0].photo.thumbnail
              });"></div>​<b>${cluster.getChildCount()}</b>`,
            },
            this.icon,
          ),
        );
      },
      icon: {
        iconSize: [40, 40],
      },
    },

    initialize(options) {
      // eslint-disable-next-line no-param-reassign
      options = L.Util.setOptions(this, options);
      this.globalMinZoom = options?.globalMinZoom || 0;
      this.currentZoom = options?.currentZoom || 12;
      this.defaultMinZoom = options?.defaultMinZoom || 10;

      L.MarkerClusterGroup.prototype.initialize.call(this);
      this._photos = options.featureGroup(null, options);
      this._photolayers = {};
      // option to filter zoom per photo layter
      this.zoomFilter = options?.zoomFilter || 'globalonly';

      this.photolayers = [];
    },

    add(photos) {
      if (this.zoomFilter === 'globalonly') {
        this._photos.addLayers(photos);
        this.addLayer(this._photos);
      } else {
        const photosGroupedByZoom = photos.reduce((acc, photo) => {
          const minzoom = photo.minzoom || this.defaultMinZoom;
          acc[minzoom] = acc[minzoom] || [];
          acc[minzoom].push(photo);
          return acc;
        }, {});
        Object.keys(photosGroupedByZoom).forEach((zoom) => {
          if (!this._photolayers[zoom]) {
            this._photolayers[zoom] = this.options.featureGroup(null, this.options);
          }
          this._photolayers[zoom] = this._photolayers[zoom].addLayers(photosGroupedByZoom[zoom]);
        });
      }
      return this;
    },

    addToMap(map) {
      this.map = map;
      this.map.on('zoomend', () => this.onMapZoom());
      this.onMapZoom();
      return this;
    },
    showNeededLayers(currentZoom) {
      Object.keys(this._photolayers).forEach((zoom) => {
        if (!this._photolayers[zoom]) {
          return;
        }
        if (currentZoom >= zoom) {
          this.addLayer(this._photolayers[zoom]);
        } else {
          this.removeLayer(this._photolayers[zoom]);
        }
      });
    },

    onMapZoom() {
      const currentZoom = this.map.getZoom();
      // remove Markers from map if zoom level is below globalMinZoom option
      if (currentZoom < this.globalMinZoom) {
        if (this.map.hasLayer(this)) {
          this.removeFrom(this.map);
        }
      } else if (!this.map.hasLayer(this)) {
        this.addTo(this.map);
      }
      // per zoom layer  change visibility
      if (this.zoomFilter !== 'globalonly') {
        this.showNeededLayers(currentZoom);
      }
    },

    clear() {
      this._photos.clearLayers();
      this.clearLayers();
    },
  });

  L.photo.cluster = (options) => {
    return new L.Photo.Cluster(options);
  };
}
