<template>
  <div class="welcome-dialog">
    <el-dialog v-model="showWelcomeDialog" :show-close="false" :close-on-click-modal="false">
      <img src="/assets/logo2.png" alt="logo" class="logo" />
      <h3>
        Интерактивная карта<br />
        Центрально-Азиатской<br />
        экспедиции
      </h3>
      <p align="center" style="text-wrap: balance">
        Рекомендуем использовать браузеры:<br />Google&nbsp;Chrome, Opera, Safari,
        Yandex&nbsp;Browser, Microsoft&nbsp;Edge; Разрешение экрана: 740x360 и выше
      </p>
      <template #footer>
        <div class="dialog-footer">
          <p>Выберите язык:</p>
          <el-button type="primary" @click="chooseLang('ru')">Русский</el-button>
          <el-button type="primary" @click="chooseLang('en')" disabled>English</el-button>
          <!-- <el-button type="primary" @click="startTour()">Begin Tour</el-button> -->
        </div>
      </template>
    </el-dialog>
  </div>
  <!-- <div class="welcome-tour">
    <el-tour v-model="showTour" @change="tourStepChange">
      <el-tour-step
        target="#leaflet-tooltip-3338"
        title="Click to marker"
        description="Click to to marker to see more information in the sidebar"
      />
      <el-tour-step
        target=".leaflet-control-layers"
        title="Map Layer"
        description="Click to to change map layer"
      />
      <el-tour-step
        target=".leaflet-control-zoom-fullscreen"
        title="Full screen"
        description="Click to see full screen"
      />
    </el-tour>
  </div> -->
  <div v-loading="loading">
    <LeafLetPolyLine
      :placeMarker="placeMarker"
      :initMapZoom="initMapZoom"
      :polyline="polyline"
      :mapMarkersList="mapMarkersList"
      :enableSidebar="enableSidebar"
    >
    </LeafLetPolyLine>
  </div>
</template>

<script>
import LeafLetPolyLine from '@/components/LeafLetPolyLine.vue';
import '@/services/leaflet-hash';
import { mapGetters } from 'vuex';
import myhelper from '@/plugins/myhelper';

export default {
  name: 'MapView',
  components: {
    LeafLetPolyLine,
  },
  props: {
    // enableSidebar
    enableSidebar: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tracksList: {},
      // tracksList: { Шибочен: { visible: false, name: 'Шибочен', url: 'Shibochen-Ansi.kml' } },
      defaultColDef: { resizable: true },
      placeMarker: [31.41322, 88.219482],
      initMapZoom: 5,
      loading: true,
      showWelcomeDialog: true,
      showTour: false,
    };
  },
  created() {
    // ise http://localhost:8085/?showWelcome=true to force showWelcome dialog
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('showWelcome')) {
      return;
    }
    // check showWelcomeDialog in localStorage
    const showWelcomeDialog = localStorage.getItem('showWelcomeDialog');
    if (showWelcomeDialog === 'false') {
      this.showWelcomeDialog = false;
    }
  },
  methods: {
    tourStepChange(step) {
      console.log('tourStepChange', step);
      // if (step === 3) {
      //   this.showTour = false;
      // }
    },
    chooseLang(lang) {
      this.showWelcomeDialog = false;
      localStorage.setItem('showWelcomeDialog', false);
      localStorage.setItem('lang', lang);
    },
    startTour() {
      this.showWelcomeDialog = false;
      // this.showTour = true;
    },
    mapInit() {
      this.loading = false;
      if (this.$caemap.loadingcached) {
        myhelper.ElWarning(
          `Внимание: произошла ошибка при загрузке данных сервера. Приложение использует данные. сохраненные в кэше браузера.`,
        );
      }
    },
    getPlaceMarkerFromUrl() {
      // eslint-disable-next-line no-undef
      const parsed = L.Hash.parseHash(this.$route.hash);
      if (parsed) {
        this.placeMarker = [parsed.center.lat, parsed.center.lng];
        this.initMapZoom = parsed.zoom;
        this.$caemap.position = { lat: this.placeMarker[0], lng: this.placeMarker[1] };
        this.$caemap.zoom = this.initMapZoom;
      } else if (this.$caemap.position) {
        this.placeMarker = [this.$caemap.position.lat, this.$caemap.position.lng];
        if (this.$caemap.zoom) {
          this.initMapZoom = this.$caemap.zoom;
        }
      }
    },
  },
  beforeMount() {
    this.getPlaceMarkerFromUrl();
  },
  computed: {
    ...mapGetters('places', ['mapPathRoute', 'mapMarkersList', 'isInitialized']),
    polyline() {
      return {
        // latlngs: [[47.334852, -1.509485], [47.342596, -1.328731],
        // [47.241487, -1.190568], [47.234787, -1.358337]],
        latlngs: this.mapPathRoute,
        color: '#f00',
        linecap: 'square',
        dashArray: '3, 10',
        weight: 3.5,
        opacity: 0.99,
        smoothFactor: 1,
      };
    },
  },
  mounted() {
    if (this.isInitialized) {
      this.mapInit();
    } else {
      const unwatch = this.$watch('isInitialized', () => {
        unwatch();
        this.mapInit();
      });
    }
  },
};
</script>

<style>
.welcome-dialog .logo {
  height: 278px;
  margin-top: 13px;
  margin-right: 10px;
  position: absolute;
  top: 2px;
  left: 8px;
}
.welcome-dialog .dialog-footer {
  text-align: center;
}
.welcome-dialog p,
.welcome-dialog ul {
  font-family: 'Noto Sans';
  font-size: 16px;
}
.welcome-dialog h3 {
  text-align: center;
  font-weight: bold;
  margin: 0 1em 0.8em 1em;
  font-size: 36px;
  color: var(--app-menu-bg-color);
  letter-spacing: 1.2px;
}
.welcome-dialog .el-dialog__footer {
  padding-top: 3px;
}
.welcome-dialog .el-dialog {
  width: 800px;
}
.welcome-dialog .el-dialog > div {
  padding-left: 225px;
}
@media (max-width: 800px) {
  .welcome-dialog .el-dialog {
    width: 500px;
  }
  .welcome-dialog p,
  .welcome-dialog ul {
    font-size: 14px;
  }

  .welcome-dialog .el-dialog > div {
    padding-left: 0px;
  }
  .welcome-dialog .logo {
    height: 127px;
  }
  .welcome-dialog h3 {
    font-size: 24px;
    padding-left: 110px;
  }
}

@media (max-width: 500px) {
  .welcome-dialog .el-dialog {
    width: 95%;
  }
  .welcome-dialog h3 {
    font-size: 17px;
  }
  .welcome-dialog h3 {
    padding-left: 70px;
    margin-top: 9px;
  }

  .welcome-dialog .logo {
    height: 90px;
  }
  .welcome-dialog p,
  .welcome-dialog ul {
    font-family: 'Noto Sans';
    font-size: 12px;
  }
  .welcome-dialog .el-dialog__header {
    display: none;
  }
}
</style>
