<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div>
    <l-control position="topleft">
      <div class="leaflet-control-export">
        <el-button
          plain
          @click="dialogVisible = true"
          class="export-button"
          style="width: 30px; height: 30px; padding: 0; margin: 0; border: none"
          ><el-icon :size="20"><Download /></el-icon
        ></el-button>
      </div>
    </l-control>
    <el-dialog v-model="dialogVisible" title="Экспорт маршрута" class="mapexport-dialog">
      <el-row>
        <el-col :span="24">
          <el-form label-width="80px">
            <el-form-item label="From">
              <el-select-v2
                v-model="fromPlaceId"
                filterable
                :options="getPlacesOptions2()"
                placeholder="Please select"
                style="width: 100%"
              />
            </el-form-item>
            <el-form-item label="To">
              <el-select-v2
                v-model="toPlaceId"
                filterable
                :options="getPlacesOptions2()"
                placeholder="Please select"
                style="width: 100%"
              />
            </el-form-item>
            <el-form-item label="Zoom filter">
              <el-input-number v-model="maxzoom" :step="1" :min="4" :max="20" />
            </el-form-item>
            <el-form-item label="Format:">
              <el-radio-group v-model="exportFormat">
                <el-radio-button label="KML" value="kml" />
                <el-radio-button label="GeoJson" value="geojson" />
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="text-align: center">
            <el-button type="primary" @click="exportRoute" :disabled="isExportDisabled">
              Export
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { LControl } from '@vue-leaflet/vue-leaflet';
import { mapGetters } from 'vuex';
import mapexport from '@/plugins/mapexport';
import myhelper from '@/plugins/myhelper';

export default {
  components: {
    LControl,
  },
  data() {
    return {
      dialogVisible: false,
      fromPlaceId: 5,
      toPlaceId: 151,
      maxzoom: 20,
      exportFormat: 'kml',
    };
  },
  methods: {
    ...mapGetters('places', ['placeByID']),

    exportRoute() {
      const fromPlace = this.placeByID()(this.fromPlaceId);
      const toPlace = this.placeByID()(this.toPlaceId);
      const placesList = this.getPlacesListFromRange(this.fromPlaceId, this.toPlaceId);
      const filteredPlaceList = placesList.filter(
        (place) => place.minzoom > 0 && place.minzoom <= this.maxzoom,
      );

      placesList.slice(-1)[0].routejson = '[]';
      const polypath = myhelper.getPolyPathFromPlaces(placesList);
      const fromPlaceTitle = myhelper.getPlaceTitle(fromPlace, false);
      const toPlaceTitle = myhelper.getPlaceTitle(toPlace, false);
      const filename = `cae-${fromPlaceTitle}-to-${toPlaceTitle}-z${this.maxzoom}`;

      mapexport.saveAs(filename, this.exportFormat, filteredPlaceList, polypath);
    },
  },
  computed: {
    ...mapGetters('places', [
      'placesList',
      'filledPlacesList',
      'getPlacesOptions2',
      'getPlacesListFromRange',
    ]),
    isExportDisabled() {
      return this.fromPlaceId === 0 || this.toPlaceId === 0;
    },
  },
};
</script>

<style scoped>
.el-menu-item:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}
.menu-title {
  font-size: 16px;
  font-weight: bold;
}
a.maplink {
  size: 13px;
  color: black;
}

.export-button {
  color: #000;
  width: 30px;
  height: 30px;
  border-radius: 0px;
  border: none;
}
.leaflet-control {
  border: 2px solid rgba(0, 0, 0, 0.2);
}
.leaflet-control-export {
  margin: 0;
}
@media (max-height: 500px) {
  .leaflet-control {
    border-left: none;
    border-right: none;
    border-top: 1px solid #a3a3a3;
    border-right: 1px solid #a3a3a3;
    border-bottom: 1px solid #a3a3a3;
  }
}
</style>
