<template>
  <div>
    <p></p>
    <el-row>
      <el-col :span="2"><p align="right" style="margin: 7px 4px 0 0">From:</p></el-col>
      <el-col :span="20">
        <el-form-item>
          <el-select-v2
            v-model="fromPlaceId"
            filterable
            :options="getPlacesOptions2"
            placeholder="Please select"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="22"></el-col>
      <el-col :span="1" style="padding-bottom: 10px">
        <el-button @click="[fromPlaceId, toPlaceId] = [toPlaceId, fromPlaceId]">
          <i class="fas fa-sync"></i>
        </el-button>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="2"><p align="right" style="margin: 7px 4px 0 0">To:</p></el-col>
      <el-col :span="20">
        <el-form-item>
          <el-select-v2
            v-model="toPlaceId"
            filterable
            :options="getPlacesOptions2"
            placeholder="Please select"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <p>Distance: {{ distanceResult }} km</p>
        <p>Places count: {{ filteredPlaces().length }}</p>
        <p v-if="dateRange">Dates: {{ dateRange }}</p>
        <p v-if="pathPointsLength">Path points len: {{ pathPointsLength }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import myhelper from '@/plugins/myhelper';
import { toRaw, ref } from 'vue';

export default {
  data() {
    return {
      imageId: 0,
      fromPlaceId: 0,
      toPlaceId: 0,
      distanceResult: 0,
      fromDate: '',
      toDate: '',
      dateRange: '',
      pathPointsLength: 0,
    };
  },
  inject: {
    getLeafletMap: { default: ref(false) },
  },

  methods: {
    ...mapGetters('places', ['placeByID']),
    filteredPlaces() {
      if (this.fromPlaceId === 0 || this.toPlaceId === 0 || this.fromPlaceId === this.toPlaceId) {
        this.distanceResult = 0;
        this.fromDate = '';
        this.toDate = '';
        this.pathPointsLength = 0;
        return [];
      }
      const fromPlace = this.placeByID()(this.fromPlaceId);
      const toPlace = this.placeByID()(this.toPlaceId);

      const filteredPlacesList = [
        ...this.filledPlacesList
          .filter((place) => place.seqid >= fromPlace.seqid && place.seqid <= toPlace.seqid)
          .map((place) => ({ ...toRaw(place) })),
      ];
      if (filteredPlacesList.length === 0) {
        this.distanceResult = 0;
        this.fromDate = '';
        this.toDate = '';
        this.pathPointsLength = 0;
        return [];
      }
      // Clear latest place routejson to avoid using it in math measurement
      filteredPlacesList.slice(-1)[0].routejson = '[]';
      if (fromPlace.date_format === 'YYYY-MM-DD' && toPlace.date_format === 'YYYY-MM-DD') {
        this.fromDate = fromPlace.enddate ? fromPlace.enddate : fromPlace.date;
        this.toDate = toPlace.date;
        this.dateRange = myhelper.humanizeDateRange(this.fromDate, this.toDate);
      } else {
        this.fromDate = '';
        this.toDate = '';
        this.dateRange = '';
      }

      const polypath = myhelper.getPolyPathFromPlaces(filteredPlacesList);
      this.pathPointsLength = polypath.length;
      this.distanceResult = (myhelper.getPathDistance(this.myMap, polypath) / 1000).toFixed(1);
      return filteredPlacesList;
    },
  },
  computed: {
    ...mapGetters('places', ['placesList', 'filledPlacesList']),
    myMap() {
      return this.getLeafletMap();
    },

    getPlacesOptions2() {
      const options = this.filledPlacesList.map((place) => {
        let placedate = '';
        if (place.date) {
          placedate = place.date;
          if (place.enddate && place.date !== place.enddate) {
            placedate = `${placedate} - ${place.enddate}`;
          }
        }
        return {
          label: `${place.place_id}: ${place.title_ru} (${placedate})`,
          value: place.place_id,
        };
      });
      return [{ label: '<Not Selected>', value: 0 }, ...options];
    },
  },
};
</script>

<style>
#polyline-measure-control {
  border-radius: 0px;
}
</style>
