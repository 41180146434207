<template>
  <LMapTrack v-for="track in getTracksList" :trackurl="track" :key="track"></LMapTrack>
  <LMapLocalTrack
    v-for="(track, index) in tracksList()"
    :track="track"
    :key="index"
  ></LMapLocalTrack>
</template>
<script>
import LMapTrack from '@/components/LMapTrack.vue';
import axios from '@/plugins/axios';
import { mapGetters } from 'vuex';
import LMapLocalTrack from '@/components/LMapLocalTrack.vue';

export default {
  name: 'LeafletTracks',
  components: {
    LMapTrack,
    LMapLocalTrack,
  },
  data() {
    return {
      kmlTracks: [],
      // kmlTracks: ['Maanshan-Subei.kml', 'Shibochen-Ansi.kml', 'Dunhuang-Maanshan.kml'],
    };
  },
  computed: {
    getTracksList() {
      return this.kmlTracks.map((trackUri) => `${process.env.VUE_APP_API_URL}/${trackUri}`);
    },
  },
  methods: {
    ...mapGetters('tracks', ['tracksList']),
  },
  async created() {
    // const trackslistAPI = `${process.env.VUE_APP_API_URL}/tracks`;
    // const tracksList = await fetch(trackslistAPI);
    // this.kmlTracks = await tracksList.json();
    // console.log(this.kmlTracks);
    axios
      .get('/tracks/', { timeout: 5000 })
      .then((response) => {
        // console.log(response.data);
        this.kmlTracks = response.data;
        // console.log(this.getTracksList);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // mounted() {
  // console.log(this.tracksList());
  // },
};
</script>
