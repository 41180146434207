<template>
  <div v-loading="!isInitialized" class="mainmap-div">
    <!-- <el-button type="primary" @click="onSubmit">Update</el-button> -->

    <l-map
      ref="myMap"
      v-model="mapZoom"
      v-model:zoom="mapZoom"
      :center="placeMarker"
      :useGlobalLeaflet="true"
      :options="lmapOptions"
      :zoomAnimation="true"
      :markerZoomAnimation="true"
      :fadeAnimation="true"
      :maxZoom="22"
      :inertia="true"
      :inertiaDeceleration="2000"
      :inertiaMaxSpeed="Infinity"
      :easeLinearity="0.2"
      @click="onMapClick"
      @ready="onMapReady"
      @moveend="mapMove"
      @update:zoom="zoomUpdated"
      v-if="isInitialized"
    >
      <LBaseTiles :leafletRef="leafletRef" />
      <LeafLetHelpers :leafletRef="leafletRef" />

      <div v-if="isInitialized">
        <LMapSidebar
          v-if="enableSidebar"
          :leafletRef="leafletRef"
          :selectedPlace="selectedPlace"
          @prevPlace="onClickPrev"
          @nextPlace="onClickNext"
          @closingSidebar="selectedPlace = 0"
          @ready="onSideBarReady"
        />
        <div v-if="mapOptions.showHeatmap">
          <LHeatMap @markerClick="onMarkerClick2" />
        </div>
        <div v-if="mapOptions.showExp.precae">
          <div v-for="(m, zoom) in mapMarkersList2()('precae')" :key="zoom">
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 1"
              :maxZoom="Number(zoom) - 1"
              markerinfo="popup"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              v-if="zoom > 0"
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom)"
              markerinfo="tooltip"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 2"
              :maxZoom="Number(zoom) - 2"
              markerinfo="popup"
              markerType="circle"
              :circleRadius="2"
              :circleWeight="7"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
          </div>
        </div>
        <div v-if="mapOptions.showExp.indiancae">
          <div v-for="(m, zoom) in mapMarkersList2()('indiancae')" :key="zoom">
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 1"
              :maxZoom="Number(zoom) - 1"
              markerinfo="popup"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              v-if="zoom > 0"
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom)"
              markerinfo="tooltip"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 2"
              :maxZoom="Number(zoom) - 2"
              markerinfo="popup"
              markerType="circle"
              :circleRadius="2"
              :circleWeight="7"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
          </div>
        </div>
        <div v-if="mapOptions.showExp.cae">
          <div v-for="(m, zoom) in mapMarkersList2()('cae')" :key="zoom">
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 1"
              :maxZoom="Number(zoom) - 1"
              markerinfo="popup"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              v-if="zoom > 0"
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom)"
              markerinfo="tooltip"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 2"
              :maxZoom="Number(zoom) - 2"
              markerinfo="popup"
              markerType="circle"
              :circleRadius="2"
              :circleWeight="7"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
          </div>
        </div>
        <div>
          <div v-for="(m, zoom) in mapMarkersList2()('other')" :key="zoom">
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 1"
              :maxZoom="Number(zoom) - 1"
              markerinfo="popup"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              v-if="zoom > 0"
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom)"
              markerinfo="tooltip"
              markerType="marker"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
            <LMapMarkers
              :leafletRef="leafletRef"
              :markersList="m"
              :minZoom="Number(zoom) - 2"
              :maxZoom="Number(zoom) - 2"
              markerinfo="popup"
              markerType="circle"
              :circleRadius="2"
              :circleWeight="7"
              @markerClick="onMarkerClick2"
              :selectedPlaceId="selectedPlaceIdForMarkers"
            />
          </div>
        </div>
      </div>
      <div v-if="isInitialized && mapControlsInitialized">
        <LMapSearch
          :leafletRef="leafletRef"
          @placeselect="onPlaceSelect"
          @ready="searchControlMounted = true"
        />
        <LControlSettings v-if="searchControlMounted" />
        <LControlExport v-if="isResearcher" />
      </div>

      <!-- <LMapLegend :leafletRef="leafletRef" :groupBy="mapOptions.pathGroupBy" /> -->
      <GeoPhotos
        v-if="isInitialized"
        :placeImages="mapImages"
        :minZoom="1"
        :showLayer="mapOptions.showGeoPhotos"
        zomonFilter="perphoto"
      />
      <!-- <l-circle
        :lat-lng="placeMarker"
        :radius="10000"
        color="green"
        :fill="true"
        :fillOpacity="0.2"
        fillColor="#41b782"
      />
 -->
      <!-- <l-feature-group ref="largePoints">
        <l-marker
          @click="onMarkerClick(mapMarker.place_id)"
          :ref="setItemRef"
          v-for="(mapMarker, i) in mapMarkersList.large"
          :key="i"
          :lat-lng="[mapMarker.latitude, mapMarker.longitude]"
          color="#41b782"
        >
          <l-icon class-name="fa1testclass" :size="[20, 20]" :icon-anchor="[13, 36]">
            <font-awesome-icon
              icon="fa-solid fa-circle-info"
              transform="shrink-9 up-2"
              mask="fa-solid fa-map-marker"
              :style="{ color: '#d33d2b' }"
              size="3x"
            />
          </l-icon>

          <l-tooltip :options="ttOptions.large" v-if="showToolTip.large">
            {{ mapMarker.title_ru }} - {{ mapMarker.zoom }}
          </l-tooltip>
        </l-marker>
      </l-feature-group> -->

      <!-- <l-feature-group ref="mediumPoints" v-if="showPoints.medium">
        <l-marker
          @click="onMarkerClick(mapMarker.place_id)"
          v-for="(mapMarker, i) in mapMarkersList.medium"
          :key="i"
          :lat-lng="[mapMarker.latitude, mapMarker.longitude]"
        >
          <l-tooltip :options="ttOptions.medium" v-if="showToolTip.medium">
            {{ mapMarker.title_ru }} - {{ mapMarker.zoom }}
          </l-tooltip>
        </l-marker>
      </l-feature-group> -->

      <!-- <l-feature-group ref="smallPoints" v-if="showPoints.small">
        <l-marker
          @click="onMarkerClick(mapMarker.place_id)"
          v-for="(mapMarker, i) in mapMarkersList.small"
          :key="i"
          :lat-lng="[mapMarker.latitude, mapMarker.longitude]"
        >
          <l-tooltip :options="ttOptions.small" v-if="showToolTip.small">
            {{ mapMarker.title_ru }} - {{ mapMarker.zoom }}
          </l-tooltip>
        </l-marker>
      </l-feature-group>

      <l-feature-group ref="tinyPoints" v-if="showPoints.tiny">
        <l-marker
          @click="onMarkerClick(mapMarker.place_id)"
          v-for="(mapMarker, i) in mapMarkersList.tiny"
          :key="i"
          :lat-lng="[mapMarker.latitude, mapMarker.longitude]"
        >
          <l-tooltip :options="ttOptions.tiny">
            {{ mapMarker.title_ru }}
          </l-tooltip>
        </l-marker>
      </l-feature-group> -->

      <!-- <l-marker :lat-lng="placeMarker" draggable @moveend="markerMove">
        <l-tooltip>
          lol
        </l-tooltip>
      </l-marker> -->

      <LMapPolyline
        :leafletRef="leafletRef"
        :show="mapOptions.showPath && mapOptions.showExp.indiancae"
        :groupBy="mapOptions.pathGroupBy"
        visitedOn="indiancae"
      />

      <LMapPolyline
        :leafletRef="leafletRef"
        :show="mapOptions.showPath && mapOptions.showExp.precae"
        :groupBy="mapOptions.pathGroupBy"
        visitedOn="precae"
      />

      <LMapPolyline
        :leafletRef="leafletRef"
        :show="mapOptions.showPath && mapOptions.showExp.cae"
        :groupBy="mapOptions.pathGroupBy"
        visitedOn="cae"
      />

      <div v-if="isInitialized && mapOptions.motionStart">
        <LMapMotion :leafletRef="leafletRef" />
      </div>

      <div v-if="mapOptions.showHeartOfAsia">
        <div v-if="false">
          <l-marker
            :lat-lng="heartOfAsia.points[0]"
            draggable
            @moveend="changeHeartOfAsiaPoint($event, 0)"
          />
          <l-marker
            :lat-lng="heartOfAsia.points[1]"
            draggable
            @moveend="changeHeartOfAsiaPoint($event, 1)"
          />
          <l-marker
            :lat-lng="heartOfAsia.points[2]"
            draggable
            @moveend="changeHeartOfAsiaPoint($event, 2)"
          />
          <l-marker
            :lat-lng="heartOfAsia.points[3]"
            draggable
            @moveend="changeHeartOfAsiaPoint($event, 3)"
          />
        </div>

        <l-polyline
          ref="heartofasia"
          :lat-lngs="[...heartOfAsia.points, heartOfAsia.points[0]]"
          :color="heartOfAsia.color"
          :lineCap="heartOfAsia.linecap"
          :dashArray="heartOfAsia.dashArray"
          :weight="heartOfAsia.weight"
          :opacity="heartOfAsia.opacity"
        >
        </l-polyline>
      </div>
    </l-map>
  </div>
</template>
<script>
import {
  LMap,
  // LIcon,
  LTileLayer,
  // LFeatureGroup,
  LMarker,
  // LControlLayers,
  // LTooltip,
  // LPopup,
  // LCircle,
  LPolyline,
  // LPolygon,
  // LRectangle,
} from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';
import { reactive, toRaw } from 'vue';
import { mapGetters } from 'vuex';
import L from 'leaflet';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import 'leaflet.polylinemeasure';
// import { latLng } from "leaflet/dist/leaflet-src.esm";
// import '@/services/leaflet-hash';
import hashState from '@/services/hashState';
import 'leaflet.gridlayer.googlemutant';

import VueLeafletGoogleMutant from '@/components/VueLeafletGoogleMutant.vue';
import LBaseTiles from '@/components/LBaseTiles.vue';
import LMapMarkers from '@/components/LMapMarkers.vue';
import LMapSearch from '@/components/LMapSearch.vue';
import LMapLegend from '@/components/LMapLegend.vue';
import LMapSidebar from '@/components/LMapSidebar.vue';
import LMapPolyline from '@/components/LMapPolyline.vue';
import LMapMotion from '@/components/LMapMotion.vue';
import LHeatMap from '@/components/LHeatMap.vue';

import LeafLetHelpers from '@/components/LeafLetHelpers.vue';
import LControlSettings from '@/components/LControlSettings.vue';
import LControlExport from '@/components/LControlExport.vue';
import GeoPhotos from '@/components/GeoPhotos.vue';
import myhelper from '@/plugins/myhelper';
import 'leaflet.locatecontrol'; // Import plugin
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'; // Import styles
import hotkeys from 'hotkeys-js';

// import L from 'leaflet';

export default {
  name: 'LeafLetPolyLine',
  props: {
    placeMarker: { type: Array, required: true },
    initMapZoom: { type: Number, required: true },
    polyline: { type: Object, required: true },
    mapMarkersList: { type: Object, required: true },
    enableSidebar: { type: Boolean, default: true },
  },
  provide() {
    return {
      sidebarcontrol: this.sidebarcontrol,
      getLeafletMap: () => this.leafletRef.leafletObject,
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'v-tilelayer-googlemutant': VueLeafletGoogleMutant,
    // L,
    LMap,
    // LIcon,
    // eslint-disable-next-line vue/no-unused-components
    LTileLayer,
    // LFeatureGroup,
    LMarker,
    // LControlLayers,
    // LTooltip,
    // LPopup,
    // LCircle,
    LPolyline,
    // LPolygon,
    // LRectangle,
    LBaseTiles,
    LMapMarkers,
    LMapSearch,
    // eslint-disable-next-line vue/no-unused-components
    LMapLegend,
    LMapSidebar,
    // eslint-disable-next-line vue/no-unused-components
    LMapPolyline,
    LMapMotion,
    LHeatMap,
    LeafLetHelpers,
    // eslint-disable-next-line vue/no-unused-components
    LControlExport,
    LControlSettings,
    GeoPhotos,
  },
  data() {
    return {
      searchControlMounted: false,
      lmapOptions: {
        // zoomSnap: 0.25,
        // zoomDelta: 0.5,
        // wheelPxPerZoomLevel: 120,
        gestureHandling: false,
        boxZoom: true,
        preferCanvas: true,
        wheelPxPerZoomLevel: 40,
        attributionControl: true,
        zoomControl: false,
      },
      heartOfAsia: {
        points: [
          [62.062733258846514, 81.47460937500001],
          [43.96119063892027, 125.24414062500001],
          [-14.26438308756265, 81.03515625000001],
          [16.13026201203477, 37.26562500000001],
        ],
        color: 'red',
        linecap: 'round',
        dashArray: '10, 0',
        weight: 3,
        opacity: 0.9,
      },
      mapControls: {},
      mapControlsInitialized: false,
      isMobile: myhelper.isMobile(),
      leafletMap: undefined,
      showGeoPhotos: true,
      gmoptions: { type: 'roadmap' },
      mapZoom: 2,
      sidebarcontrol: {},
      hashInited: false,
      iconWidth: 25,
      iconHeight: 40,
      selectedPlace: 0,
      defaultsSelectedPlace: 5,
      ttOptions: reactive({
        tiny: reactive({ permanent: true, interactive: false, direction: 'left' }),
        small: reactive({
          permanent: true,
          interactive: false,
          direction: 'left',
          className: 'tt-smallzoom',
        }),
        medium: reactive({
          permanent: true,
          interactive: false,
          direction: 'left',
          className: 'tt-mediumzoom',
        }),
        large: {
          permanent: true,
          interactive: false,
          direction: 'left',
          className: 'tt-largezoom',
        },
      }),
      showPointsThreshold: {
        tiny: 9,
        small: 7,
        medium: 6,
        large: 4,
      },

      showPoints: {
        tiny: false,
        small: false,
        medium: false,
        large: true,
      },
      showToolTip: {
        tiny: false,
        small: false,
        medium: false,
        large: true,
      },
      itemRefs: [],
    };
  },
  inject: {
    mapOptions: { default: {} },
  },

  created() {
    this.mapZoom = this.initMapZoom;
  },
  mounted() {
    this.assignHotKeys();

    window.addEventListener('resize', this.changeMapHeight);
    this.changeMapHeight();

    const { addEventListener } = hashState();
    hashState().initialize();
    addEventListener('m', this.processMapHash);
    addEventListener('p', (value) => {
      this.processSidebarHash(value);
    });
    // map coordinates will be inited from MapView component to avoid flickering
  },
  beforeUnmount() {
    hashState().cleanup();
    if (window.destroyLightGallery) {
      window.destroyLightGallery();
    }
    hotkeys.unbind();
    window.removeEventListener('resize', this.changeMapHeight);

    this.myMap.removeControl(this.mapControls.measure);
    this.myMap.removeControl(this.mapControls.locate);
  },

  computed: {
    isAdmin: myhelper.isAdmin,
    isResearcher: myhelper.isResearcher,
    ...mapGetters('places', ['isInitialized', 'mapPathRoute']),
    ...mapGetters('images', ['imagesList']),

    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
    leafletRef() {
      return this.$refs.myMap;
    },
    mapImages() {
      const mI = this.imagesList.filter(
        (image) => image.latitude && image.longitude && image.image_enabled,
      );
      return mI;
    },
    myMap() {
      return this.$refs.myMap.leafletObject;
    },
    selectedPlaceIdForMarkers() {
      return this.mapOptions.editorHighlightSelectedMarker ? this.selectedPlace : 0;
    },
  },
  methods: {
    ...mapGetters('images', ['getImageByPlaceId']),
    isTextFieldActive: myhelper.isTextFieldActive,
    changeHeartOfAsiaPoint(event, index) {
      const marker = event.target;
      const position = marker.getLatLng();
      this.heartOfAsia.points[index] = [position.lat, position.lng];
      this.$forceUpdate();
      console.log(JSON.stringify(toRaw(this.heartOfAsia.points)));
    },
    processMapHash(value) {
      // console.log('processMapHash', value);
      const [zoomValue, lat, lng] = value.split('/').map(Number);
      this.zoom = zoomValue;
      this.center = [lat, lng];
      try {
        this.myMap.setView(this.center, this.zoom);
      } catch (error) {
        console.log(this.center, this.zoom);
        console.error(error);
      }
    },
    processSidebarHash(value) {
      if (!value) {
        return;
      }
      const [placeId, status] = value.split('/');
      if (status === 'o') {
        this.openSideBar();
      } else {
        this.sidebarcontrol.control.close();
      }
      if (placeId) {
        this.selectedPlace = Number(placeId);
      }
      // console.log('processSidebarHash', value);
    },
    waitForElm: myhelper.waitForElm,
    ...mapGetters('places', [
      'placeByID',
      'getFilledPrevPlace',
      'getFilledNextPlace',
      'mapMarkersList2',
    ]),

    onSubmit() {
      // console.log(L);

      [].forEach.call(this.itemRefs, (ref) => {
        /* eslint-disable no-underscore-dangle */
        // console.log(ref.leafletObject._tooltip);

        ref.leafletObject.unbindTooltip();
        ref.leafletObject.bindTooltip('Title', {
          permanent: true,
          interactive: false,
          direction: 'left',
        });
        /* eslint-disable no-param-reassign */
        ref.leafletObject.options.permanent = false;
      });
    },
    onMarkerClick(placeid) {
      // console.log(event);
      // if (e.shiftKey) {
      this.$router.push({ path: `/place/edit/${placeid}` });
      // }
      // this.selectedPlace = placeid;
    },
    onMarkerClick2(event, placeid) {
      if (!this.enableSidebar) {
        return;
      }
      if (event.originalEvent.shiftKey && this.isAdmin) {
        this.$router.push({ path: `/place/edit/${placeid}` });
        return;
      }
      this.showSelectedPlace(placeid);
    },
    showSelectedPlace(placeId) {
      this.setSelectedPlace(placeId, 'o');
      this.openSideBar();
    },

    openSideBar() {
      try {
        this.sidebarcontrol.open();
      } catch (error) {
        console.warn(error);
        console.warn('Cannot open sidebar');
      }
    },

    setItemRef(el) {
      if (el) {
        // console.log(el);
        this.itemRefs.push(el);
      }
    },
    onMapClick(e) {
      if (typeof e.latlng !== 'undefined') {
        // console.log('Click location:', [e.latlng.lat, e.latlng.lng]);
        // if (e.originalEvent.shiftKey) {
        //   const myMap = toRaw(this.$refs.myMap.leafletObject);
        //   const popLocation = e.latlng;
        //   // myMap.openPopup("test popup", popLocation);
        //   // eslint-disable-next-line new-cap
        //   new L.popup()
        //     .setLatLng(popLocation)
        //     .setContent(geolinks.getExernalLinks(popLocation, myMap.getZoom()))
        //     .openOn(myMap);
        // }
      }
    },
    onMapReady() {
      const myMap = toRaw(this.$refs.myMap.leafletObject);
      if (!myhelper.isTouchDevice()) {
        myMap.addControl(
          L.control.zoom({
            position: 'bottomright',
            zoomInTitle: 'Увеличить',
            zoomOutTitle: 'Уменьшить',
          }),
        );
      }
      // eslint-disable-next-line no-new
      // this.mapControls.hash = new L.Hash(myMap);
      const options = {
        position: 'topleft',
        unit: 'metres',
        clearMeasurementsOnStop: true,
        measureControlLabel: '<i class="fa fa-ruler"></i>', // Label of the Measure Control (Unicode symbols are possible)
        measureControlTitleOn: 'Измерить расстояние на карте',
        measureControlTitleOff: 'Отключить измерение расстояния',
      };
      this.mapControls.measure = L.control.polylineMeasure(options);
      this.mapControls.locate = L.control.locate({
        locateOptions: {
          enableHighAccuracy: true,
        },
        strings: {
          title: 'Ваше местоположение',
        },
      });
      this.mapControls.measure.addTo(myMap);
      this.mapControls.locate.addTo(myMap);
      this.mapControlsInitialized = true;
      this.initLogRequests(myMap);

      this.updateMapHashState();
    },
    initLogRequests(myMap) {
      myMap.on('locateactivate', () => {
        console.log(`locateactivate`);
        myhelper.remoteLog('locateactivate', 'locateactivate');
      });
      myMap.on('locatedeactivate', () => {
        console.log(`locatedeactivate`);
        myhelper.remoteLog('locatedeactivate', 'locatedeactivate');
      });
      myMap.on('locationfound', (e) => {
        // console.log(`locationfound`, e);
        console.log(
          `locationfound`,
          JSON.stringify(e, ['accuracy', 'latitude', 'longitude', 'timestamp']),
        );
        myhelper.remoteLog(
          'locationfound',
          JSON.stringify(e, ['accuracy', 'latitude', 'longitude', 'timestamp']),
        );
      });
      myMap.on('locationerror', (e) => {
        console.log(`locationerror`, e.message);
        myhelper.remoteLog('locationerror', e.message);
      });
    },
    zoomUpdated(zoom) {
      // console.log(`Zoom: ${zoom}`);
      const pThrshld = this.showPointsThreshold;

      Object.keys(pThrshld).forEach((property) => {
        this.showPoints[property] = zoom >= pThrshld[property];
        this.showToolTip[property] = zoom >= pThrshld[property] + 1;
        // this.ttOptions[property].permanent = (zoom >= (pThrshld[property] + 2));
        // const newOption = {};
        // newOption[property] = { permanent: (zoom >= (pThrshld[property] + 1)) };
        // console.log(pThrshld);
        // console.table(this.showToolTip);

        // Object.assign(this.ttOptions, { tiny: { direction: 'right1' } });
        // Object.assign(this.ttOptions, newOption);
        // this.$set(this.ttOptions[property], 'permanent', (zoom >= (pThrshld[property] + 2)));
        // const boxes = document.getElementsByClassName(`leaflet-tooltip tt-${property}zoom`);
        // /* eslint no-param-reassign: "error" */
        // const dispprop = (zoom >= (pThrshld[property] + 1)) ? 'block' : 'none';

        // console.log(`Setting ${property} to ${dispprop}`);
        // [].forEach.call(boxes, (box) => {
        //   box.style.display = (zoom >= (pThrshld[property] + 1)) ? 'block' : 'none';
        // });
      });

      // const property = 'large';
      // console.log(this.ttOptions);
      // console.log(this.showPoints);

      // const smallMapZoom = 7;
      // const map = this.$refs.myMap.leafletObject;
      // const zoom = map.getZoom();
      // return true;
      // console.log(zoom >= smallMapZoom);
      // this.showPoints.small = (zoom >= smallMapZoom);

      // const largeMapZoom = 4;
      // const mediumMapZoom = 6;
      // const smallMapZoom = 7;
      // // const tinyMapZoom = 9;

      // // const  latlng  = event.latlng;
      // const map = this.$refs.myMap.leafletObject;
      // console.log(`Zoom: ${map.getZoom()}`);
      // const mediumPoints = this.$refs.mediumPoints.leafletObject;
      // const smallPoints = this.$refs.smallPoints.leafletObject;

      // if (map.getZoom() < mediumMapZoom) {
      //   map.removeLayer(mediumPoints);
      // } else { map.addLayer(mediumPoints); }

      // if (map.getZoom() < smallMapZoom) {
      //   map.removeLayer(smallPoints);
      // } else { map.addLayer(smallPoints); }
      // if (map.getZoom() < tinyMapZoom)
      // { map.removeLayer(tinyPoints); } else { map.addLayer(tinyPoints); }

      // console.log(map);
      // console.log(largePoints);
    },
    log(a) {
      console.log(a);
    },
    markerMove(event) {
      const marker = event.target;
      // console.log(marker);
      const position = marker.getLatLng();
      // console.log(position);
      this.$emit('markerMove', position);
    },
    updateMapHashState() {
      const mapCenter = this.myMap.getCenter();
      const mapZoom = this.myMap.getZoom();
      this.$caemap.position = mapCenter;
      this.$caemap.zoom = mapZoom;

      const precision = Math.max(0, Math.ceil(Math.log(mapZoom) / Math.LN2));
      hashState().updateState(
        'm',
        `${[mapZoom, mapCenter.lat.toFixed(precision), mapCenter.lng.toFixed(precision)].join('/')}`,
      );
    },
    onSideBarReady() {
      if (hashState().state.parameters.p) {
        // this.setSelectedPlace(Number(hashState().state.parameters.p));
        this.processSidebarHash(hashState().state.parameters.p);
      }
      if (!this.selectedPlace) {
        this.selectedPlace = this.defaultsSelectedPlace;
      }
      this.sidebarcontrol.control.on('opening', () => {
        hashState().updateState('p', [this.selectedPlace, 'o'].join('/'));
      });
      this.sidebarcontrol.control.on('closing', () => {
        hashState().updateState('p', [this.selectedPlace, 'c'].join('/'));
      });
    },
    mapMove() {
      this.updateMapHashState();
    },

    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
    onClickPrevWithHisPhotos() {
      if (this.selectedPlace === 0) {
        return;
      }
      const { seqid: seqId } = this.placeByID()(this.selectedPlace);
      let prevPlace = this.getFilledPrevPlace()(seqId);
      let imagescnt = this.getImageByPlaceId()(prevPlace.place_id).filter(
        (image) => image.image_type === 'histexpphoto',
      ).length;
      if (process.env.NODE_ENV === 'development') {
        console.log(prevPlace.place_id, imagescnt);
      }
      let i = 0;
      while (prevPlace && imagescnt === 0 && i < 100) {
        prevPlace = this.getFilledPrevPlace()(prevPlace.seqid);
        imagescnt = this.getImageByPlaceId()(prevPlace.place_id).filter(
          (image) => image.image_type === 'histexpphoto',
        ).length;
        i += 1;
        if (process.env.NODE_ENV === 'development') {
          console.log(prevPlace.place_id, imagescnt);
        }
      }
      if (prevPlace) {
        this.setSelectedPlace(prevPlace.place_id, 'o');
        this.myMap.setView([prevPlace.latitude, prevPlace.longitude]);
      }
    },

    // next place that contains histexpphoto
    onClickNextWithHisPhotos() {
      if (this.selectedPlace === 0) {
        return;
      }
      const { seqid: seqId } = this.placeByID()(this.selectedPlace);
      let nextPlace = this.getFilledNextPlace()(seqId);
      let imagescnt = this.getImageByPlaceId()(nextPlace.place_id).filter(
        (image) => image.image_type === 'histexpphoto',
      ).length;
      if (process.env.NODE_ENV === 'development') {
        console.log(nextPlace.place_id, imagescnt);
      }
      let i = 0;
      while (nextPlace && imagescnt === 0 && i < 100) {
        nextPlace = this.getFilledNextPlace()(nextPlace.seqid);
        imagescnt = this.getImageByPlaceId()(nextPlace.place_id).filter(
          (image) => image.image_type === 'histexpphoto',
        ).length;
        i += 1;
        if (process.env.NODE_ENV === 'development') {
          console.log(nextPlace.place_id, imagescnt);
        }
      }
      if (nextPlace) {
        this.setSelectedPlace(nextPlace.place_id, 'o');
        this.myMap.setView([nextPlace.latitude, nextPlace.longitude]);
      }
    },
    onClickPrev() {
      if (this.selectedPlace === 0) {
        return;
      }
      const { seqid: seqId } = this.placeByID()(this.selectedPlace);
      let prevPlace = this.getFilledPrevPlace()(seqId);
      if (prevPlace.minzoom < 0) prevPlace = this.getFilledPrevPlace()(prevPlace.seqid);
      if (prevPlace.minzoom < 0) prevPlace = this.getFilledPrevPlace()(prevPlace.seqid);
      if (prevPlace) {
        this.setSelectedPlace(prevPlace.place_id, 'o');
        this.myMap.setView([prevPlace.latitude, prevPlace.longitude]);
      }
    },
    onClickNext() {
      if (this.selectedPlace === 0) {
        return;
      }
      const { seqid: seqId } = this.placeByID()(this.selectedPlace);
      let nextPlace = this.getFilledNextPlace()(seqId);
      if (nextPlace.minzoom < 0) nextPlace = this.getFilledNextPlace()(nextPlace.seqid);
      if (nextPlace.minzoom < 0) nextPlace = this.getFilledNextPlace()(nextPlace.seqid);
      if (nextPlace) {
        this.setSelectedPlace(nextPlace.place_id, 'o');
        this.myMap.setView([nextPlace.latitude, nextPlace.longitude]);
      }
    },
    setSelectedPlace(placeId, status) {
      this.selectedPlace = placeId;
      hashState().updateState('p', [this.selectedPlace, status].join('/'));
    },
    assignHotKeys() {
      hotkeys(',', this.onClickPrev);
      hotkeys('.', this.onClickNext);
      hotkeys('m', () => {
        this.mapOptions.showMarkers = !this.mapOptions.showMarkers;
      });
      // mapOptions.editorPlaceQuickEdit
      hotkeys('d', () => {
        this.mapOptions.showDebugInfo = !this.mapOptions.showDebugInfo;
      });
      if (this.isAdmin) {
        hotkeys('q', () => {
          this.mapOptions.editorPlaceQuickEdit = !this.mapOptions.editorPlaceQuickEdit;
        });
        hotkeys('shift+.', this.onClickNextWithHisPhotos);
        hotkeys('shift+,', this.onClickPrevWithHisPhotos);
      }
      hotkeys('p', () => {
        if (
          this.mapOptions.showExp.precae ||
          this.mapOptions.showExp.indiancae ||
          this.mapOptions.showExp.cae
        ) {
          this.mapOptions.showExp.precae = false;
          this.mapOptions.showExp.cae = false;
          this.mapOptions.showExp.indiancae = false;
        } else {
          this.mapOptions.showExp.precae = true;
          this.mapOptions.showExp.cae = true;
          this.mapOptions.showExp.indiancae = true;
        }
        // this.mapOptions.showPath = !this.mapOptions.showPath;
      });
      hotkeys('l', () => {
        if (this.isTextFieldActive()) return;
        this.goToCoordFromUserUrl();
      });

      hotkeys('g', () => {
        const availVal = ['transport', 'pathaccuracy'];
        // toggle transport or pathaccuracy
        this.mapOptions.pathGroupBy =
          availVal[(availVal.indexOf(this.mapOptions.pathGroupBy) + 1) % 2];
      });
      hotkeys('i', () => {
        this.mapOptions.editorHighlightSelectedMarker =
          !this.mapOptions.editorHighlightSelectedMarker;
      });
      // hide precae polyline
      hotkeys('1', () => {
        this.mapOptions.showExp.precae = !this.mapOptions.showExp.precae;
      });
      // hide cae polyline
      hotkeys('2', () => {
        this.mapOptions.showExp.indiancae = !this.mapOptions.showExp.indiancae;
      });
      // hide cae polyline
      hotkeys('3', () => {
        this.mapOptions.showExp.cae = !this.mapOptions.showExp.cae;
      });
      hotkeys('4', () => {
        this.mapOptions.showExp.manchuriaexp = !this.mapOptions.showExp.manchuriaexp;
      });
      hotkeys('5', () => {
        this.mapOptions.showHeartOfAsia = !this.mapOptions.showHeartOfAsia;
      });
      hotkeys('7', () => {
        this.mapOptions.showHeatmap = !this.mapOptions.showHeatmap;
      });
      hotkeys('y', () => {
        this.mapOptions.showGeoPhotos = !this.mapOptions.showGeoPhotos;
      });
    },

    changeMapHeight() {
      const navHeight = document.querySelectorAll('nav')[0].offsetHeight || 0;

      this.waitForElm('.btn-container_foreground').then(() => {
        const bottomNavbar =
          document.querySelectorAll('.btn-container_foreground')[0].offsetHeight || 0;

        document.getElementsByClassName('mainmap-div')[0].style.height =
          `${window.innerHeight - navHeight - bottomNavbar - 2}px`;
      });
    },
    onPlaceSelect(place) {
      this.myMap.setView(new L.LatLng(place.latitude, place.longitude), 10);
      this.showSelectedPlace(place.place_id);
    },
    goToCoordFromUserUrl() {
      // eslint-disable-next-line no-alert
      const url = prompt('Введите координаты или URL:');
      const clipboardCood = myhelper.getCoordFromText(url);
      if (clipboardCood) {
        // center map to the coordinates
        this.myMap.setView(clipboardCood, 10);
      }
    },
  },
};
</script>

<style>
:root {
  --el-tooltip-border-color: #0700ff;
  /* --el-tooltip-border-color: #fff; */
}
.leaflet-left {
  left: 9px;
}
.leaflet-top {
  top: 26px;
}
@media (max-width: 756px), (max-height: 500px) {
  .leaflet-left {
    left: 0px;
  }
  .leaflet-top {
    top: 0px;
  }
}

.leaflet-tooltip {
  top: 20px;
  left: -20px;
  /* position: absolute; */
  /* padding: 6px; */
  background-color: #fff;
  border: 2px solid var(--el-tooltip-border-color);
  /* font-weight: bold; */
  /* border-radius: 3px; */
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip-left:before {
  border-left-color: var(--el-tooltip-border-color);
}
.leaflet-tooltip-right:before {
  border-right-color: var(--el-tooltip-border-color);
}
.leaflet-tooltip-bottom:before {
  border-bottom-color: var(--el-tooltip-border-color);
}
.leaflet-tooltip-top:before {
  border-top-color: var(--el-tooltip-border-color);
}

/* to show polyline below the circle markers */
.leaflet-map-pane canvas {
  z-index: 400;
}

.mainmap-div {
  height: 90vh;
  width: 100%;
}
#polyline-measure-control {
  font-size: 16px;
}
</style>
