export default {
  // Places
  PLACES: 'PLACES',
  UPDATE_PLACE: 'UPDATE_PLACE',
  DELETE_PLACE: 'DELETE_PLACE',
  ADD_PLACE: 'ADD_PLACE',
  // Images
  IMAGES: 'IMAGES',
  UPDATE_IMAGE: 'UPDATE_IMAGE',
  DELETE_IMAGE: 'DELETE_IMAGE',
  ADD_IMAGE: 'ADD_IMAGE',
  // Books
  SET_BOOKS: 'SET_BOOKS',
  SET_CHAPTERS: 'SET_CHAPTERS',
  SET_TEXTS: 'SET_TEXTS',
  SET_TEXT_IDS: 'SET_TEXT_IDS',
  SET_ALL_TEXT_IDS: 'SET_ALL_TEXT_IDS',
  SET_ALL_CHAPTERS: 'SET_ALL_CHAPTERS',
};
