<template>
  <!-- eslint-disable max-len -->
  <el-dialog
    v-model="dialogSearchVisible"
    title="Search dialog"
    @open-auto-focus="this.dialogContentFocused = true"
    @close-auto-focus="elDialogCloseFocus"
    class="search-dialog"
  >
    <template #header>
      <div class="my-header"></div>
    </template>

    <PlacesAutocomplete @onPlaceSelect="onPlaceSelect" :isVisible="dialogContentFocused" />
  </el-dialog>
</template>
<script>
import { ref } from 'vue';
import L from 'leaflet';
import '@/services/leaflet-searchbox';
import PlacesAutocomplete from '@/components/PlacesAutocomplete.vue';

import hotkeys from 'hotkeys-js';

export default {
  name: 'LMapSearch',
  props: {
    leafletRef: { type: Object, required: true },
  },
  components: {
    PlacesAutocomplete,
  },
  data() {
    return {
      controlDiv: '',
      dialogSearchVisible: ref(false),
      dialogContentFocused: ref(false),
      searchbox: '',
    };
  },
  inject: {
    getLeafletMap: { default: ref(false) },
  },

  methods: {
    elDialogCloseFocus() {
      this.dialogContentFocused = false;
      setTimeout(() => {
        this.searchbox.onButton('click', this.onClickSearchBttn);
      }, 100);
    },
    onPlaceSelect(place) {
      this.dialogSearchVisible = false;
      // Without this code the search dialog appears again due to getting focus on search button.
      const unwatch = this.$watch('dialogContentFocused', () => {
        console.log('dialogContentFocused');
        console.log(this.dialogContentFocused);

        this.$nextTick(() => {
          this.$emit('placeselect', place);
        });

        unwatch();
      });
    },
    onClickSearchBttn() {
      this.dialogSearchVisible = true;
      this.searchbox.offButton('click', this.onClickSearchBttn);
    },
    addSearchButton() {
      this.searchbox = L.control
        .searchbox({
          position: 'topleft',
          expand: 'left',
        })
        .addTo(this.myMap);
      this.searchbox.onButton('click', this.onClickSearchBttn);
    },
  },
  computed: {
    myMap() {
      return this.getLeafletMap();
      // return this.leafletRef.leafletObject;
    },
  },
  mounted() {
    // console.log(`${this.$options.name} mounted`);
    // FIX ME - with ctrl-c
    // hotkeys('cmd+k, ctrl-k', this.onClickSearchBttn);
    hotkeys('cmd+k', this.onClickSearchBttn);
    hotkeys('ctrl+k', this.onClickSearchBttn);
    this.addSearchButton();
    this.$emit('ready');
  },
  beforeUnmount() {
    hotkeys.unbind();
    this.myMap.removeControl(this.searchbox);
  },
};
</script>

<style>
.searchModalHeaderIcon {
  width: 18px;
  height: 18px;
  color: #8899a8;
}

.searchResultActionText {
  color: #0049ff;
  font-family: 'Inter', sans-serif;
  /* font-weight: 500; */
}

.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.my-autocomplete li .name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.my-autocomplete li .addr {
  font-size: 12px;
  color: #b4b4b4;
}
.my-autocomplete li .highlighted .addr {
  color: #ddd;
}
.my-autocomplete li .link {
  font-family: monospace;
}

.inline-input {
  width: 80%;
}

.searchResultActionIcon_dkZSk {
  width: 16px;
  color: #0049ff;
}

.el-overlay {
  background-color: #000000c2 !important;
}
@media (max-width: 992px) {
  .search-dialog {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .search-dialog {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .search-dialog {
    width: 98%;
    padding: 5px;
  }
}

.leaflet-searchbox-wrapper {
  display: flex;
  justify-content: right;
  height: 30px;
  /* border-top-left-radius: 20px 50%;
  border-bottom-left-radius: 20px 50%;
  border-top-right-radius: 20px 50%;
  border-bottom-right-radius: 20px 50%; */
}

.leaflet-searchbox-wrapper .leaflet-searchbox,
.leaflet-searchbox-wrapper .leaflet-searchbox-button,
.leaflet-searchbox-container .leaflet-searchbox-autocomplete,
.leaflet-searchbox-autocomplete .leaflet-searchbox-autocomplete-item {
  background-color: white;
  /* border: 1px rgb(0, 0, 0, 0.3) solid !important; */
  border: none;
  outline: none;
  transition-duration: 0.2s;
}

.leaflet-searchbox-wrapper .leaflet-searchbox {
  width: 300px;
  font-size: 17px;
  transition-delay: 0.1s;
  z-index: 702;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-left {
  /* border-top-left-radius: 20px 50%; */
  /* border-bottom-left-radius: 20px 50%; */
  border-right: none;
  padding: 0px 0px 0px 15px;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-right {
  /* border-top-right-radius: 20px 50%;
  border-bottom-right-radius: 20px 50%; */
  border-left: none;
  padding: 0px 15px 0px 0px;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-button {
  width: 30px;
  transition-delay: 0s;
  z-index: 702;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-button:hover {
  cursor: pointer;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-button-right {
  /* border-top-right-radius: 20px 50%; */
  /* border-bottom-right-radius: 20px 50%; */
  border-left: none;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-button-left {
  /* border-top-left-radius: 20px 50%; */
  /* border-bottom-left-radius: 20px 50%; */
  border-right: none;
}

.leaflet-searchbox-wrapper .leaflet-searchbox-button i {
  font-size: 130%;
}

.leaflet-searchbox-container .leaflet-searchbox-icon {
  width: 69%;
  height: auto;
}

.leaflet-searchbox-container .leaflet-searchbox-autocomplete {
  position: absolute;
  top: 20px;
  width: 100%;
  list-style-type: none;
  margin: 0px;
  padding: 20px 0px 0px 0px;
  background-color: white;
  border-top: none;
  z-index: 701;
}

.leaflet-searchbox-container .leaflet-searchbox-autocomplete:empty {
  display: none;
}

.leaflet-searchbox-autocomplete .leaflet-searchbox-autocomplete-item {
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  z-index: 701;
  font-size: 15px;
  padding: 3px 10px;
  cursor: pointer;
}

.leaflet-searchbox-autocomplete .leaflet-searchbox-autocomplete-item:hover {
  background-color: #f3f3f3;
}

.collapsed .leaflet-searchbox {
  width: 0px !important;
  padding: 0px;
  transition-delay: 0s;
}

.collapsed .leaflet-searchbox {
  border-left: none;
  border-right: none;
}

.collapsed .leaflet-searchbox-button {
  /* border-radius: 50%; */
  transition-delay: 0.2s;
  /* border: 1px rgb(0, 0, 0, 0.3) solid; */
  border: none;
  padding: 3px 2px 2px 2px;
}

.collapsed .leaflet-searchbox-autocomplete {
  display: none;
}

.leaflet-searchbox-wrapper.open .leaflet-searchbox,
.leaflet-searchbox-wrapper.open .leaflet-searchbox-button {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
</style>
