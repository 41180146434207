<template>
  <div></div>
</template>
<script>
// import { ref, toRaw, nextTick } from 'vue';
// import L from 'leaflet';
import { mapGetters } from 'vuex';
// import 'leaflet-legend';
import '@/services/leaflet-legend';

export default {
  name: 'LMapLegend',
  props: {
    leafletRef: { type: Object, required: true },
    groupBy: {
      type: String,
      required: true,
      // default: 'transport',
      validator(value) {
        return ['transport', 'pathaccuracy'].includes(value);
      },
    },
  },
  watch: {
    groupBy() {
      this.myMap.removeControl(this.legendLayer);
      this.addLegendOnMap();
    },
  },

  data() {
    return {
      legendLayer: null,
      legendsByType: {
        transport: [
          {
            label: 'By railroad and streamboat',
            type: 'polyline',
            color: '#b4f',
            fillColor: '#0000FF',
            dashArray: [5, 10],
            weight: 4,
          },
          {
            label: 'By Caravan',
            type: 'polyline',
            color: '#a00',
            fillColor: '#0000FF',
            dashArray: [5, 10],
            weight: 4,
          },
        ],
        pathaccuracy: [
          {
            label: 'Маршрут',
            type: 'polyline',
            color: '#b4f',
            fillColor: '#0000FF',
            dashArray: [1],
            weight: 4,
          },
          {
            label: 'Требуется уточнение',
            type: 'polyline',
            color: '#f00',
            fillColor: '#0000FF',
            dashArray: [5, 10],
            weight: 4,
          },
        ],
      },
    };
  },
  methods: {
    addLegendOnMap() {
      this.legendLayer = new window.L.Control.Legend({
        position: 'bottomleft',
        collapsed: false,
        symbolWidth: 48,
        opacity: 1,
        column: 1,
        legends: this.legendsByType[this.groupBy],
      });
      this.myMap.addControl(this.legendLayer);
    },
  },
  computed: {
    ...mapGetters('places', ['mapMarkersList', 'isInitialized']),
    myMap() {
      return this.leafletRef.leafletObject;
    },
  },
  mounted() {
    if (this.isInitialized) {
      this.addLegendOnMap();
    } else {
      const unwatch = this.$watch('isInitialized', () => {
        this.addLegendOnMap();
        unwatch();
      });
    }
  },
  beforeUnmount() {
    if (this.legendLayer) {
      this.myMap.removeControl(this.legendLayer);
    }
  },
};
</script>

<style>
.leaflet-legend {
  background-color: white;
}

@media (max-width: 480px), (max-height: 480px) {
  .leaflet-legend {
    display: none;
    visibility: hidden;
  }
}

.leaflet-legend-title {
  margin: 3px;
  padding-bottom: 5px;
}

.leaflet-legend-column {
  float: left;
  margin-left: 10px;
}

.leaflet-legend-item {
  display: table;
  margin: 2px 0;
}

.leaflet-legend-item span {
  vertical-align: middle;
  display: table-cell;
  word-break: keep-all;
  white-space: nowrap;
  background-color: transparent;
  text-align: left;
}

.leaflet-legend-item-clickable {
  cursor: pointer;
}

.leaflet-legend-item-inactive span {
  color: #cccccc;
}

.leaflet-legend-item-inactive i img,
.leaflet-legend-item-inactive i canvas {
  opacity: 0.3;
  /*
  color: #000000;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  */
}

.leaflet-legend-item i {
  display: inline-block;
  padding: 0px 3px 0px 4px;
  position: relative;
  vertical-align: middle;
}

.leaflet-legend-toggle {
  /* eslint-disable-next-line max-len */
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTk5MDE0Mjk2NTEwIiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjE3Nzk4IiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48ZGVmcz48c3R5bGUgdHlwZT0idGV4dC9jc3MiPjwvc3R5bGU+PC9kZWZzPjxwYXRoIGQ9Ik05MzQuNCA0NzguNzJINzM3LjI4Yy0xNS44NzItMTEwLjA4LTExMS4xMDQtMTk0LjU2LTIyNS4yOC0xOTQuNTZTMzAyLjU5MiAzNjguNjQgMjg2LjcyIDQ3OC43Mkg4OS42djY2LjU2SDI4Ni43MmMxNS44NzIgMTEwLjA4IDExMS4xMDQgMTk0LjU2IDIyNS4yOCAxOTQuNTZzMjA5LjQwOC04NC40OCAyMjUuMjgtMTk0LjU2aDE5Ny4xMnYtNjYuNTZ6IiBmaWxsPSIjNzA3MDcwIiBwLWlkPSIxNzc5OSI+PC9wYXRoPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: none;
  border-radius: 4px;
}

.leaflet-legend-contents {
  display: none;
}

.leaflet-legend-expanded .leaflet-legend-contents {
  display: block;
  padding: 6px 15px 6px 6px;
}

.leaflet-legend-contents img {
  position: absolute;
}

.leaflet-legend-contents:after {
  content: '';
  display: block;
  clear: both;
}
</style>
