import Vuex from 'vuex';
import places from './modules/places';
import images from './modules/images';
import books from './modules/books';
import tracks from './modules/tracks';

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    places,
    images,
    books,
    tracks,
  },
});

store.dispatch('initPlaceStore');
store.dispatch('initImagesStore');
store.dispatch('initBooksStore');
store.dispatch('initTracksStore');

export default store;
