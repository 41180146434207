import { reactive } from 'vue';

// Singleton instance
let sharedInstance = null;

export default function hashState() {
  if (sharedInstance) {
    return sharedInstance;
  }

  // Reactive state to store parameters
  const state = reactive({
    parameters: {}, // Dynamic storage for all parameters (e.g., `m`, `l`, `p`)
  });

  // Event listeners for parameters
  const eventListeners = {};

  // Count of how many components are using hashchange
  let hashChangeListenerCount = 0;

  // Utility to trigger event listeners
  const triggerListeners = (key, value) => {
    if (eventListeners[key]) {
      eventListeners[key].forEach((callback) => callback(value));
    }
  };

  // Parse the hash URL
  const parseHashUrl = () => {
    const hash = window.location.hash.slice(1);
    const params = new URLSearchParams(hash.replace(/&/g, '&'));

    // Use .forEach instead of for...of to iterate over parameters
    params.forEach((value, key) => {
      if (state.parameters[key] !== value) {
        state.parameters[key] = value; // Update reactive state
        triggerListeners(key, value); // Trigger listeners
      }
    });
  };

  // Update the hash URL
  const updateHashUrl = () => {
    const sortKeysList = ['m', 'l', 'p'];
    const hashParts = Object.entries(state.parameters)
      // eslint-disable-next-line no-unused-vars
      .filter(([key, value]) => value !== null && value !== undefined)
      // sort by order keys 1st l, then m, then p
      .sort(([key1], [key2]) => {
        const index1 = sortKeysList.indexOf(key1);
        const index2 = sortKeysList.indexOf(key2);
        return index1 - index2;
      })
      .map(([key, value]) => `${key}=${value}`);
    window.location.hash = hashParts.join('&');
  };

  // Generic state updater
  const updateState = (key, value) => {
    state.parameters[key] = value;
    updateHashUrl();
    // console.log('updateState', key, value);
    // triggerListeners(key, value);
  };

  // Add an event listener for a specific parameter
  const addEventListener = (key, callback) => {
    if (!eventListeners[key]) {
      eventListeners[key] = [];
    }
    eventListeners[key].push(callback);
  };

  // Remove an event listener for a specific parameter
  const removeEventListener = (key, callback) => {
    if (eventListeners[key]) {
      eventListeners[key] = eventListeners[key].filter((cb) => cb !== callback);
    }
  };

  // Start listening to hashchange
  const initialize = () => {
    parseHashUrl();
    if (hashChangeListenerCount === 0) {
      window.addEventListener('hashchange', parseHashUrl);
    }
    hashChangeListenerCount += 1;
  };

  // Stop listening to hashchange
  const cleanup = () => {
    hashChangeListenerCount -= 1;
    if (hashChangeListenerCount === 0) {
      window.removeEventListener('hashchange', parseHashUrl);
    }
  };

  // Create the shared instance
  sharedInstance = {
    state,
    updateState,
    addEventListener,
    removeEventListener,
    initialize,
    cleanup,
  };

  return sharedInstance;
}
