<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <l-control>
    <div class="leaflet-bar leaflet-control leaflet-control-date-picker">
      <el-button plain @click="buttonClick" class="datepicker-button"
        ><el-icon><Calendar /></el-icon
      ></el-button>
      <el-dialog
        v-model="dialogTableVisible"
        title="Shipping address"
        @open-auto-focus="ElDialogOpened"
      >
        <template #header>
          <div class="my-header"></div>
        </template>
        <div class="block">
          <p>Component value：{{ value2 }}</p>
          <el-button plain @click="buttonClick2">B2 </el-button>
          <el-date-picker
            ref="picker"
            v-model="value2"
            type="daterange"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :shortcuts="shortcuts"
            :default-value="[new Date(2010, 9, 1), new Date(2010, 10, 1)]"
            size="large"
          />
        </div>
      </el-dialog>
      <!-- <a
        class="leaflet-control-zoom-fullscreen fullscreen-icon"
        href="#"
        title="Full Screen"
        role="button"
        aria-label="Full Screen"
      ></a> -->
    </div>
  </l-control>

  <div></div>
</template>

<script>
import { LControl } from '@vue-leaflet/vue-leaflet';
import { ref, toRaw } from 'vue';

export default {
  components: {
    LControl,
  },

  data() {
    return {
      dialogVisible: false,
      selectedDate: null,
      dialogTableVisible: false,
      value2: ref(''),
    };
  },
  computed: {
    shortcuts() {
      return [
        {
          text: 'Last week',
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: 'Last month',
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: 'Last 3 months',
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ];
    },
  },
  methods: {
    buttonClick2() {
      console.log('Button clicked 2');
      console.log(toRaw(this.$refs.picker.$el));
      // get 1st input elemet using this.$refs.picker.$el
      // const input = this.$refs.picker.$el.querySelector('input');
      // console.log(input);
      // how to find el-range-input input element in whole document DOM and set focus
      // const input = document.querySelector('.el-range-input input');
      // console.log(document.querySelector('input.el-range-input'));
      // document.querySelector('input.el-range-input').click();

      // this.$refs.picker.handleOpen();
    },
    ElDialogOpened() {
      console.log('ElDialogOpened');
      this.$nextTick(() => {
        console.log('ElDialogOpened nextTick');
        document.querySelector('input.el-range-input').click();
        // this.$refs.picker.handleOpen();
        // this.$refs.picker.showPicker();
      });
      // this.$refs.picker.showPicker();
    },
    buttonClick() {
      console.log('Button clicked');
      this.dialogTableVisible = true;
      console.log(this.$refs.picker);
      // document.querySelector('input.el-range-input').click();
    },
    showDatePicker() {
      this.dialogVisible = true;
    },
    saveDate() {
      // Handle saving the selected date
      console.log('Selected Date:', this.selectedDate);
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.datepicker-button {
  color: #000;
  width: 44px;
  height: 44px;
}
.leaflet-control-date-picker {
  right: -9px;
}
</style>
