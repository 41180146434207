<template>
  <div class="track-manage">
    <el-row>
      <el-col :span="24">
        <el-upload
          name="image"
          class="track-upload"
          accept=".kml"
          drag
          action=""
          :auto-upload="false"
          :multiple="false"
          :before-upload="beforeUpload"
          :limit="1"
          :on-change="handleChange"
          :file-list="fileList"
          :show-file-list="false"
        >
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
        </el-upload>
      </el-col>
    </el-row>
    <div v-if="fileContent">
      <h3>File Content:</h3>
      <pre>{{ fileContent }}</pre>
    </div>
    <div class="tracks-list">
      <div v-for="(track, index) in tracksList()" :key="index">
        <el-row>
          <el-col :span="1">
            <el-checkbox v-model="track.showTrack" size="default" @click="onClickCheckbox" />
          </el-col>
          <el-col :span="19" class="track-name">{{ track.name }}</el-col>
          <el-col :span="2">
            <el-button type="" @click="onClickName(track.points)" circle>
              <el-icon><Location /></el-icon>
            </el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="danger" @click="onClickDelete(index)" circle>
              <el-icon> <Delete /> </el-icon>
            </el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import myhelper from '@/plugins/myhelper';
import toGeoJSON from '@mapbox/togeojson';
import L from 'leaflet';
import { ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      fileList: [],
      fileContent: '',
      localtracks: [],
    };
  },
  inject: {
    getLeafletMap: { default: ref(false) },
  },
  // watch showTrack and save changes in storage using vuex
  watch: {
    tracksList: {
      handler() {
        this.tracksList();
      },
      deep: true,
    },
  },

  computed: {
    myMap() {
      return this.getLeafletMap();
    },
  },
  methods: {
    ...mapGetters('tracks', ['tracksList']),
    ...mapActions('tracks', ['addTrack', 'removeTrack', 'saveTracks']),
    beforeUpload(file) {
      const isTextFile = file.type === 'text/plain' || file.type === 'text/markdown';
      if (!isTextFile) {
        this.$message.error('You can only upload text files!');
      }
      return isTextFile; // Prevent upload if it's not a text file
    },
    handleChange(file, fileList) {
      this.fileList = fileList; // Update the file list
      myhelper.readFile(file).then((fileDataArray) => {
        const kmlXml = new DOMParser().parseFromString(fileDataArray.data, 'text/xml');
        const geojson = toGeoJSON.kml(kmlXml);
        // const trackName = geojson.features[0].properties.description;
        const trackName = fileDataArray.filename.replace(/\.[^/.]+$/, '');
        const trackPoints = geojson.features[0].geometry.coordinates.map((coord) => [
          coord[1],
          coord[0],
        ]);
        const tolerance = 0.0002;
        const simplePoints = myhelper.simplifyLatlngs(L, trackPoints, tolerance);
        console.log(
          `compression: from ${trackPoints.length} to ${simplePoints.length}: ${Math.floor(trackPoints.length / simplePoints.length)}x `,
        );
        this.addTrack({
          name: trackName,
          showTrack: true,
          points: simplePoints,
        });
        // console.log(this.tracksList());
        // const geodataArray = [];
        // for (const fileData of fileDataArray) {
        //   geodataArray.push(...parseGeoFile(fileData.filename, fileData.data));
        // }
        // this.readingFiles(this.readingFiles() - 1);
        // this.addTracksFromGeodataArray(geodataArray);
      });
    },
    onClickDelete(index) {
      this.removeTrack(index);
    },
    onClickName(points) {
      this.myMap.fitBounds(points);
    },
    onClickCheckbox() {
      // need this delay to make sure the checkbox variable is changed
      setTimeout(() => {
        this.saveTracks();
      }, 100);
    },
  },
};
</script>

<style scoped>
.upload-demo {
  margin-bottom: 20px;
}
.track-manage {
  /* padding: 20px; */
  /* display: flex; */
  /* justify-content: center; */
  margin-top: 20px;
  max-width: 800px;
  margin: 0 auto;
}
.track-manage .tracks-list {
  margin-top: 20px;
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 5px; */
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: space-between; */
}
.track-manage .tracks-list > div {
  border: 1px solid #3d1c9e;
  padding: 10px;
}
.track-upload {
  text-align: center;
  margin-top: 20px;
}
.tracks-list .track-name {
  padding-left: 10px;
  line-height: 34px;
  font-family: sans-serif;
  font-size: 14px;
}
</style>
