// trackStore.js
import { toRaw } from 'vue';

const STORAGE_KEY = 'maptracks';

export default {
  namespaced: true,
  state: {
    tracks: [], // Array to store track objects
  },
  getters: {
    tracksList: ({ tracks }) => tracks,
  },
  mutations: {
    SET_TRACKS(state, tracks) {
      state.tracks = tracks; // Set the tracks in the state
    },
    ADD_TRACK(state, track) {
      state.tracks.push(track); // Add a new track
    },
    REMOVE_TRACK(state, index) {
      state.tracks.splice(index, 1); // Remove a track by index
    },
    UPDATE_TRACK(state, { index, track }) {
      state.tracks[index] = track; // Update a track at the given index
    },
  },
  actions: {
    initTracksStore: {
      handler({ dispatch }) {
        dispatch('loadTracks');
        // console.log('fetchPlaces');
      },
      root: true,
    },

    loadTracks({ commit }) {
      const tracks = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
      // iterate through tracks and make sure there is showTrack show showXX property using merge arrays
      const updTracks = tracks.map((track) => {
        const defaultOptions = {
          name: 'unknown track',
          showTrack: true,
          points: [],
        };
        return { ...defaultOptions, ...track };
      });
      commit('SET_TRACKS', updTracks); // Load tracks from localStorage
    },
    saveTracks({ state }) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(toRaw(state.tracks))); // Save tracks to localStorage
    },
    addTrack({ commit, dispatch }, track) {
      commit('ADD_TRACK', track);
      dispatch('saveTracks'); // Save to localStorage after adding
    },
    removeTrack({ commit, dispatch }, index) {
      commit('REMOVE_TRACK', index);
      dispatch('saveTracks'); // Save to localStorage after removal
    },
    updateTrack({ commit, dispatch }, { index, track }) {
      commit('UPDATE_TRACK', { index, track });
      dispatch('saveTracks'); // Save to localStorage after updating
    },
  },
};
