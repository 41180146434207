/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable camelcase */
/* eslint-disable no-tabs */
/* eslint-disable no-unused-expressions */
// eslint-disable-next-line no-mixed-spaces-and-tabs
// eslint-disable no-param-reassingn

import L from 'leaflet';

(function (window) {
  const HAS_HASHCHANGE = (function () {
    const doc_mode = window.documentMode;
    return 'onhashchange' in window && (doc_mode === undefined || doc_mode > 7);
  })();

  L.Hash = function (map) {
    this.onHashChange = L.Util.bind(this.onHashChange, this);

    if (map) {
      this.init(map);
    }
  };

  L.Hash.parseHash = function (hash) {
    const allparams = L.Hash.parseHashParams(hash);
    const args = allparams.m;
    if (!args) return false;
    if (args.length === 3) {
      const zoom = parseInt(args[0], 10);
      const lat = parseFloat(args[1]);
      const lon = parseFloat(args[2]);
      if (Number.isNaN(zoom) || Number.isNaN(lat) || Number.isNaN(lon)) {
        return false;
      }
      return {
        center: new L.LatLng(lat, lon),
        zoom,
      };
    }
    return false;

    // if (hash.indexOf('#') === 0) {
    //   hash = hash.substr(1);
    // }
    // const args = hash.split('/');
    // if (args.length === 3) {
    //   const zoom = parseInt(args[0], 10);
    //   const lat = parseFloat(args[1]);
    //   const lon = parseFloat(args[2]);
    //   if (Number.isNaN(zoom) || Number.isNaN(lat) || Number.isNaN(lon)) {
    //     return false;
    //   }
    //   return {
    //     center: new L.LatLng(lat, lon),
    //     zoom,
    //   };
    // }
    // return false;
  };

  L.Hash.parseHashParams = function (s) {
    const args = {};
    const i = s.indexOf('#');
    if (i >= 0) {
      s = s.substr(i + 1).trim();
      let m;
      let key;
      let value;
      // eslint-disable-next-line no-restricted-syntax
      for (const pair of s.split('&')) {
        m = /^([^=]+?)(?:=(.*))?$/u.exec(pair);
        if (m) {
          [, key, value] = m;
          if (value) {
            value = value.split('/');
          } else {
            value = [];
          }
          args[key] = value;
        }
      }
    }
    return args;
  };

  L.Hash.formatHash = function (map) {
    const center = map.getCenter();
    const zoom = map.getZoom();
    const precision = Math.max(0, Math.ceil(Math.log(zoom) / Math.LN2));

    return `#m=${[zoom, center.lat.toFixed(precision), center.lng.toFixed(precision)].join('/')}`;
  };

  L.Hash.prototype = {
    map: null,
    lastHash: null,

    parseHash: L.Hash.parseHash,
    formatHash: L.Hash.formatHash,

    init(map) {
      this.map = map;

      // reset the hash
      this.lastHash = null;
      this.onHashChange();

      if (!this.isListening) {
        this.startListening();
      }
    },

    removeFrom() {
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout);
      }

      if (this.isListening) {
        this.stopListening();
      }

      this.map = null;
    },

    onMapMove() {
      // bail if we're moving the map (updating from a hash),
      // or if the map is not yet loaded

      if (this.movingMap || !this.map._loaded) {
        return false;
      }

      const hash = this.formatHash(this.map);
      if (this.lastHash != hash) {
        window.location.replace(hash);
        this.lastHash = hash;
      }
    },

    movingMap: false,
    update() {
      // return;
      const { hash } = location;
      if (hash === this.lastHash) {
        return;
      }
      const parsed = this.parseHash(hash);
      if (parsed) {
        this.movingMap = true;

        // Commented due to weird bugs in leaflet-vue when working together
        // this.map.setView(parsed.center, parsed.zoom);

        this.movingMap = false;
      } else {
        this.onMapMove(this.map);
      }
    },

    // defer hash change updates every 100ms
    changeDefer: 100,
    changeTimeout: null,
    onHashChange() {
      // throttle calls to update() so that they only happen every
      // `changeDefer` ms
      if (!this.changeTimeout) {
        const that = this;
        this.changeTimeout = setTimeout(() => {
          that.update();
          that.changeTimeout = null;
        }, this.changeDefer);
      }
    },

    isListening: false,
    hashChangeInterval: null,
    startListening() {
      this.map.on('moveend', this.onMapMove, this);

      if (HAS_HASHCHANGE) {
        L.DomEvent.addListener(window, 'hashchange', this.onHashChange);
      } else {
        clearInterval(this.hashChangeInterval);
        this.hashChangeInterval = setInterval(this.onHashChange, 5000);
      }
      this.isListening = true;
    },

    stopListening() {
      this.map.off('moveend', this.onMapMove, this);

      if (HAS_HASHCHANGE) {
        L.DomEvent.removeListener(window, 'hashchange', this.onHashChange);
      } else {
        clearInterval(this.hashChangeInterval);
      }
      this.isListening = false;
    },
  };
  L.hash = function (map) {
    return new L.Hash(map);
  };
  L.Map.prototype.addHash = function () {
    this._hash = L.hash(this);
  };
  L.Map.prototype.removeHash = function () {
    this._hash.removeFrom();
  };
})(window);
