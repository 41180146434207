<template>
  <div class="app-settings">
    <!-- Your component's HTML template goes here -->
    <el-row>
      <p class="settings-group-name">Общие настройки:</p>
    </el-row>
    <el-row>
      <!-- Show markers -->
      <el-checkbox v-model="mapOptions.showMarkers" label="Отображать маркеры" size="large" />
      <el-tooltip
        effect="dark"
        content="Показать/cкрыть маркеры на карте"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <!-- Show path -->
      <el-checkbox v-model="mapOptions.showPath" label="Отображать маршрут" size="large" />
      <el-tooltip
        effect="dark"
        content="Показать/cкрыть маршрут на карте"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <!-- Pin selected marker -->
      <el-checkbox
        v-model="mapOptions.editorHighlightSelectedMarker"
        label="Выделять выбранный маркер"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="<span>Выделить подсказку для выбранного маркера<br/>(информация по нему отображается в боковой панели)</span>"
        raw-content
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <!-- Show Modern Title -->
      <el-checkbox
        v-model="mapOptions.showModernTitle"
        label="Отображать совр. название"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Отображать современное название рядом с маркером на карте"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <el-checkbox
        v-model="mapOptions.showAllPhotos"
        label="Отображать все фотографии"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Отображать все/выбранные фотографии в боковой панели"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <el-checkbox
        v-model="mapOptions.showGeoPhotos"
        label="Отображать фотографии на карте"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Отображать на карте фотографии с геопозицией"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row v-if="$appmodifier === 'pre'">
      <el-checkbox
        v-model="mapOptions.showHeatmap"
        label="Отображать тепловую карту"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Тут будет подсказка"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>

    <el-row>
      <el-checkbox
        v-model="mapOptions.showAdditionalMaps"
        label="Отображать доп. карты"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Отображать карты для поездки по Китаю и OpenRailwayMap"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>

      <div
        v-if="showAdditionalMapsOrig !== mapOptions.showAdditionalMaps"
        class="text-boxreload-page"
      >
        <el-button type="primary" @click="pageReload()" style="margin-top: 10px">
          Перезагрузите страницу, чтобы применить изменения
        </el-button>
      </div>
    </el-row>

    <el-row>
      <el-col :span="23" class="showexp-checkboxes">
        <p class="settings-group-name">Отображение участков экспедиции:</p>
      </el-col>
    </el-row>
    <el-row>
      <el-checkbox v-model="mapOptions.showExp.precae" label="Прибытие в Индию " size="large" />
      <el-tooltip
        effect="dark"
        content="Париж – Дарджилинг, (1923 г.)"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <el-checkbox
        v-model="mapOptions.showExp.indiancae"
        label="Индийская часть маршрута"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Марсель – Дарджилинг, монастыри Сиккима (1924-1925 гг.)"
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row>
      <el-checkbox
        v-model="mapOptions.showExp.cae"
        label="Основной маршрут экспедиции"
        size="large"
      />
      <el-tooltip
        effect="dark"
        content="Замкнутый маршрут: 6 марта 1925 г. – конец мая 1928 г."
        placement="top"
        popper-class="settings-tooltip"
      >
        <span><i class="fas fa-info-circle"></i></span>
      </el-tooltip>
    </el-row>
    <el-row v-if="$appmodifier === 'pre'">
      <el-col :span="20">
        <el-checkbox
          v-model="mapOptions.showHeartOfAsia"
          label="Периметр границ экспедиций"
          size="large"
        />
        <el-tooltip effect="dark" content="" placement="top" popper-class="settings-tooltip">
          <span><i class="fas fa-info-circle"></i></span>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <p class="settings-group-name">Дополнительные настройки:</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="setting-maplang">
        <span class="el-checkbox__label" style="margin-top: 3px; font-size: 14px; margin-left: -8px"
          >Язык карты:&nbsp;</span
        >

        <el-radio-group v-model="mapOptions.mapLanguage" class="ml-4" size="small">
          <el-radio-button value="ru">РУС</el-radio-button>
          <el-radio-button value="en">ENG</el-radio-button>
        </el-radio-group>
        <el-tooltip
          effect="dark"
          content="Язык названий населенных пунктов на карте Гугл и Яндекс"
          placement="top"
          popper-class="settings-tooltip"
        >
          <span><i class="fas fa-info-circle"></i></span>
        </el-tooltip>
        <div v-if="mapLanguageOrig !== mapOptions.mapLanguage" class="text-boxreload-page">
          <el-button type="primary" @click="pageReload()" style="margin-top: 10px">
            Перезагрузите страницу, чтобы применить изменения
          </el-button>
        </div>
      </el-col>
      <el-col :span="24" v-if="isAdmin">
        <span class="el-checkbox__label" style="margin-top: 3px; font-size: 16px; margin-left: -8px"
          >Тип линии маршрута:&nbsp;</span
        >
      </el-col>
      <el-col :span="24" v-if="isAdmin">
        <el-select v-model="mapOptions.pathGroupBy" size="small" placeholder="Select" label="Test">
          <el-option value="transport" label="По транспорту">По транспорту</el-option>
          <el-option value="pathaccuracy" label="По точности">По точности</el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row v-if="isAdmin">
      <el-checkbox
        v-model="mapOptions.editorPlaceQuickEdit"
        label="Place quick editor"
        size="large"
      />
    </el-row>

    <el-row v-if="isAdmin">
      <el-col :span="8">
        <el-checkbox v-model="mapOptions.motionStart" label="Motion start" size="large" />
      </el-col>
      <el-col :span="8">
        <el-checkbox v-model="mapOptions.showDebugInfo" label="Show Debug Info" size="large" />
      </el-col>
      <el-col :span="8">
        <el-checkbox v-model="mapOptions.animation" label="Animation" size="large" />
      </el-col>
    </el-row>

    <!-- <el-row v-if="tracksList.length > 0">
      <el-col :span="24"><h2>Tracks</h2></el-col>

      <el-checkbox
        v-model="track.visible"
        :label="'Show ' + track.name"
        size="large"
        v-for="track in tracksList"
        :key="track.name"
      />
    </el-row> -->
  </div>
</template>

<script>
import myhelper from '@/plugins/myhelper';

export default {
  name: 'AppSettings',
  data() {
    return {
      showAdditionalMapsOrig: false,
      mapLanguageOrig: 'ru',
    };
  },
  mounted() {
    this.showAdditionalMapsOrig = this.mapOptions.showAdditionalMaps;
    this.mapLanguageOrig = this.mapOptions.mapLanguage;
  },
  watch: {
    'mapOptions.showHeatmap': {
      handler(newVal) {
        if (newVal) {
          this.mapOptions.showMarkers = false;
        }
      },
    },
    'mapOptions.showExp.indiancae': {
      handler(newVal) {
        console.log('showExp.indiancae', newVal);
        if (newVal) {
          this.mapOptions.showPath = true;
        }
      },
    },
    'mapOptions.showExp.cae': {
      handler(newVal) {
        if (newVal) {
          this.mapOptions.showPath = true;
        }
      },
    },
    'mapOptions.showExp.precae': {
      handler(newVal) {
        if (newVal) {
          this.mapOptions.showPath = true;
        }
      },
    },
  },
  methods: {
    pageReload() {
      window.location.reload();
      return false;
    },
  },
  inject: {
    mapOptions: { default: {} },
    tracksList: { default: [] },
  },
  computed: {
    isAdmin: myhelper.isAdmin,
  },

  // Your component's JavaScript logic goes here
};
</script>

<style scoped>
/* Your component's CSS styles go here */
.setting-maplang .el-radio-button {
  font-size: 16px;
}
.app-settings .setting-maplang .el-tooltip__trigger .fa-info-circle {
  top: 5px;
}
.app-settings .el-tooltip__trigger .fa-info-circle {
  position: absolute;
  top: 12px;
  margin-left: 5px;
  font-size: 16px;
}

.showexp-checkboxes .el-checkbox {
  width: 100%;
  padding-left: 0px;
}
</style>
<style>
.setting-maplang .el-radio-button .el-radio-button__inner {
  padding: 5px 8px;
  font-size: 10px;
}

.settings-tooltip {
  font-size: 14px;
  text-align: center;
}

.app-settings .el-checkbox__label {
  color: #000;
  font-family: 'Nota Sans', serif;
  font-weight: normal;
}
.app-settings .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
.app-settings .settings-group-name {
  font-weight: bold;
  font-size: 1.2em;
}
.app-settings {
  font-family: 'Nota Sans', serif;
}
</style>
