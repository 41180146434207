<template>
  <div v-if="track.showTrack">
    <l-polyline
      :lat-lngs="track.points"
      :color="lineStyle.color"
      :lineCap="lineStyle.linecap"
      :dashArray="lineStyle.dashArray"
      :weight="lineStyle.weight"
      :opacity="lineStyle.opacity"
      :smoothFactor="lineStyle.smoothFactor"
    >
    </l-polyline>
  </div>
</template>
<script>
import { LPolyline } from '@vue-leaflet/vue-leaflet';

export default {
  name: 'LMapLocalTrack',
  props: {
    track: { type: Object, required: true },
  },
  components: {
    LPolyline,
  },
  data() {
    return {
      lineStyle: {
        weight: 4,
        color: '#f0ff00',
        opacity: 0.99,
        smoothFactor: 1,
        lineCap: 'square',
        dashArray: '10, 0',
      },
    };
  },
  computed: {},
  methods: {},
  mounted() {
    // console.log(this.track);
  },
};
</script>
