<template>
  <l-tile-layer
    v-for="map in topomaplist"
    :url="map.url"
    :key="map.name"
    :name="map.name"
    :detectRetina="map.detectRetina || detectRetina"
    :options="{
      tms: map.tms || false,
      jnx: true,
      crs: map.crs,
      maxNativeZoom: map.maxNativeZoom || 21,
      maxZoom: map.maxZoom || 21,
      code: map.code,
    }"
    :subdomains="map.subdomains || undefined"
    :tms="map.tms || false"
    :attribution="map.attribution"
    :layer-type="map.layertype || 'base'"
    :visible="isMapLayerActive(map.name)"
    @tileerror="onTileError"
  >
  </l-tile-layer>

  <v-tilelayer-googlemutant
    v-for="map in googlemaplist"
    :key="map.name"
    :name="map.name"
    @tileerror="onTileError"
    :options="{
      type: map.type,
      layerType: 'base',
      visible: isMapLayerActive(map.name),
      code: map.code,
    }"
  >
  </v-tilelayer-googlemutant>
  <!-- <v-tilelayer-bing
    name="Bing Satellite"
    :options="{
      imagerySet: 'Aerial',
      layerType: 'base',
      visible: isMapLayerActive('Bing Satellite'),
      maxNativeZoom: 19,
      maxZoom: 22,
    }"
    @tileerror="onTileError"
    :detectRetina="detectRetina"
    code="BingSat"
    attribution="© 2024 Microsoft"
  >
  </v-tilelayer-bing>

  <v-tilelayer-bing
    name="Bing Satellite with Labels"
    :options="{
      imagerySet: 'AerialWithLabels',
      layerType: 'base',
      visible: isMapLayerActive('Bing Satellite with Labels'),
      maxNativeZoom: 19,
      maxZoom: 22,
    }"
    code="BingSatLab"
    attribution="© 2024 Microsoft"
  >
  </v-tilelayer-bing>

  <v-tilelayer-bing
    name="Bing Road"
    :options="{
      imagerySet: 'Road',
      layerType: 'base',
      visible: isMapLayerActive('Bing Road'),
      maxNativeZoom: 19,
      maxZoom: 22,
    }"
    code="BingRoad"
    attribution="© 2024 Microsoft"
  >
  </v-tilelayer-bing> -->

  <l-tile-layer
    v-for="map in maplist1"
    :url="map.url"
    :key="map.name"
    :name="map.name"
    :detectRetina="detectRetina"
    :tileSize="map.tileSize || 256"
    :options="{
      tms: map.tms || false,
      jnx: true,
      crs: map.crs,
      maxNativeZoom: map.maxNativeZoom || 21,
      maxZoom: map.maxZoom || 21,
      code: map.code,
      zoomOffset: map.zoomOffset || 0,
    }"
    :subdomains="map.subdomains || undefined"
    :tms="map.tms || false"
    :attribution="map.attribution"
    :layer-type="map.layertype || 'base'"
    :visible="isMapLayerActive(map.name)"
    @tileerror="onTileError"
  >
  </l-tile-layer>

  <l-tile-layer
    v-if="showOverlayMaps"
    url="https://c.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"
    :zoomOffset="-1"
    :tileSize="512"
    :detectRetina="detectRetina"
    :options="{ zoomOffset: -1 }"
    name="OpenRailWayMap"
    layer-type="overlay"
    :visible="isMapOverlayVisible('OpenRailWayMap')"
    @tileerror="onTileError"
    code="Orw"
    attribution='Data <a href="https://www.openstreetmap.org/copyright">© OpenStreetMap contributors</a>, Style: <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA 2.0</a> <a href="http://www.openrailwaymap.org/">OpenRailwayMap</a>'
  ></l-tile-layer>

  <div v-if="mapOptions.showAdditionalMaps">
    <l-tile-layer
      v-for="map in chinamaplist"
      :url="map.url"
      :key="map.name"
      :name="map.name"
      :detectRetina="map.detectRetina || detectRetina"
      :options="{
        tms: map.tms || false,
        jnx: true,
        crs: map.crs,
        maxNativeZoom: map.maxNativeZoom || 21,
        maxZoom: map.maxZoom || 21,
        code: map.code,
      }"
      :subdomains="map.subdomains || undefined"
      :tms="map.tms || false"
      :attribution="map.attribution"
      :layer-type="map.layertype || 'base'"
      :visible="isMapLayerActive(map.name)"
      @tileerror="onTileError"
    >
    </l-tile-layer>
  </div>

  <!-- <l-layer-group
    :visible="isMapLayerActive('Tainditu Streets')"
    layer-type="base"
    name="Tainditu Streets"
  >
    <l-tile-layer :url="tainditu1" :subdomains="['0', '1', '2']"></l-tile-layer>
    <l-tile-layer :url="tainditu1_overlay" :subdomains="['0', '1', '2']"></l-tile-layer>
  </l-layer-group>

  <l-layer-group
    :visible="isMapLayerActive('Tainditu Satellite')"
    layer-type="base"
    name="Tainditu Satellite"
  >
    <l-tile-layer :url="tainditu2" :subdomains="['0', '1', '2']"></l-tile-layer>
    <l-tile-layer :url="tainditu2_overlay" :subdomains="['0', '1', '2']"></l-tile-layer>
  </l-layer-group>
 -->
  <LeafletTracks></LeafletTracks>

  <!-- <l-tile-layer
    url="https://maps.marshruty.ru/ml.ashx?x={x}&y={y}&z={z}&i=1&al=1"
    :zoomOffset="-1"
    :tileSize="512"
    :detectRetina="true"
    :options="{ zoomOffset: -1 }"
    name="NaKarte2"
    layer-type="base"
    ></l-tile-layer> -->

  <l-control-layers :collapsed="true" position="topleft" ref="controllayer" />
  <!--  FIXME - make datepicker work -->
  <!-- <LControlDatepicker /> -->
  <LControlMaplinks></LControlMaplinks>
</template>

<script>
import { LTileLayer, LControlLayers, LLayerGroup } from '@vue-leaflet/vue-leaflet';
import { toRaw } from 'vue';
// eslint-disable-next-line no-unused-vars
import L from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import VueLeafletGoogleMutant from '@/components/VueLeafletGoogleMutant.vue';
// import VueLeafletBing from '@/components/VueLeafletBing.vue';
import LeafletTracks from '@/components/LeafletTracks.vue';
import LControlDatepicker from '@/components/LControlDatepicker.vue';
import LControlMaplinks from '@/components/LControlMaplinks.vue';
import 'proj4';
import 'proj4leaflet';
import myhelper from '@/plugins/myhelper';
import hashState from '@/services/hashState';
import hotkeys from 'hotkeys-js';

L.CRS.Baidu = new L.Proj.CRS(
  'EPSG:900913',
  '+proj=merc +a=6378206 +b=6356584.314245179 +lat_ts=0.0 +lon_0=0.0 +x_0=0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
  {
    resolutions: (() => {
      const level = 19;
      const res = [];
      res[0] = 2 ** 18;
      for (let i = 1; i < level; i += 1) {
        res[i] = 2 ** (18 - i);
      }
      return res;
    })(),
    origin: [0, 0],
    bounds: L.bounds([20037508.342789244, 0], [0, 20037508.342789244]),
  },
);

export default {
  name: 'LBaseTiles',
  props: {
    leafletRef: { type: Object, required: true },
  },
  inject: {
    mapOptions: { default: {} },
  },

  components: {
    LTileLayer,
    LControlLayers,
    // eslint-disable-next-line vue/no-unused-components
    LControlDatepicker,
    LControlMaplinks,
    LeafletTracks,
    // eslint-disable-next-line vue/no-unused-components
    LLayerGroup,
    'v-tilelayer-googlemutant': VueLeafletGoogleMutant,
    // 'v-tilelayer-bing': VueLeafletBing,
  },
  emits: ['baselayerchange'],
  data() {
    return {
      defaultMapLayer: { name: 'Topomapper', code: 'T' },
      detectRetina: false,
      showOverlayMaps: false,
      generalmaplist: [],
      fullmaplist: [],
      topomaplist: [
        {
          name: 'Topomapper',
          url: 'https://proxy.nakarte.me/http/88.99.52.155/tmg/{z}/{x}/{y}',
          maxNativeZoom: 12,
          detectRetina: true,
          code: 'T',
          // maxZoom: 18,
          // attribution:
          //   '<a href="https://play.google.com/store/apps/' +
          //   'details?id=com.atlogis.sovietmaps.free&hl=en&gl=US">Russian Topo Maps</a>',
        },
        {
          name: 'Topo 10km',
          url: 'https://{s}.tiles.nakarte.me/topo001m/{z}/{x}/{y}',
          maxNativeZoom: 9,
          tms: true,
          detectRetina: true,
          code: 'D',
        },
        {
          name: 'Eurasia 25km',
          url: 'https://{s}.tiles.nakarte.me/eurasia25km/{z}/{x}/{y}',
          maxNativeZoom: 8,
          tms: true,
          detectRetina: true,
          code: 'E25m',
        },

        {
          name: 'OpenTopoMap',
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          maxNativeZoom: 14,
          attribution: '<a href="https://opentopomap.org/">OpenTopoMap</a>',
          code: 'Ot',
        },
        {
          name: 'OpenStreetMap',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution:
            '<a href="https://www.openstreetmap.org/copyright">' +
            '&copy; OpenStreetMap contributors</a>',
          code: 'Os',
        },
        {
          name: 'ArcGis Topo',
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          maxNativeZoom: 19,
          attribution: 'Powered by Esri',
          code: 'EsriTopo',
        },
        {
          name: 'ArcGis Street',
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          maxNativeZoom: 19,
          attribution: 'Powered by Esri',
          code: 'EsriSt',
        },
        {
          name: 'ArcGis Satellite',
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          maxNativeZoom: 19,
          attribution: 'Powered by Esri',
          code: 'EsriSat',
        },
        // arcgis streets
        // arcgis topo
      ],
      maplist1: [
        // MapBox Streets
        {
          name: 'MapBox Streets',
          url: `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
          attribution:
            '<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox</a> ' +
            '<a href="http://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap</a>',
          code: 'MBst',
          zoomOffset: -1,
          tileSize: 512,
          detectRetina: true,
          maxNativeZoom: 20,
          maxZoom: 21,
        },
        // MapBox Satellite
        {
          name: 'MapBox Satellite',
          url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
          attribution:
            '<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox</a> ' +
            '<a href="http://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap</a>',
          code: 'MBsat',
          zoomOffset: -1,
          tileSize: 512,
          detectRetina: true,
          maxNativeZoom: 20,
          maxZoom: 21,
        },
        // MapBox Terrain
        {
          name: 'MapBox Terrain',
          url: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
          attribution:
            '<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox</a> ' +
            '<a href="http://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap</a>',
          code: 'MBter',
          zoomOffset: -1,
          tileSize: 512,
          detectRetina: true,
          maxNativeZoom: 20,
          maxZoom: 21,
        },
        {
          name: 'MapTiler Streets',
          url: `https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER_KEY}`,
          attribution:
            "<a href='https://www.maptiler.com/copyright/' target='_blank'>&copy; MapTiler</a> <a href='https://www.openstreetmap.org/copyright' target='_blank'>&copy; OpenStreetMap contributors</a>",
          code: 'MT',
          zoomOffset: -1,
          tileSize: 512,
        },
        // maptiler satellite
        {
          name: 'MapTiler Satellite',
          url: `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${process.env.VUE_APP_MAPTILER_KEY}`,
          attribution:
            "<a href='https://www.maptiler.com/copyright/' target='_blank'>&copy; MapTiler</a>",
          code: 'MTsat',
          zoomOffset: -1,
          tileSize: 512,
          detectRetina: true,
          maxNativeZoom: 19,
        },

        // // here.com map
        // {
        //   name: 'Here.com Streets',
        //   url: `https://{s}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?apiKey=${process.env.VUE_APP_HERE_KEY}&ppi=320`,
        //   attribution: '&copy; HERE 2019',
        //   subdomains: ['1', '2', '3', '4'],
        //   code: 'Hst',
        // },
        // // here.com satellite
        // {
        //   name: 'Here.com Satellite',
        //   url: `https://{s}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/jpg?apiKey=${process.env.VUE_APP_HERE_KEY}`,
        //   attribution: '&copy; HERE 2019',
        //   subdomains: ['1', '2', '3', '4'],
        //   code: 'Hsat',
        // },
        // tomtom map
        {
          name: 'TomTom Streets',
          url: `https://api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=${process.env.VUE_APP_TOMTOM_KEY}`,
          attribution: '&copy; TomTom 2019',
          code: 'TTst',
          maxNativeZoom: 21,
        },
        // tomtom satellite
        {
          name: 'TomTom Satellite',
          url: `https://api.tomtom.com/map/1/tile/sat/main/{z}/{x}/{y}.jpg?key=${process.env.VUE_APP_TOMTOM_KEY}`,
          attribution: '&copy; TomTom 2019',
          code: 'TTsat',
          maxNativeZoom: 19,
        },
        {
          name: 'Yandex Streets',
          url: `https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&scale=2&lang=${this.mapOptions.mapLanguage}`,
          crs: L.CRS.EPSG3395,
          attribution: '<a href="https://yandex.ru/maps/">Yandex</a>',
          code: 'Yst',
        },
        {
          name: 'Yandex Satellite',
          url: `https://core-sat.maps.yandex.net/tiles?l=sat&v=3.1155.0&x={x}&y={y}&z={z}&scale=2&lang=${this.mapOptions.mapLanguage}`,
          crs: L.CRS.EPSG3395,
          maxNativeZoom: 17,
          attribution: '<a href="https://yandex.ru/maps/?l=sat">Yandex</a>',
          code: 'Ysat',
        },
      ],
      chinamaplist: [
        {
          name: 'Google Sattellite in China',
          url: `${process.env.VUE_APP_SERVER_URL}/services/mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}`,
          code: 'ChGs',
        },
        {
          name: 'Yandex Streets in China',
          url: `${process.env.VUE_APP_SERVER_URL}/services/core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&scale=2&lang=${this.mapOptions.mapLanguage}`,
          crs: L.CRS.EPSG3395,
          attribution: '<a href="https://yandex.ru/maps/">Yandex</a>',
          code: 'ChYst',
        },
        {
          name: 'Yandex Satellite in China',
          url: `${process.env.VUE_APP_SERVER_URL}/services/core-sat.maps.yandex.net/tiles?l=sat&v=3.1155.0&x={x}&y={y}&z={z}&scale=2&lang=${this.mapOptions.mapLanguage}`,
          crs: L.CRS.EPSG3395,
          maxNativeZoom: 17,
          attribution: '<a href="https://yandex.ru/maps/">Yandex</a>',
          code: 'ChYsat',
        },
        {
          name: 'OpenStreetMap in China',
          url: `${process.env.VUE_APP_SERVER_URL}/services/{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
          attribution:
            '<a href="https://www.openstreetmap.org/copyright">' +
            '&copy; OpenStreetMap contributors</a>',
          code: 'ChOs',
        },
        {
          name: 'Baidu Map-Large1',
          tms: true,
          url: 'https://maponline{s}.bdimg.com/tile/?qt=vtile&x={x}&y={y}&z={z}&styles=pl&scaler=2&udt=&from=jsapi2_',
          subdomains: ['0', '1', '2', '3'],
          crs: L.CRS.Baidu,
          attribution: 'Map data &copy; Baidu',
          code: 'Bd1',
        },
        {
          name: 'Baidu Map-Large2',
          tms: true,
          url: `${process.env.VUE_APP_SERVER_URL}/services/online{s}.map.bdimg.com/onlinelabel/?qt=tile&x={x}&y={y}&z={z}&styles=pl&scaler=1&p=1`,
          subdomains: ['0', '1', '2', '3'],
          crs: L.CRS.Baidu,
          attribution: 'Map data &copy; Baidu',
          code: 'Bd2',
        },

        {
          name: 'Baidu Satellite',
          tms: true,
          url: `${process.env.VUE_APP_SERVER_URL}/services/shangetu{s}.map.bdimg.com/it/u=x={x};y={y};z={z};v=009;type=sate&fm=46`,
          subdomains: ['1', '2', '3', '4'],
          crs: L.CRS.Baidu,
          maxNativeZoom: 16,
          attribution: 'Map data &copy; Baidu',
          code: 'Bds',
        },

        // {
        //   name: 'Tencent Normal',
        //   tms: true,
        //   url: '//rt{s}.map.gtimg.com/tile?z={z}&x={x}&y={-y}&type=vector&styleid=3',
        //   subdomains: ['1', '2', '3'],
        //   // crs: L.CRS.Baidu,
        // },
        // {
        //   name: 'Tencent Satellite',
        //   tms: true,
        //   url: 'https://p{s}.map.gtimg.com/sateTiles/{z}/{sx}/{sy}/{x}_{-y}.jpg',
        //   subdomains: ['1', '2', '3'],
        //   // crs: L.CRS.Baidu,
        // },
        // {
        //   name: 'Tencent Terrain',
        //   tms: true,
        //   url: '//p{s}.map.gtimg.com/demTiles/{z}/{sx}/{sy}/{x}_{-y}.jpg',
        //   subdomains: ['1', '2', '3'],
        //   // crs: L.CRS.Baidu,
        // },

        {
          name: 'Gaode Streets',
          url: 'https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}',
          subdomains: ['1', '2', '3', '4'],
          maxNativeZoom: 17,
          attribution: 'Map data &copy; Gaode',
          code: 'GdSt',
        },

        {
          name: 'Gaode Satellite',
          url: 'https://webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
          maxNativeZoom: 15,
          // FIXME - upgrade vue-leaflet to support subdomains
          subdomains: ['1', '2', '3', '4'],
          attribution: 'Map data &copy; Gaode',
          code: 'GdSat',
        },
        // {
        //   name: 'GeoQ',
        //   url: 'https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer/tile/{z}/{y}/{x}',
        // },
      ],
      maplist2: [
        // {
        //   name: 'NaKarte',
        //   url: 'https://maps.marshruty.ru/ml.ashx?x={x}&y={y}&z={z}&i=1&al=1',
        // },
        // {
        //   name: 'Thunderforest Pioneer',
        //   url: `https://{s}.tile.thunderforest.com/pioneer/{z}/{x}/{y}.png?apikey=${process.env.VUE_APP_THUNDER_KEY}`,
        //   attribution:
        //     '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        //   maxZoom: 22,
        // },
        // {
        //   name: 'Thunderforest Landscape',
        //   url: `https://{s}.tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=${process.env.VUE_APP_THUNDER_KEY}`,
        //   attribution:
        //     '&copy; <a href="http://www.thunderforest.com/">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        //   maxZoom: 22,
        // },
        // {
        //   name: 'Jawg.Streets',
        //   url: `https://{s}.tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=${process.env.VUE_APP_JAWG_KEY}`,
        //   attribution:
        //     '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        //   maxZoom: 22,
        // },
        // {
        //   name: 'Esri.Delorme',
        //   url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}',
        // },
        // {
        //   name: 'ESRI Satellite',
        //   url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        // },
        // {
        //   name: 'ArcGis Street',
        //   url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
        // },
        // {
        //   name: 'ArcGis Topo',
        //   url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        // },
        // { name: 'OpenTopo', url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png' },
        // { name: 'mbUrlCartoL', url: 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png' },
      ],
      googlemaplist: [
        { name: 'Google Roadmap', type: 'roadmap', visible: false, code: 'Gr' },
        // { name: 'Aerial', type: 'satellite' },
        { name: 'Google Satellite', type: 'hybrid', visible: false, code: 'Gs' },
        { name: 'Google Terrain', type: 'terrain', visible: false, code: 'Gt' },
        // { name: 'Traffic', type: 'roadmap' },
        // { name: 'Transit', type: 'roadmap' },
      ],
      manualmaplist: [
        // Mapbox - just name and code
        { name: 'MapBox Streets', code: 'MBst' },
        { name: 'MapBox Satellite', code: 'MBsat' },
        { name: 'MapBox Terrain', code: 'MBter' },
        { name: 'MapTiler', code: 'MT' },
      ],
      maptiler_url: `https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${process.env.VUE_APP_MAPTILER_KEY}`,
      // Mapbox styles https://docs.mapbox.com/mapbox-gl-js/guides/styles
      mapbox_url: `https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
      mapbox2_url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
      mapbox_outdoors_url: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v12/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_KEY}`,
      tainditu1:
        'https://t{s}.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=b72aa81ac2b3cae941d1eb213499e15e',
      tainditu1_overlay:
        'https://t{s}.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=b72aa81ac2b3cae941d1eb213499e15e',

      tainditu2:
        'https://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=b72aa81ac2b3cae941d1eb213499e15e',
      tainditu2_overlay:
        'https://t{s}.tianditu.gov.cn/cia_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}&tk=b72aa81ac2b3cae941d1eb213499e15e',

      allLayersList: [
        'Google Roadmap',
        'Google Satellite',
        'Google Terrain',
        'Bing Satellite',
        'Bing Satellite with Labels',
        'Bing Road',
        'MapTiler',
        'MapBox Terrain',
        'MapBox Streets',
        'MapBox Satellite',
        // 'Topomapper',
        // 'Topo 10km',
        // 'Eurasia 25km',
        // 'OpenStreetMap',
        // 'OpenTopoMap',
        // 'OpenRailWayMap',
        // 'ArcGis Satellite',
        // 'Yandex Streets',
        // 'Yandex Satellite',
        // 'Baidu Map-Large',
        // 'Baidu Satellite',
        // 'Gaode Streets',
        // 'Baidu Map-Large1',
        // 'Baidu Map-Large2',
        // 'GeoQ',
        // 'Tainditu Streets',
        // 'Tainditu Satellite',
        // 'Gaode Satellite',
        // 'Gaode Streets',
      ],
      activeMapLayer: '',
    };
  },
  methods: {
    isTextFieldActive: myhelper.isTextFieldActive,
    // FIXME - queue remote logging for tileerror to consolidate them if too many requests
    // eslint-disable-next-line no-unused-vars
    onTileError(e) {
      // console.log('Tile error', e);
      // eslint-disable-next-line no-underscore-dangle
      // myhelper.remoteLog('tileerror', e.target._url);
    },
    mapSetCrs(e) {
      // console.log(toRaw(e.layer.options.crs));
      const { myMap } = this;
      const center = myMap.getCenter();
      let setCrs;
      if (e && e.layer.options.crs) {
        setCrs = e.layer.options.crs;
      } else {
        setCrs = L.CRS.EPSG3857;
      }
      if (toRaw(myMap.options.crs.code) !== setCrs.code) {
        myMap.options.crs = setCrs;
        // eslint-disable-next-line no-underscore-dangle
        myMap._resetView(center, myMap.getZoom(), true);
        myMap.setView(center);
      }
    },
    saveCurrentMapLayer(e) {
      // console.log(toRaw(e.name));
      localStorage.setItem('ActiveMapLayer', e.name);
    },
    onOverlayVisibilityChange(e) {
      let visibleOverlays = JSON.parse(localStorage.getItem('VisibleOverlayLayers')) || [];
      if (!Array.isArray(visibleOverlays)) {
        visibleOverlays = [];
      }
      if (e.type === 'overlayadd') {
        if (!visibleOverlays.includes(e.name)) {
          visibleOverlays.push(e.name);
        }
      }
      if (e.type === 'overlayremove') {
        visibleOverlays = visibleOverlays.filter((name) => {
          return name !== e.name;
        });
      }
      localStorage.setItem('VisibleOverlayLayers', JSON.stringify(visibleOverlays));
    },
    getActiveMapLayer() {
      const savedMapLayer = localStorage.getItem('ActiveMapLayer');
      let activeMap;
      if (hashState().state.parameters.l) {
        // find active map by code
        const code = hashState().state.parameters.l;
        activeMap = this.fullmaplist.find((m) => m.code === code);
        if (activeMap) {
          this.activeMapLayer = activeMap.name;
          return;
        }
      }

      // Check if the saved layer is in the list of available layers
      if (this.allLayersList.includes(savedMapLayer)) {
        this.activeMapLayer = savedMapLayer;
        // find code by name
        activeMap = this.fullmaplist.find((m) => m.name === savedMapLayer);
        if (activeMap) {
          hashState().updateState('l', activeMap.code);
        }
      } else {
        this.activeMapLayer = this.defaultMapLayer.name;
        hashState().updateState('l', this.defaultMapLayer.code);
      }
    },
    changeMapLayer(code) {
      // console.log('changeMapLayer', code);
      const activeMap = this.fullmaplist.find((m) => m.code === code);
      // console.log('activeMap', activeMap);
      if (activeMap) {
        this.activeMapLayer = activeMap.name;
        const layerLabel = [...document.querySelectorAll('.leaflet-control-layers-selector')].find(
          (input) => input.nextElementSibling.textContent.includes(this.activeMapLayer),
        );
        // console.log(layerLabel, this.activeMapLayer);
        if (layerLabel) {
          // Simulate the click event on the radio input for the desired layer
          layerLabel.click();
        }
      }

      // if (this.activeMapLayer !== e) {
      //   this.activeMapLayer = e;
      // }
    },
    isMapOverlayVisible(layername) {
      const visibleOverlays = JSON.parse(localStorage.getItem('VisibleOverlayLayers')) || [];
      return visibleOverlays.includes(layername);
    },

    isMapLayerActive(layername) {
      return this.activeMapLayer === layername;
    },
    setInitialCrs() {
      const activeMap = this.generalmaplist.find((m) => m.name === this.activeMapLayer);
      if (activeMap?.crs) {
        this.mapSetCrs({ layer: { options: { crs: activeMap.crs } } });
      }
    },
    loadGoogleMapApis() {
      if (document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]')) {
        // console.log('Google Maps API already loaded');
        return;
      }
      const googleMapScript = document.createElement('script');
      googleMapScript.async = true;
      if (this.mapOptions.mapLanguage === 'ru') {
        const region = 'RU';
        const language = 'ru';
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLEMAP_KEY}&region=${region}&language=${language}&loading=async`;
      } else {
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLEMAP_KEY}&loading=async`;
      }
      // https://maps.googleapis.com/maps/api/js?key=<%= VUE_APP_GOOGLEMAP_KEY %>&region=RU&language=ru&callback=Function.prototype
      if (process.env.NODE_ENV === 'development') {
        googleMapScript.onload = () => {
          console.log('Google Maps API loaded');
        };
      }
      document.head.appendChild(googleMapScript);
    },
    assignHotKeys() {
      // shift+1 to change to topomapper
      hotkeys('shift+1', (event) => {
        if (this.isTextFieldActive()) {
          return;
        }
        event.preventDefault();
        this.changeMapLayer('T');
      });
      // shift+2 to change to Google Satellite
      hotkeys('shift+2', (event) => {
        if (this.isTextFieldActive()) {
          return;
        }
        event.preventDefault();
        this.changeMapLayer('Gs');
      });
      hotkeys('shift+3', (event) => {
        if (this.isTextFieldActive()) {
          return;
        }
        event.preventDefault();
        this.changeMapLayer('MBsat'); // change to MapBox Satellite
      });
      // shift+3 change to MapBox Satellite
    },
  },
  computed: {
    myMap() {
      return this.leafletRef.leafletObject;
    },
  },
  watch: {
    mapOptions: {
      handler() {
        if (this.mapOptions.showAdditionalMaps) {
          this.showOverlayMaps = true;
        }
      },
      deep: true,
    },
  },
  created() {
    this.fullmaplist = this.maplist1.concat(
      this.chinamaplist,
      this.maplist2,
      this.googlemaplist,
      this.manualmaplist,
      this.topomaplist,
    );
    if (this.mapOptions.showAdditionalMaps) {
      this.generalmaplist = this.maplist1.concat(
        this.chinamaplist,
        this.maplist2,
        this.topomaplist,
      );
      this.showOverlayMaps = true;
    } else {
      this.generalmaplist = this.maplist1.concat(this.maplist2, this.topomaplist);
    }
    this.allLayersList = [...this.allLayersList, ...this.generalmaplist.map((m) => m.name)];
    // console.log('LBaseTiles created');
    this.getActiveMapLayer();
  },
  mounted() {
    myhelper
      .fetchUserCountry()
      .then((currentCountry) => {
        if (process.env.NODE_ENV === 'development') {
          console.log('currentCountry', currentCountry);
        }

        if (currentCountry !== 'CN') {
          // console.log('Load Google Maps API');
          this.loadGoogleMapApis();
          myhelper.loadCssDynamic('https://fonts.googleapis.com/css2?family=Inter');
        }
      })
      .catch((error) => {
        console.error('currentCountry detection error', error);
      });
    this.setInitialCrs();

    this.myMap.on('baselayerchange', (e) => {
      this.saveCurrentMapLayer(e);
      this.mapSetCrs(e);
      hashState().updateState('l', `${e.layer.options.code}`);
    });
    this.myMap.on('overlayremove', this.onOverlayVisibilityChange);
    this.myMap.on('overlayadd', this.onOverlayVisibilityChange);
    hashState().initialize();
    hashState().addEventListener('l', this.changeMapLayer);
    this.assignHotKeys();
    this.$nextTick(() => {
      document.querySelectorAll('label').forEach((label) => {
        const mapsList = [
          'OpenStreetMap',
          'Google Terrain',
          'MapBox Terrain',
          'ArcGis Satellite',
          'MapTiler Satellite',
          'TomTom Satellite',
          'OpenTopoMap',
          'Baidu Satellite',
          'OpenStreetMap in China',
        ];
        if (this.mapOptions.showAdditionalMaps) {
          mapsList.push('Yandex Satellite');
        }
        if (mapsList.includes(label.textContent.trim())) {
          // eslint-disable-next-line no-param-reassign
          label.style.marginBottom = '5px'; // Set your desired margin-bottom value
        }
      });
    });
  },
  beforeUnmount() {
    hotkeys.unbind();
    hashState().cleanup();
    this.myMap.off('baselayerchange');
    this.myMap.off('overlayremove', this.onOverlayVisibilityChange);
    this.myMap.off('overlayadd', this.onOverlayVisibilityChange);
    hashState().removeEventListener('l', this.changeMapLayer);
  },
};
</script>

<style>
.leaflet-control-container .leaflet-control-attribution a[href*='leafletjs'],
.leaflet-control-attribution span[aria-hidden='true'] {
  display: none;
}
@media print {
  .leaflet-control-container {
    display: none;
  }
  .leaflet-control-attribution {
    display: none;
  }
}

@media (max-width: 564px) {
  .leaflet-control-attribution {
    display: none;
  }
}
</style>
