/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable wrap-iife */

(function (factory, window) {
  // define an AMD module that relies on 'leaflet'
  // eslint-disable-next-line no-undef
  if (typeof define === 'function' && define.amd) {
    // eslint-disable-next-line no-undef
    define(['leaflet'], factory);

    // define a Common JS module that relies on 'leaflet'
  } else if (typeof exports === 'object') {
    module.exports = factory(require('leaflet'));
  }

  // attach your plugin to the global 'L' variable
  if (typeof window !== 'undefined' && window.L) {
    window.L.YourPlugin = factory(L);
  }
})(function (L) {
  L.Control.Searchbox = L.Control.extend({
    options: {
      class: 'leaflet-bar leaflet-control-searchbox',
      id: '',
      position: 'topright',
      expand: 'left',
      collapsed: true,
      width: null,
      iconPath: require('@/assets/search_icon.png'),
      autocompleteFeatures: ['setValueOnClick'],
    },

    onAdd(map) {
      this._create();

      this._collapsed = this.options.collapsed;
      // if (this.options.collapsed) {
      this.hide();
      // }

      L.DomEvent.disableClickPropagation(this._container);

      // L.DomEvent.on(this._button, 'click', this._onClick, this);

      return this._container;
    },

    onRemove(map) {},

    // getValue() {
    //   return this._input.value;
    // },

    // setValue(value) {
    //   this._input.value = value;
    //   return this;
    // },

    addItem(item) {
      const listItem = L.DomUtil.create(
        'li',
        'leaflet-searchbox-autocomplete-item',
        this._autocomplete,
      );
      listItem.textContent = item;
      this._items.push(listItem);

      L.DomUtil.addClass(this._searchboxWrapper, 'open');

      return this;
    },

    hide() {
      L.DomUtil.addClass(this._container, 'collapsed');
      // this._input.blur();
      this._button.blur();
      setTimeout(() => {
        this._collapsed = true;
      }, 600);

      return this;
    },

    onInput(event, handler) {
      L.DomEvent.on(this._input, event, handler, this);

      return this;
    },

    offInput(event, handler) {
      L.DomEvent.off(this._input, event, handler, this);

      return this;
    },

    onButton(event, handler) {
      // const wrapper = this._buttonHandlerWrapper(handler);
      // L.DomEvent.on(this._button, 'click', this._onClick, this);
      L.DomEvent.on(this._button, event, handler, this);

      return this;
    },

    offButton(event, handler) {
      // const wrapper = this._buttonHandlerWrapper(handler);
      L.DomEvent.off(this._button, event, handler, this);

      return this;
    },

    onAutocomplete(event, handler) {
      L.DomEvent.on(this._autocomplete, event, handler, this);

      return this;
    },

    offAutocomplete(event, handler) {
      L.DomEvent.off(this._autocomplete, event, handler, this);

      return this;
    },

    _onClick() {
      console.log('clicked');
    },

    _buttonHandlerWrapper(handler) {
      return function () {
        if (!this._collapsed) {
          handler();
        }
      };
    },

    _create() {
      this._container = L.DomUtil.create('div', 'leaflet-control leaflet-searchbox-container');
      this._container.title = 'Поиск';
      if (this.options.class != '') {
        L.DomUtil.addClass(this._container, this.options.class);
      }
      if (this.options.id != '') {
        this._container.id = this.options.id;
      }

      this._searchboxWrapper = L.DomUtil.create(
        'div',
        'leaflet-searchbox-wrapper',
        this._container,
      );

      if (this.options.expand == 'left') {
        // this._createInput('left');
        this._createButton('right');
      } else if (this.options.expand == 'right') {
        this._createButton('left');
        // this._createInput('right');
      }
      // this._createAutocomplete();
    },

    _createButton(position) {
      this._button = L.DomUtil.create(
        'button',
        `leaflet-searchbox-button leaflet-searchbox-button-${position}`,
        this._searchboxWrapper,
      );
      this._button.setAttribute('type', 'button');
      this._button.style.width = this.options.height;
      this._button.style.height = this.options.height;
      this._icon = L.DomUtil.create('img', 'leaflet-searchbox-icon', this._button);
      this._icon.setAttribute('src', this.options.iconPath);
    },

    // _createAutocomplete() {
    //   this._autocomplete = L.DomUtil.create(
    //     'ul',
    //     'leaflet-searchbox-autocomplete',
    //     this._container,
    //   );

    //   this._items = [];
    // },
  });

  return L.Control.Searchbox;
}, window);

L.control.searchbox = function (options) {
  return new L.Control.Searchbox(options);
};
