<template><div></div></template>

<script>
// import { ref, toRaw, nextTick } from 'vue';
import L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import { mapGetters } from 'vuex';
import 'leaflet.motion/dist/leaflet.motion';

export default {
  name: 'LMapMotion',
  props: {
    leafletRef: { type: Object, required: true },
  },
  data() {
    return {
      motionLayer: null,
    };
  },
  inject: {
    mapOptions: { default: {} },
  },
  computed: {
    ...mapGetters('places', ['mapPathRoute']),
    myMap() {
      return this.leafletRef.leafletObject;
    },
  },
  methods: {
    ...mapGetters('places', ['placesListByVisit']),
    getPathFromPlace(place) {
      const path = [[place.latitude, place.longitude]];
      try {
        const rj = JSON.parse(place.routejson);
        path.push(...rj);
      } catch (error) {
        console.warn('getPathFromPlace', error);
        console.warn(place.routejson);
        console.warn(place);
      }
      return path;
    },
    getPathDistance(path) {
      let distance = 0;
      for (let i = 0; i < path.length - 1; i += 1) {
        distance += this.myMap.distance(path[i], path[i + 1]);
      }
      return distance;
    },
    splitPolyLineByTransport() {
      // console.log(this.placeByID());
      // return 0;
      const splittedPath = [];
      let prevTransport = '';
      let acc = [];

      this.placesListByVisit()('cae').forEach((place) => {
        if (!prevTransport) {
          prevTransport = place.go_by;
        }
        if (prevTransport !== place.go_by) {
          if (acc.length > 1) {
            splittedPath.push({ transport: prevTransport, path: acc });
          }
          acc = [];
          prevTransport = place.go_by;
        }
        acc.push(...this.getPathFromPlace(place));
      });
      splittedPath.push({ transport: prevTransport, path: acc });
      return splittedPath;
    },
    createMotionSeq(transport, path, duration = 8000) {
      const distance = this.getPathDistance(path);
      const transportMap = {
        car: 'car',
        steamship: 'ship',
        train: 'train',
        horse: 'horse',
        horsecart: 'horse',
      };
      const transportSpeed = {
        car: 20,
        steamship: 20,
        train: 20,
        horse: 10,
      };
      const duration2 = (distance / transportSpeed[transport]).toFixed(0) / 40;
      console.log(duration2, duration, transport, distance);
      let iconname = transportMap[transport];
      if (!iconname) {
        iconname = 'train';
      }
      return L.motion
        .polyline(
          path,
          {
            color: 'orangered',
          },
          {
            easing: L.Motion.Ease.easeInOutQuad,
          },
          {
            removeOnEnd: true,
            icon: L.divIcon({
              html: `<i class='fa fa-${iconname} fa-2x fa-flip-horizontal' aria-hidden='true'></i>`,
              className: 'transport-icon',
              iconSize: L.point(27.5, 24),
            }),
          },
        )
        .motionDuration(duration2);
    },
    prepareMotion() {
      // const path = this.mapPathRoute;
      const splittedPath = this.splitPolyLineByTransport();
      // console.log(splittedPath);
      // eslint-disable-next-line no-unused-vars
      const seqGroups = splittedPath.map((pathObj) => {
        return this.createMotionSeq(pathObj.transport, pathObj.path, 4000);
      });
      // console.log(seqGroups);
      // console.log(path);
      // const motion = L.motion.polyline(path, {
      //   color: 'red',
      //   weight: 5,
      //   opacity: 0.7,
      //   duration: 5000,
      //   easing: L.Motion.Ease.easeInOutQuart,
      // });
      // motion.addTo(this.myMap);
      // motion.motionStart();
      this.motionLayer = L.motion.seq(seqGroups).addTo(this.myMap);
      this.myMap.addLayer(this.motionLayer);
      if (this.mapOptions.motionStart) {
        setTimeout(() => {
          this.motionLayer.motionStart();
        }, 1000);
      }

      this.motionLayer.on('click', () => {
        this.motionLayer.motionToggle();
      });

      // setTimeout(() => {
      //   this.motionLayer.motionToggle();
      // }, 2000);

      // const seqGroup = L.motion.seq(seqGroups).addTo(this.myMap);
      // console.log(splittedPath[0].path);
      // const seqGroup = L.motion
      //   .seq([
      //     L.motion
      //       .polyline(
      //         splittedPath[0].path,
      //         {
      //           color: 'orangered',
      //         },
      //         {
      //           easing: L.Motion.Ease.easeInOutQuad,
      //         },
      //         {
      //           removeOnEnd: true,
      //           icon: L.divIcon({
      //             html: "<i class='fa fa-train fa-2x fa-flip-horizontal' aria-hidden='true'></i>",
      //             iconSize: L.point(27.5, 24),
      //           }),
      //         },
      //       )
      //       .motionDuration(80000),
      //   ])
      //   .addTo(this.myMap);
      // setTimeout(() => {
      //   seqGroup.motionStart();
      // }, 1000);
    },
    removeMotionLayer() {
      if (this.motionLayer) {
        this.motionLayer.off();
        this.myMap.removeLayer(this.motionLayer);
      }
    },
  },

  mounted() {
    console.log(L.Motion);
    this.prepareMotion();
  },
  beforeUnmount() {
    this.removeMotionLayer();
  },
};
</script>

<style>
.transport-icon {
  color: #9c02b0;
  background-color: transparent;
}
/* .transport-icon i { */
/* color: orangered; */
/* } */
</style>
